
%projects-list {
  & {
    @include outer-container();
    @include row(block);

    padding: em($gutter * 0.4);
    font-size: em(14);
  }

  @include media($medium-screen-up) {
    font-size: em(16);
    padding: 0;
    // margin: 0;
    @include row(block);
    >.item {
      @include span-columns(4 of 12);
      @include omega(3n);
    }
  }

  >.item {
    @extend %card-style;
    display: block;
    position: relative;
    overflow: hidden;
    color: tint(black, 20);
    background: tint(black, 100);
    border-radius: $base-border-radius;

    // margin-top: em($gutter * 0.6) ;
    margin-bottom: em($gutter * 0.6) ;

    // @include span-columns(4 of 12);
    // @include omega(3n);

    // border: 1px solid rgba(white, 0.0);

    &:hover {

      // border-color: tint(black, 80);
      // box-shadow: 0px 20px 40px 8px rgba(0,0,0,0.2);
      // @include transition(all 300ms $ease-out-circ);

      @include transition(box-shadow 300ms ease);

      box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2);

      >.figure >.image {
        // background-size: 110%;
        // @include transition(all 1000ms ease);
        @include transform(scale(1.2));
        @include transition(all 3000ms $ease-out-circ);
      }
    }

    &:not(:nth-child(-n+3)) {
      margin-top: ($gutter);
    }

    >.figure {
      position: relative;
      overflow: hidden;
      border-radius: $base-border-radius $base-border-radius 0 0;
      // border-radius: $base-border-radius $base-border-radius 0 0;
      // @include transition(background-size 2000ms ease);

      >.image {
        @include transform(scale(1));
        @include transition(all 300ms ease);

        position: relative;
        width: 100%;
        height: 0;
        padding-top: 60%;

        border-radius: $base-border-radius $base-border-radius 0 0;
        background: tint(black, 80) center no-repeat;
        background-size: cover;
      }
      >.label.-raisecomplete {
        display: block;
        position: absolute;
        z-index: 1;
        bottom: 0;
        color: white;
        background: rgba($green, 0.9);
        height: (40px);
        width: 100%;
        font-weight: bold;
        font-size: em(12);
        line-height: (40px);
        text-align: center;
      }
    }
    >.body {
      font-size: em(14);
      padding: ($gutter);

      // height: em(200);
      height: em(240);

      >.name {
        // font-size: em(16);
        font-weight: bold;
      }
      >.owner {
        font-size: em(14);
      }
      >.location {
        color: tint(black, 30);
        font-size: em(14);
        font-weight: bold;
        line-height: (40px)
      }
      p {
        margin-top: em(16);
        font-size: em(14);
      }

    }
    .progress-bar {
      display: block;
      position: relative;
      overflow: hidden;
      height: 4px;
      width: 100%;
      background: rgba(black, 0.2);
      border-radius: 2px;

      margin: em(16) auto em(0);

      >.bar {
        display: block;
        height: inherit;
        background: $green;
      }
    }
    .raiseinfo {
      // border-top: 1px solid tint(black, 90);
      // background: black;
      color: tint(black, 25);
    }
    .row {
      // @include row(table);
      background: tint(black, 98);
      border-top: 1px solid tint(black, 90);

      display: inline-flex;
      width: 100%;
    }
    .cell {
      flex: 1 0 50%;
      // @include span-columns(1 of 3);
      text-align: center;
      padding: ($gutter * 0.6) ($gutter * 0.8);

      &:not(:last-of-type) {
        border-right: 1px solid tint(black, 90);
      }

      &.-primary {
        display: block;
        width: 100%;
        font-size: em(18);
      }

      .value {
        font-size: em(20);
        font-weight: bold;
      }
      .label {
        font-size: em(10);
      }
    }
  }
}

.projects-list {
  @extend %projects-list;
}
