.standalone-nav {

  @include outer-container;

  max-width: 100%;
  background: black;
  height: 110px;

  .header {
    height: 110px;
    text-align: center;
    padding: 0.5rem 0;

    .logo {
      height: 95px;
      background: gray;
      margin: 0 auto;
      overflow: hidden;
//  div background method
//      border-radius: 50%;
//      background-size: cover;
//      padding-top: 20px;
    }
  }

}
