.article-card {
  & {
    @extend %type-system;

    font-size: em(12.8);
    position: relative;
    overflow: hidden;
    background: tint(black, 98);

    /* @include margin($gutter null $gutter); */
    margin: em($gutter * 0.6) auto;

    /* @include span-columns(6); */
    @include span-columns(11 of 12);
    @include shift(0.5);

    @include media($medium-screen-up) {
      @include span-columns(6 of 12);
      @include shift(0);

      font-size: em(16);
      margin-top: em($gutter * 1);
      margin-bottom: em($gutter * 1);
    };
  }

  // >.head{
    // width: 100%;
    // border-bottom: 1px solid black;
    // padding: em($gutter*2) em($gutter*2) em($gutter*0);
  // }

  >.body {
    padding: em($gutter*1.6) em($gutter*1.6);

    @include media($medium-screen-up) {
      padding: em($gutter*2) em($gutter*2);
    };
  }
  >.actions {
    padding: em($gutter*1) em($gutter*2);
    background: #DDD;
    text-align: center;

    @include media($medium-screen-up) {
      text-align: left;
      /* padding: em($gutter*1) em($gutter*2); */
    };
  }
}
