// .projectdisplay-block {
//   & {
//     padding: em(90px) 0;
//     position: relative;
//   }
//
//   >.cta {
//     display: block;
//     // @include outer-container();
//     padding: em($gutter * 0.4);
//     margin-bottom: em($gutter * 0.6);
//
//     @include media($medium-screen-up) {
//       display: none;
//     }
//   }
//
//   >.projects-list {
//     @extend %projects-list;
//   }
// }

// FIXME:
// scope classes in a better way
.home-page-navigation {

  & {
    margin-top: 45px;
    display: block;
    position: fixed;
    overflow: visible;;
    // overflow-y: visible;

    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    width: 100%;
    // height: 80px;
    height: 120px;

    color: white;
    // background: rgba(tint(black, 4), 0.95);
    background: rgba(black, 0.98);
    // background: rgba(white, 1);
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);

    // border-bottom: 1px solid rgba(white, 0.1);

    // @include transition(background-color 500ms ease);
    @include transition(background-color 500ms ease);

    @include media($medium-screen-up) {
      padding: 0 $gutter;
      // padding: 0 ;
    }
    @include media($large-screen-up) {
      padding: 0 ;
    }
  }

  &.-is-overlay {
    background: rgba(black, 0.2);
    // background: rgba(white, 1);
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.0);
    // @include transition(background-color 500ms ease);
  }

  >.flex-grid {
    @include outer-container();
    position: relative;

    display: flex;
    flex-flow: column;

    height: inherit;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
      // height: inherit;
    }
  }

  .logo {
    display: block;
    height: 60px;
    width: 100%;
    // border: 1px solid white;
    fill: white;

    padding: 1em;
    // background: white;
    // border-bottom: 1px solid tint(black, 90);
    border-bottom: 1px solid tint(black, 10);

    @include media($medium-screen-up) {
      height: 100%;
      width: 180px;
      padding: 0;
      background: none;
      border-bottom: none;
    }
  }
  .logo svg {
    height: 100%;
    width: 100%;

    // fill: white;
  }

  .nav-list {
    display: flex;
    flex-flow: row nowrap;
    // align-items: center;
    // align-self: flex-end;

    height: 60px;

    @include media($medium-screen-up) {
      margin-left: auto;
      margin-right: 0;
      height: auto;
    }
  }
  .nav-list >.item {
    color: white;
    // color: black;

    // font-size: em(18);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-transform: capitalize;

    // margin: 0 1em;
    margin: 0 1px;

    display: flex;
    flex: 1;

    &.-is-active a{
      // border-bottom: 2px solid rgba(white, 1.0);
      border-bottom: 2px solid rgba($green, 1.0);
    }
    &:hover a {
      // border-bottom: 2px solid rgba(white, 0.5);
      border-bottom: 2px solid rgba($green, 0.5);
    }

    a {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      // position: relative;
      // display: block;
      // height: 100%;
      // width: auto;
      color: inherit;
      padding: 0 1.5em;

      border-bottom: 2px solid rgba(white, 0);
    }

    @include media($medium-screen-up) {
      width: auto;
    }

  }

  .contact-button {
    display: none;
    align-self: center;

    font-size: 16px;
    font-weight: 600;
    text-align: center;

    color: white;
    background: lighten($green, 10);
    border: 1px solid lighten($green, 10);

    line-height: 46px;
    height: 48px;
    border-radius: 48px;

    padding: 0 40px;
    margin: 0 $gutter;

    transition: background-color 500ms ease;

    &:hover {
      color: white;
      background: rgba(lighten($green, 10), 0.5);
    }

    @include media($medium-screen-up) {
      display: block;
      @include ellipsis(100%);
    }
  }
}

#memberships-banner {
  text-align: center;
  height: 45px;
  background-color: green;
  padding-top: 10px;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;

  a {
    color: #fff;
    font-weight: 200;
    font-size: 1em;
  }
}

.home-page-body {
  & {
    // FIXME:
  // padding-top: 120px;
  // padding-bottom: 80px;
  // padding-bottom: $column;
  //
    display: block;
    position: relative;
    z-index: 1;

    overflow: hidden;
    overflow-y: auto;

    width: 100%;
    height: auto;
    min-height: 100vh;

    color: white;
    // background: rgba(black, 0.75);
    background: black;

    // padding: 120px $gutter $column;
    padding: 120px 0 0;

    @include media($large-screen-up) {
      padding: 120px 0 $column;
    }

  }

  &.-is-overlay {
    background: rgba(black, 0.9);
    // background: rgba($dark-green, 0.95);

    @include transition(background-color 3000ms $ease-out-quint);
    // @include media($medium-screen-up) {
    //   background: rgba(black, 0.85);
    // }
  }

  >.flex-grid {
    @include outer-container();

    display: flex;
    position: relative;
    // z-index: 1;

    padding: $gutter;

    @include media($large-screen-up) {
      padding: 0;
    }
  }
  >.neat-grid {
    @include outer-container(1000px);

    padding: $gutter;

    @include media($large-screen-up) {
      padding: 0;
    }
  }

  .text-block {
    @extend .type-system-sans;
    margin-top: $column;
    // padding-right: $gutter;

    @include media($medium-screen-up) {
      @include span-columns(5 of 12);
    }
    // @include media($large-screen-up) {
    //   @include span-columns(6.5 of 12);
    // }
  }
  .form-block {
    margin-top: $column;
    @include media($medium-screen-up) {
      @include span-columns(6 of 12);
      @include shift(1);
    }
    // @include media($large-screen-up) {
    //   @include span-columns(6 of 12);
    // }

    button[type="submit"] {
      @extend %invest-button;
      border: none;
    }
  }

  .button-cta {
    @extend %invest-button;
    margin: 2em auto;

    @include media($medium-screen-up) {
      display: none;
    }
  }

}

.home-page-footer {
  & {
    position: relative;
    // overflow: hidden;

    background: $green;
    background: tint($green, 10);
    // background: $dark-green;
    color: white;
  }

  >.flex-grid {
    @include outer-container();

    position: relative;
    display: flex;
    flex-flow: column;
    // z-index: 1;
    // margin: 1em;

    padding: 1em 2em;

    @include media($medium-screen-up) {
      padding: 0;
    }
  }

  .links-block {
    // color: $dark-green;
    color: white;
    font-weight: bold;
    margin: $gutter auto;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;;
    // justify-content: flex-end;

    a {color: inherit;}

    ul {
      flex: 1;
      // width: 25%;
    }
    ul:not(:last-of-type) {
      margin-right: $gutter;
    }
  }

  .tos-block {
    // font-size: em(13px);
    font-size: 12px;
    //text-transform: uppercase;
    margin: $gutter auto;

		a {
			color: #fff;
		}
  }

  .press-block {
    position: relative;
    background: rgba($dark-green, 1.0);

    >.list {
      @include outer-container();
      display: flex;
      flex-flow: row wrap;
      // align-items: center;

      // height: $column * 2;
    }

    >.list>.item {
      flex: 1 100%;
      // max-width: 50%;
      height: $column;
      // width: 100%;
      background: none center no-repeat;
      background-size: auto 40%;
      opacity: 0.75;
      // margin: $gutter 0;
      border-bottom: 1px solid tint($dark-green, 10);
      // border: 1px solid tint($dark-green, 10);
    }

    >.list>.item:nth-of-type(2n) {
      border-left: 1px solid tint($dark-green, 10);
    }


    @include media($medium-screen-up) {
      >.list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        height: $column * 2;
      }
      >.list>.item {
        flex: 1 auto;
        // height: $column;
        // width: auto;
        background: none center no-repeat;
        background-size: contain;
        // opacity: 0.75;
        border: none;
      }
      >.list>.item:not(:last-of-type) {
        margin-right: $gutter*2;
      }
      >.list>.item:nth-of-type(2n) {
        border: none;
      }
    }

  }


}

.headline-block {
  & {
    @include outer-container();

    // display: block;
    width: 100%;

    height: 500px;
    height: calc(60vh - 80px);

    // margin-top: 80px;
    padding: 1em;

    display: flex;
    flex-flow: column;
    // align-items: center;
    justify-content: center;
  }

  >h1 {
    font-size: 24px;
    font-weight: 400;
    // line-height: (48px * 1.7);
  }
  >h2 {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
  }

  @include media($medium-screen-up) {

    margin-top: 80px;

    >h1 {
      font-size: 40px;
      font-weight: 400;
    }
    >h2 {
      font-size: 32px;
      font-weight: 400;
    }
  }
}

// FIXME:  move me

.cover-photo {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: auto;
  padding-bottom: 200%;

  background: 100% black no-repeat;
  background-position: center;

  @include media($medium-screen-up) {
    width: 100%;
    height: 100%;
    padding: 0;

    background-attachment: fixed;
    background-size: cover;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, black, transparent 50%);
  }
}

.casestudy-block {
  & {
    @include outer-container();

    position: relative;
    display: flex;
    flex-flow: column;

    color: tint(black, 30);
    background: white;

    margin: 0 auto;

    @include media($medium-screen-up) {
      margin: $column auto 0;
    }
  }

  .section {
    position: relative;
    overflow: hidden;
    overflow-y: visible;
    background: white;
  }
  .section.-gray {
    background: tint(black, 95);
  }

  .section >.figure {
    display: block;
    overflow: hidden;

    background: tint(black, 50) center no-repeat;
    background-size: cover;
    // background-attachment: fixed;

    height: 0;
    width: 100%;
    padding-bottom: calc(9/16 * 100%);
  }

  // .section >.figure.-fixed {
  //   background: tint(black, 50) center center no-repeat;
  //   background-size: cover;
  //   background-attachment: fixed;
  // }

  .section >.figure.-r-1-3 {
    padding-bottom: calc(1/3 * 100%);
  }

  // .section >.figure.-r-1-2 {
  //   padding-bottom: calc(1/2 * 100%);
  // }

  .section >.textblock {
    @extend .type-system-sans;
    font-size: 16px;

    display: block;
    overflow: hidden;
    // padding: 1em;
    padding: $gutter;

    @include media($medium-screen-up) {
      @include span-columns(6 of 12);
    }
  }

  .section >.textblock.-center {
    @include media($medium-screen-up) {
      @include span-columns(8 of 12);
      @include shift(2);
    }
  }

}


.casestudy-block .section.-hero {
  & {
    background: white;
  }

  >.figure {
    position: relative;
    padding-bottom: 100%;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, black -25%, transparent 50%);
    }
  }

  >.textblock {
    background: white;

    border-top: 6px solid lighten($green, 10);

    @include span-columns(12 of 12);

    // p {max-width: calc(100% - 90px);}
  }

  .block {
    @include span-columns(12 of 12);
  }
  .block .figure {
    background: center no-repeat;
    background-size: cover;
    padding-bottom: calc(9/16 * 100%);
  }


  @include media($medium-screen-up) {
    >.figure {
      padding-bottom: calc(9/16 * 100%);
      // padding-bottom: calc(1/2 * 100%);
    }
    >.textblock {
      transform: translateY(-$column);
      // position: relative;
      background: white;
      padding: $gutter*2;
      padding-bottom: 0;

      @include span-columns(7 of 12);

      margin-right: 0;

      p {max-width: calc(100% - 90px);}
    }
    .block {
      @include span-columns(5 of 12);
      padding: $gutter 0;
    }
    // .block .figure {
    //   background: center no-repeat;
    //   background-size: cover;
    //   padding-bottom: calc(9/16 * 100%);
    // }
  }
}

.casestudy-block .section.section-b {
  & {
    background: tint(black, 95);
  }

  .textblock:first-child {
    background: white;
    // padding: $gutter * 2;
    // padding: $column $column ($column*2) ;
    padding-bottom: ($column*1.6);

    @include span-columns(12 of 12);
    // @include shift(1);
  }

  .textblock:last-child {
    padding: 0 $gutter $gutter ;
    // padding-bottom: $column;
    @include span-columns(12 of 12);
    // @include shift(2);
  }

  .figure {
    // width: 50%;
    @include span-columns(10 of 12);
    @include shift(1);
    // margin: 0 auto;

    background: gray center no-repeat;
    background-size: cover;
    padding-bottom: $column*2;
    // padding-bottom: calc(9/16 * 100%);

    // transform: translateY(-50%);
    transform: translateY(-$column);
  }


  @include media($medium-screen-up) {
    >.textblock:first-child {
      padding: $column ($column+$gutter) ($column*2);

      // background: white;

      @include span-columns(10 of 12);
      @include shift(1);
    }

    >.textblock:last-child {
      padding: 0 $gutter $column;
      // padding-bottom: $column;

      @include span-columns(8 of 12);
      @include shift(2);
    }

    >.figure {
      // width: 50%;
      @include span-columns(8 of 12);
      @include shift(2);
      // margin: 0 auto;

      // background: rgba(black, 0.25);
      padding-bottom: 320px;
      // padding-bottom: calc(9/16 * 100%);

      // transform: translateY(-50%);
      transform: translateY(-$column);
    }
  }
}

.casestudy-block .section-cta {
  >.sticky-block {
    display: flex;
    flex-flow: column;
    // justify-content: center;
    align-items: center;

    height: auto;
    padding: $gutter;

    // background: $green;
    // color: white;
  }

  >.sticky-block a {
    flex: 1;

    display: block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: black;
    background: white;

    line-height: 60px;
    border-radius: 60px;

    padding: 0;
    margin: $gutter 0 0;

    width: 100%;
    max-width: 300px;

  }
  >.sticky-block span {
    flex: 1 1 auto;

    font-weight: 600;
    font-size: 18px;

    text-align: center;
  }

  & {
    overflow: visible;
    color: white;
    background: $green;

    @include transition(background-color 500ms ease);
  }

  &.is_stuck {
    top: auto;
    bottom: 0;
    z-index: 9999999999999;
    background: rgba($green, 0.95);
  }

  @include media($medium-screen-up) {
    >.sticky-block {
      display: flex;
      flex-flow: row nowrap;

      height: 100px;
      padding: 0 $gutter;
    }

    >.sticky-block a {
      margin: 0;
    }
  }

}

.casestudy-block .section-a {
  & {
    display: flex;
    flex-flow: column-reverse;

    background: tint(black, 95);
    // padding: $gutter 0;
  }

  .textblock {
    @include span-columns(12 of 12);
    padding: $gutter*2 $gutter;
  }

  .block {
    @include span-columns(12 of 12);
    // @include shift(1);

    // display: flex;
    // padding: $gutter 0;
    // align-items: center;
  }
  .block .figure {
    // flex: 1;

    background: center no-repeat;
    background-size: cover;
    padding-bottom: calc(2/3 * 100%);
  }


  @include media($medium-screen-up) {
    display: flex;
    flex-flow: row nowrap;
    padding: $column 0;

    .block {
      @include span-columns(5 of 12);
      @include shift(1);

      display: flex;
      // padding: $gutter 0;
      // align-items: center;
    }
    .block .figure {
      flex: 1;

      background: center no-repeat;
      background-size: cover;
      // padding-bottom: calc(9/16 * 100%);
    }

    .textblock {
      @include span-columns(5 of 12);
    }
  }

}

.investors-block {
  & {
    @include outer-container();

    position: relative;
    z-index: 0;
    overflow: hidden;

    margin: 0 auto;
    background: $green;

    border-top: 1px solid rgba(white, 0.1);
  }

  >.textblock {
    @extend .type-system-sans;
    text-align: center;

    margin: ($column*1.1) auto $column;
  }
}

.investors-list {
  & {
    display: flex;
    flex-flow: row wrap;

    width: 100%;

    // padding: $gutter ;
    background: rgba(black, 0.1);

    @include media($medium-screen-up) {
      padding: 0 $gutter;
    }
  }

  >.item {
    flex: 1 calc(100% / 2);
    min-height: $column * 2;
    align-items: center;

    padding: $gutter;
    color: white;

    @include media($medium-screen-up) {
      flex: 1 calc(100% / 5);
      display: flex;
      flex-flow: row nowrap;
      padding: 0;
    }
  }

  >.item >.avatar {
    @include size(40px);
    background: gray center no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 50%;

    margin: 0 1em 1em 0;
  }
  >.item >.info {
    flex: 1;
    @include ellipsis();
  }

  >.item >.info >.name {
    font-size: em(16);
  }
  >.item >.info >.title {
    font-size: em(12);
  }
  >.item >.info >.company {
    font-size: em(12);
    font-weight: bold;
  }


}

.featured-block {
  @include outer-container();

  position: relative;
  z-index: 1;

  margin: 0 auto;

  // background: rgba(black, 0.5);
}

// .featured-block >.textblock {
//   @extend .type-system-sans;
//
//   // margin: $column auto 0;
//   padding: $column $gutter 0;
//
//   @include media($medium-screen-up) {
//     @include span-columns(8 of 12);
//     @include shift(2);
//
//     // padding: 0 ;
//   }
// }

.featured-block >.projects-list {
  // padding:  $gutter;
  // transform: translateY(90px);
}

.contact-block {
  & {
    display: block;
    position: fixed;
    // z-index: 10;
    z-index: 999999;
    top: 0;
    left: 0;

    overflow: auto;

    width: 100%;
    height: 100%;

    padding: $gutter;

    color: white;

    background: 100% black no-repeat;
    background: rgba(lighten($green, 10), 1);
    // background-position: center;

  }

  >.exit {
    position: absolute;
    top: 0;
    right: 0;

    @include size( 100px 100px );

    background: url("/images/svg/optimized/x-icon-02.svg") center no-repeat;
    background-size: 24px;

    cursor: pointer;
  }

  form {
    display: block;
    background: white;
    background: none;

    font-size: 18px;

    margin: 2em auto;

    // color: white;

    max-width: 500px;

    // box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);

    label {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: rgba(white, 0.75);

      line-height: 24px;

      background: none;
      border: none;
      outline: none;

      box-shadow: none;
    }

    textarea,
    input {
      font-size: 14px;
      font-weight: 400;
      // text-transform: uppercase;

      color: white;
      line-height: 48px;
      padding: 0 1em;

      background: rgba(black, 0.05);
      border: none;
      outline: none;
      box-shadow: none;

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
        background: rgba(black, 0.1);
        // box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
      }
    }

    textarea {
      padding: 1em;
      line-height: 1.7;
    }
  }

  button[type='submit'] {
    display: block;

    font-size: 16px;
    font-weight: 600;
    text-align: center;

    color: black;
    // background: lighten($green, 10);
    background: white;
    border: 1px solid lighten($green, 10);

    width: 100%;
    height: 48px;
    line-height: 46px;
    border-radius: 48px;

    margin: 2em auto;
    padding: 0 40px;


    // transition: background-color 500ms ease;
    // transform: translateY(75%);

    &:hover,
    &:focus {
    //   color: white;
    //   background: lighten($green, 10);
      outline: none;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    }
  }

  .cta-row {
    max-width: 500px;
    margin: 2em auto;
    // padding: 0 2em;

    color: white;

    ul {
      display: flex;
      flex-flow: column;;
      align-items: center;
      justify-content: center;

      @include media($medium-screen-up) {
        flex-flow: row nowrap;
      }
    }

    li {
      flex: 1;
      color: white;
      font-weight: 600;
      text-align: center;
      // margin: 0 1em;

      width: 100%;
      margin: 8px 0;
    }

    a {
      @extend %round-button;

      &:hover {
        color: black;
        background: white;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
      }
    }
  }

  .text-row {
    font-size: 18px;
    max-width: 500px;
    margin: 2em auto;
    // padding: 0 2em;
    color: white;

    @extend .type-system-sans;
  }

}

%round-button {
  display: block;

  font-size: 14px;
  font-weight: 600;
  text-align: center;

  color: white;
  // background: lighten($green, 10);
  // color: black;
  // background: white;
  // border: 1px solid lighten($green, 10);
  border: 1px solid white;

  // min-width: 200px;
  height: 48px;
  line-height: 46px;
  border-radius: 48px;

  margin: 0 auto;
  padding: 0 0;
}
