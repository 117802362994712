.form-card {
  & {
    @extend %card-style;
    @include row(table);

    padding: em(8);
    background: none;

    @include media($medium-screen-up) {
      padding: em(0);
    };
  }

  >.body {
    @extend %type-system;

    margin: 0 auto;
    padding: em($gutter * 1.2);
    /* @include pad($gutter * 1.2); */
    font-size: em(12);
    background: white;
    color: tint(black, 20);

    @include media($medium-screen-up) {
      @include span-columns( 6 of 12 );

      padding: em($gutter * 2);
      font-size: em(16);
    };

  }
  >.figure {
    vertical-align: middle;
    padding: em($gutter * 2);
    background: tint(black, 96);

    @include media($medium-screen-up) {
      @include span-columns( 6 of 12 );
    };

    svg {
      display: block;
      margin: 0 auto;

      width: 100%;
      max-width: em(120);
    }

  }
}
