%covervid-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-background {
  & {
    /* @extend %covervid-wrapper; */
    display: none;
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background: black;

    @include media(min-width 1200px) {
      display: block;
      // position: fixed;
    };
  }

  // >.video {
  //   position: relative;
  //   height: 100%;
  //   width: 100%;
  //   /* min-width: 100%; */
  // }

  // &.-overlay {
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      @include size(100%);

      background: linear-gradient(0, rgba(black, 0.9), rgba(black, 0.6));
      // background-attachment: scroll;

    }
  // }
}


// FIXME:  move me

.videocover-photo {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  background: #000 center no-repeat;
  background-size: cover;
  background-image:
    linear-gradient(0, rgba(black, 0.9), rgba(black, 0.6)),
    url("/images/css/proposal-bg--large@2x.jpg")
    ;

  @include media(min-width 1200px) {
    background-image: none;
    background-color: rgba(black, 0);

    @include transition(background-color 3000ms $ease-out-quint);
  };

  &.-is-overlay {
    background-color: rgba(black, 1);
  }
}
