.-wip-showcase-page {
  background: white;

  .header-block {
    @include row(table);
    min-height: 80vh;
      vertical-align: center;

    .body {
      font-size: em(15);
      overflow: hidden;
      position: relative;
      z-index: 2;
      // background: rgba(black, 0.5);
      background: center no-repeat;
      background-image: url("/images/css/chef-photo-01.jpg");
      background-size: cover;

      color: white;
      padding: 50% em($gutter * 1.6) em($gutter * 2);

      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: -2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: rgba(black, 0.8);
      }

      @include media($medium-screen-up) {
        @include span-columns(1 of 2);
        width: 40%;
        & {
          background: tint(black, 8);
          padding: em($gutter * 2) em($gutter * 3);
        }
        &:after {
          background: none;
        }
      }
    }
    .figure {
      @include media($medium-screen-up) {
        @include span-columns(1 of 2);
        width: 60%;
      }

      background: black 100% center no-repeat;
      background-image: url("/images/css/chef-photo-01.jpg");
      background-size: cover;
    }
  }
}

.container-block {
  & {
    @extend .type-system-sans;
  }
  >.-grid,
  &.-grid {
    // @include outer-container();
    @extend %grid;
  }
  &.-pad,
  .-pad  {
    padding: em(90) 0;
  }

  .row.-center {
    h1 {
    text-align: center;
    }
    p {
    margin: 0 auto;
    max-width: em(600);

    }
    .textbox {
      margin: 0 auto;
      max-width: em(600);
    }
  }

  .-svg-usa-map {
    background: center no-repeat;
    background-size: contain;
    background-image: url('/images/svg/usa-map-01.svg');

    max-width: em(500);
    margin: 0 auto;
    width: 100%;
    height: 0;
    padding-top: 30%;
  }
}

%grid {
  @include outer-container();

  >.row {
    @include row();

    padding: em($gutter * 1);

    @include media($medium-screen-up) {
      padding: auto;
    };

    &.-col-2 {
      >div {
        & {
          min-height: 100px;
        }
        @include media($medium-screen-up) {
          @include span-columns(1 of 2);
          @include omega(2n);
        };
      }
    }
    &.-col-3 {
      >div {
        & {
          min-height: 100px;
        }
        @include media($medium-screen-up) {
          @include span-columns(4 of 12);
        };
      }
    }
  }
}

.-style-01 {
  background: white;

  &+& {
    border: solid tint(black, 90);
    border-width: 1px 0 0;
  }
}

.-style-02 {
  background: $dark-green;
  color: white;
}

.-style-03 {

  >div {
    // @include media($medium-screen-up) {
    //   @include span-columns(1 of 2);
    //   padding: em($gutter * 3);
    // }

    // padding: em($gutter * 2) em($gutter * 1);
    background: tint(black, 94);
    border: solid white;
    border-width: 0 1px;

    @include media($medium-screen-up) {
      @include row(table);
    }
  }

  .col {
    @include media($medium-screen-up) {
      @include span-columns(1 of 4);
    }

      @include pad();
    border: 1px solid white;
    // border-width: 0 1px;
  }

  ul {
    font-size: em(14);
    margin-bottom: em(16);
    // max-width: em(300);
    // margin: 0 auto;
    // @include row(table);
  }
  ul li {
    // margin-bottom: em(16 * 1.0);
  }
}

.list-row {
  & {
    list-style-type: none;
    padding: 0;
  }
  >li {
    @include media($medium-screen-up) {
      @include span-columns(1 of 5);
      @include pad();
    }
  }
}

.cta-row {
  .button {
    margin: 0 auto;
    max-width: em(280);
  }
}


.form-card-02 {
  & {
    @extend %card-style;
    @include row(block);

    padding: em(8);
    background: none;
    outline: hidden;

    @include media($medium-screen-up) {
      padding: em(0);
    };
  }

  >.body {
    @extend .type-system-sans;

    display: block;
    font-size: em(12);
    margin: 0 auto;
    padding: em($gutter * 1.2);
    width: 100%;
    background: white;

    @include media($medium-screen-up) {
      max-width: 50%;
      padding: em($gutter * 2);
      font-size: em(16);
    };
  }
}
