%invest-button {
  & {
    display: block;

    margin: 0;
    padding: 0;
    width: 100%;
    line-height: (56px);

    // background: lighten($green, 10);
    background: lighten($green, 10) center no-repeat;

    color: white;
    font-weight: bold;
    text-align: center;

    overflow: hidden;
    border-radius: $base-border-radius;

    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.0);

    // background: lighten($green, 10) center no-repeat;
    // background-image: url('/images/svg/oval.svg');
    // background-size: auto 50%;
  }

  &:disabled {
    background: lighten($green, 10);
    box-shadow: none;
  }

  @extend %-noselect;

  @include transition(
    background-color 400ms ease,
    box-shadow 600ms ease
  );

  &:hover {
    background-color: lighten($green, 20);
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
  }

  &.-loading {
    background: lighten($green, 10) center no-repeat;
    background-image: url('/images/svg/oval.svg');
    background-size: auto 50%;

    // @include hide-text();
    font-size: 0;
    color: rgba(white, 0.0);
  }

}
