%base-size {
  font-size: 16px;
}

%base-section {
  & {
    display: block;
    position: relative;
    background: white;
    color: black;
    overflow: hidden;
  }

  >.header {
    font-size: 1.8rem;
    text-align: center;

    padding: 1rem;
    margin: 3rem auto;

    &.-align-left {
      text-align: left;
      @include outer-container(1000px);
    }

    @include media($medium-screen-up) {
      font-size: 2.5rem;
    }
  }

}

%flex-block {
  & {
    display: block;
    // display: flex;
    // flex-flow: column;
    @include outer-container(1000px);
  }

  @include media($medium-screen-up) {
    display: flex;
    flex-flow: row wrap;
  }

  >.item {
    flex: 1 auto;
  }

}

$bd-color: tint(black, 90);


.partners-page-body {
  & {
    @extend %base-size;

    position: relative;
    z-index: 1;

    display: block;

    // padding: 1rem;
    // margin: rem(120) auto 0;
    // background: #000;
  }

  .form-wrapper {

    .row {
      @include outer-container;
    }

    fieldset {
      border: none;
      background: inherit;
      color: #000;
    }

    label {
      color: $bd-color;
    }

    .col-6 {
      padding: 5px 10px;
      margin-bottom: 0;
      @include span-columns(6);

      @media screen and (max-width: 767px) {
        @include span-columns(12);
      }
    }

    .col-12 {
      @include span-columns(12);
      padding: 0.35em 0.625em 0.75em;
    }
  }

  .header-section {
    & {
      display: flex;
      flex-flow: column;
      justify-content: center;

      overflow: hidden;

      height: 100%;
      padding: 1rem;
      padding-top: rem(180px);

      // height: calc(100vh - 120px);

      color: white;
      background: rgba(black, 0.75);
    }

    .headline {
      @include outer-container(1000px);

      font-size: rem(10);
      text-align: left;

      @include media($medium-screen-up) {
        font-size: rem(16);
        text-align: center;
      }
    }
    //
    h1 {
      font-size: 2.5em;
      font-weight: 600;
      // line-height: (48px * 1.7);
    }
    h2 {
      font-size: 1.5em;
      font-weight: 400;
      font-style: italic;
    }

    // .actions {
    //   margin: 3rem auto 0;
    //   width: 320px;
    // }
    //
    // .actions a {
    //   font-weight: 600;
    //   color: tint(black, 20);
    //   background: white;
    //   border-radius: 4px;
    //   // border-left: 1px solid tint(black, 90);
    //
    //   // flex: 1 0 auto;
    //
    //   padding: 0 1.6em;
    //   line-height: (60px);
    //   max-width: none;
    //   display: block;
    //   width: 100%;
    //   text-align: center;
    // }

  }

  .actions {
    // display: flex;
    // flex-flow: row nowrap;
    //
    // justify-content: center;
    display: block;

    min-width: 60%;
    max-width: 600px;

    margin: 3rem auto 0;
    // padding: 1rem;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;

      justify-content: center;
      min-width: 60%;

      button {
        flex: 1 1 auto;
        width: 50%;
        max-width: 300px;
      }
    }
  }

  .actions button {
    // flex: 1 1 auto;
    display: block;

    width: 100%;

    // margin: 0.5rem auto;
    margin-bottom: 0.5rem;
    padding: 0 1.6em;

    text-align: center;
    font-weight: 600;
    line-height: (60px);
    color: white;
    background: lighten($green, 10);
    border-radius: 4px;

    @include ellipsis(100%);

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  .testimonials-block {
    & {
      display: block;
      // margin: 0 auto;

      // justify-content: center;

      overflow: hidden;
      max-width: 100%;

      padding: 2rem 1rem;

      background: rgba(black, 0.8);
    }

    @include media($large-screen-up) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }

    >.item {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column;

      width: 100%;
      max-width: rem(360);
      background: white;

      margin: 0 auto;

      &:not(:last-of-type) {
        border-bottom: 1px solid $bd-color;

        @include media($large-screen-up) {
          margin-right: 1rem;
        }
      }
    }

    .quoteblock {
      flex: 1;
      // align-self: flex-end;
      font-size: rem(16);

      margin: 1.5rem auto;
      padding: 0 2rem;

    }

    .userblock {
      // flex: 0;
      // align-self: flex-end;

      width: 100%;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      // justify-content: center;

      // padding: 1rem 2rem;
      margin: 0 auto;
      padding: 1.5rem 2rem;

      // border-top: 1px solid tint(black, 94);
    }
    .userblock >.avatar {
      @include size(3.5rem);
      flex: 0 0 auto;
      background: gray center no-repeat;
      background-size: cover;

      overflow: hidden;
      border-radius: 50%;

      margin-right: 1rem;
    }
    .userblock >.name {
      font-size: rem(14);
      font-weight: 600;
    }
  }

  .form-section {
    >.header {
      font-size: 1.8rem;
      text-align: center;

      padding: 1rem;
      margin: 3rem auto;

      color: #fff;

      &.-align-left {
        text-align: left;
        @include outer-container(1000px);
      }

      @include media($medium-screen-up) {
        font-size: 2.5rem;
      }
    }
  }

  .howitworks-section {
    & {
      @extend %base-section;

      border-bottom: 1px solid $bd-color;
    }

    >.flex-block {
      @extend %flex-block;

      // padding: 5rem 0;
      margin: 3rem auto;;
      // align-items: center;
      // justify-content: center;
    }

    >.flex-block >.item {
      flex: 1 1 auto;
      margin: 0.5rem;
      text-align: center;

      max-width: 315px;

    }
    >.flex-block >.item >.figure {
      @include size(5rem);
      margin: 1rem auto;
    }
    >.flex-block >.item >.figure svg {
      fill: $green;
      height: 100%;
      width: 100%;
    }
  }

  .investorbase-section {
    & {
      @extend %base-section;

      border-bottom: 1px solid $bd-color;
    }
    >.flex-block {
      // flex-flow: column;
      display: block;
      width: 100%;

      @include media($medium-screen-up) {
        display: flex;
        flex-flow: row wrap;
      }
    }
    >.flex-block >.item {
      flex: 1 1 (100% / 3);
      padding: 3rem 2rem;
      border-top: 1px solid $bd-color;

      text-align: center;

      // min-height: 12.5rem;

      &:not(:last-of-type) {
        border-right: 1px solid $bd-color;
      }

      .figure {
        // background: gray;
        @include size(3rem);
        margin: 1rem auto;
      }
      .figure svg {
        fill: $green;
        height: 100%;
        width: 100%;
      }
      .title {
        font-size: rem(18);
        font-weight: 600;
        margin: 1rem auto;
      }
    }
  }

  .mobileapp-section {
    & {
      @extend %base-section;
      border-bottom: 1px solid tint(black, 90);
    }

    >.flex-block {
      display: flex;
      flex-flow: column;
      align-items: stretch;
      justify-content: flex-end;

      @include media($medium-screen-up) {
        flex-flow: row nowrap;
      }
    }

    >.flex-block >.item {
      flex: 1 1 auto;

      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      // max-width: 90%;/
      min-width: 50%;
      // margin: 0 auto;
      padding: 1rem;

      ul {
        @extend ul%default-ul;
        max-width: 480px;
        // padding: 1rem;

        li {
          font-size: rem(18);
          // line-height: 1.5rem;
          margin-bottom: 2rem;
        }
      }

    }
    >.flex-block >.item.-is-figure {
      // max-width: 50%;
      display: block;
      height: 90vh;
      background: none center no-repeat;
      background-image: url('/images/css/IMG_4311_iphone6plus_gold_portrait.png');
      background-size: auto 100%;
    }
  }

  .funds-section {
    & {
      @extend %base-section;
      border-bottom: 1px solid tint(black, 90);
    }

    >.flex-block {
      @extend %flex-block;

      margin: 3rem auto;
    }
    >.flex-block >.item {
      flex: 1 1 50%;
      // min-width: 50%;
      padding: 1rem;
    }

  }

  .greathowdoi-section {
    & {
      @extend %base-section;
      border-bottom: 1px solid tint(black, 90);
      background: tint(black, 95);;
    }

    >.flex-block {
      @extend %flex-block;
      margin: 4rem auto;
      max-width: rem(600);
    }

    ol {
      @extend ol%default-ol;
      margin: 1rem;
    }
    ol li {
      font-size: rem(22);
      margin-bottom: 2rem;
    }

    >.actions {
      display: block;
      // display: flex;
      // flex-flow: row nowrap;
      //
      // justify-content: center;

      // min-width: 60%;
      max-width: 600px;

      margin: 3rem auto ;
      padding: 1rem;

      @include media($medium-screen-up) {
        display: flex;
        flex-flow: row nowrap;

        justify-content: center;
        min-width: 60%;

        a {
          flex: 1 1 auto;
          width: 50%;
          max-width: 300px;
        }
      }
    }
    >.actions a {
      // flex: 1 1 auto;
      display: block;

      width: 100%;
      // max-width: 300px;

      // margin: 1rem auto;
      padding: 0 1.6em;

      text-align: center;
      font-weight: 600;
      line-height: (60px);
      color: white;
      background: lighten($green, 10);
      border-radius: 4px;

      @include ellipsis(100%);

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
  .cta-section {
    @extend .greathowdoi-section;
  }

  .featuredprojects-section {
    @extend %base-section;
    background: tint(black, 95);
  }

  .cost-section {
    & {
      @extend %base-section;
    }

    >.flex-block {
      // @extend %flex-block;
      // margin: 3rem auto;
      // display: flex;
      // flex-flow: column;
      display: block;

      @include media($medium-screen-up) {
        display: flex;
        flex-flow: row nowrap;
      }
    }
    >.flex-block >.item {
      flex: 1 1 50%;
      padding: 1rem;

      border: 1px solid $bd-color;

      h1 {
        max-width: rem(400);
        margin: 3rem auto;
        padding: 0 1.25rem;
      }
      ul {
        max-width: rem(400);
        margin: 3rem auto;
      }
    }

    @extend .type-system-sans;

    ul {
      @extend ul%default-ul;
    }
    ul li {
      font-size: rem(22);
      margin-bottom: 2rem;
    }

    .ee-cost {
      color: lighten($green, 10);
    }
    .other-cost {
      color: tint(red, 20);
    }

  }

  .featuredprojects-section {
    .button {
      background: gray;
      border: none;
    }
  }

  // .pdfdownload-section {}

  //
  // FIXME: cleanup later

  .pdfdownload-block .pdf-success {
    color: lighten($green, 10);
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    margin: 2rem auto;
  }


  .pdfdownload-block {
    & {
      display: flex;
      flex-flow: column;
      // align-items: center;
      justify-content: center;

      position: relative;
      z-index: 0;

      overflow: hidden;

      width: 100%;
      min-height: 500px;

      padding: $gutter;

      background:
        linear-gradient(to top, $dark-green, rgba(black, 0.5)),
        url('https://images.unsplash.com/photo-1414235077428-338989a2e8c0?crop=entropy&dpr=2&fit=crop&fm=jpg&h=800&ixjsv=2.1.0&ixlib=rb-0.3.5&q=50&w=1000') black center no-repeat
      ;
      background-size: auto, cover;
    }

    >.icon {
      height: 80px;
      width: 100px;
      margin: 1em auto;

      fill: white;

      >svg {
        height: 100%;
        width: 100%;
      }
    }

    >.text {
      color: white;
      display: block;
      margin: 0 auto;
      max-width: 600px;

      text-align: center;

      font-size: 26px;
      font-weight: 400;

      h1 {
        line-height: 1.4;
      }
    }

    form {
      display: block;
      margin: 0 auto;

      // max-width: 600px;
      width: 320px;
      max-width: 100%;

      input[type='email'] {
        @include ellipsis();

        display: block;

        margin: 0.8em auto ;
        padding: 0 2em;

        line-height: (60px);

        color: tint(black, 20);
        background: rgba(black, 0.75);

        border-radius: 4px;

        outline: none;
        border: none;

        @include transition(background-color 600ms ease);

        &::-webkit-input-placeholder {
          color: tint(black, 50);
          text-align: center;
        }

        &:focus,
        &:active {
          background: rgba(white, 1);
        }

      }
      button[type='submit'] {
        color: tint(black, 20);
        background: white;
        border-radius: 4px;
        // border-left: 1px solid tint(black, 90);

        // flex: 1 0 auto;

        padding: 0 1.6em;
        line-height: (60px);
        max-width: none;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }

  .partners-list {
    .item {
      padding: 4rem;
      flex: 1 1 33% !important;
      max-width: 500px;

      > .img-wrapper {
        min-height: 120px;
        position: relative;

        > img {
          max-height: 80px;
          position: absolute;
          bottom: 20px;
        }
      }
    }
  }

}


