@import "_swipebox.scss";

.photo-card {
  & {
    // @extend %card-style;

    margin-bottom: em($gutter);
  }

  >.head {
    padding: em($gutter) 0;
  }

  >.figure {
    position: relative;
    display: inline-block;
    overflow: hidden;

    height: 0;
    width: 25%;
    padding-bottom: 25%;
    background: gray;
  }



  .figure {
    a {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    img {
      display: block;
      height: 100%;
      width: auto;
      max-width: none;
    }
  }

  >.figure + .figure {
    border-left: 2px solid whitesmoke;
  }

  >.body {
    overflow: hidden;
    margin: em($gutter) auto;
    // padding: 0 em($gutter*1.2);
    /* background: whitesmoke; */
  }
}
