.purchase-new-wrapper {
  position: static;
  overflow: inherit;
  padding-top: 40px;
  padding-bottom: 40px;
}

.purchase-new {
  & {
    @include outer-container();

    background: white;

    // display: flex;
    // flex-flow: column;
    padding-top: 10px;
  }

  .cc_exp_field {
    display: inline-block !important;
    width: 50px !important;
  }

  #cc_cvc {
    width: 60px !important;
  }

  .file-bttn {
    @include ellipsis();

    display: block;
    position: relative;

    // color: tint(black, 20);
    background: tint(black, 95);

    border: 1px solid tint(black, 90);
    border-radius: $base-border-radius;

    margin: em(16px) auto;
    padding: 0 em(32);
    line-height: (80px);
    font-size: em(16px);
    font-weight: bold;

    color: lighten($green, 5);

    @include transition(border 400ms ease);

    &:hover {
      text-decoration: underline;
      border-color: lighten($green, 5);
    }

    &::before {
      content: '';
      display: block;

      background: center no-repeat;
      background-size: 80%;
      background-image: url('/images/svg/icon-document.svg');

      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: auto;
      // z-index: 0;

      margin: auto;
      margin-top: 4px;

      height: 100%;
      width: (80px);
    }
  }

  label, input {
    font-family: inherit !important;
    color: inherit !important;
  }

  button {
    font-family: inherit !important;
  }


  #purchase-header {
    text-align: center;
    margin: 0 auto;
    color: #333;
    padding: 2rem 4rem 0;

    @media screen and(max-width:500px) {
			padding: 2rem 2rem 0;
    }

    h1 {
      font-size: 1.8em;
    }

    h3 {
      font-weight: normal;
    }

		#promo-text {
			@media screen and(max-width:500px) {
				text-align: left;
			}
		}
  }

  .vip-benefits {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .benefit {
      text-align: center;
      padding: 20px;
      max-width: 33%;
      min-width: 200px;
      margin: 0 auto;

      h4 {
        padding-top: 10px;
      }
    }
  }

  .label-info {
    font-size: 14px;
    color: lighten($dark-gray, 40);
  }

  .mobile-buy-btn {
    @media screen and (min-width: 768px) {
      display: none;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
		background-color: $green;
		color: #fff;
		text-align: center;
		font-size: 1.2rem;
		padding-top: 15px;
		z-index: 100001;
  }
}

.title-head {
  & {
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    margin-bottom: $gutter;
    background: gray center no-repeat;
    background-size: cover;

    height: (200px);

  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(0deg, rgba(tint(black, 0), 0.75) 0, transparent);
  }

  >.title {
    position: relative;
    z-index: 10;
    font-size: em(24);
    font-weight: 600;
    text-align: center;
    line-height: (60px);
    color: white;
    // background: gray;
  }
  >.location {
    position: relative;
    z-index: 10;
    font-size: em(14);
    font-weight: 400;
    text-align: center;
    // line-height: 180px;
    color: white;
  }


}

.investment-table {
  background: tint(black, 10);
  color: white;

  overflow: visible;
  border-radius: $base-border-radius;

  margin: em(10);

  // @include media($medium-screen-up) {
  //   display: flex;
  //   flex-flow: row nowrap;
  // }

  >.row.-header {
    font-size: em(18px);
    background: black;

    >.row {
      height: em(60);
    }

    .cell.-selected {
      color: white;
      background: lighten($green, 10);
    }

    .cell.-is-unavailable {
      color: tint(black, 20);
      pointer-events: none;
      b {color: inherit;}
    }

    .cell > b {
      color: #fff;
      // font-family: Helvetica !important;
    }

    .badge {
      position: absolute;
      top: 0;
      right: 0;

      font-size: 12px;
      font-weight: bold;
      line-height: 2rem;

      background: red;
      padding: 0rem 1rem;

      border-radius: 2rem;
      // @include size(2rem);

      transform: translate(0.8rem, -40%);
      box-shadow: 0px 2px 4px 0px rgba(black, 0.25);
      @include ellipsis(100%);
    }

  }

  >.row.-select {
    flex-flow: column-reverse;

    min-height: 80px;
    margin-top: 20px;

    @include media($medium-screen-up) {
      margin: 0;
      flex-flow: row;
    }

    .cell:not(:last-of-type) {
      border: none;
      @include media($medium-screen-up) {
        border-right: 1px solid tint(black, 20);
      }
    }

  }

  >.row {
    display: flex;
    flex-flow: column;
    text-align: center;
    // min-height: em(60);
    // margin: em(20) auto;

    // min-height: em(80);


    @include media($medium-screen-up) {
      flex-flow: row nowrap;
      align-items: center;
      // margin: 0 auto;
      height: em(60);
      border-bottom: 1px solid tint(black, 5);
      // padding: em(20);
    }
  }

  >.row >.label {

    display: block;
    font-size: 14px;
    text-align: center;

    color: tint(black, 50);

    // line-height: 60px;

    abbr {
      @extend %-noselect;
    }

    @include ellipsis();

    @include media($medium-screen-up) {
      // flex-flow: row nowrap;
      flex: 1 0 35%;
      font-size: em(14);
      text-align: left;
      color: tint(black, 80);
      margin: 0;
      padding: 0 em(40);
    }
  }


  >.row >.row {
    display: inline-flex;
    align-items: center;
    // margin: 0 auto;

    width: 100%;
    height: em(60);
    min-height: 100%;
    margin: 0 auto;
  }
  .-summary {
    font-size: rem(12);
    justify-content: center;
    >.cell {
      padding: 0 1rem;
    }
  }

  .cell {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;

    >b {
      color: #fff;
    }

    >.badge {
      font-size: 12px;
      font-weight: bold;
      line-height: 2rem;
      margin: 0;
      padding: 0rem 1rem;

      border-radius: 2rem;
      box-shadow: 0px 2px 4px 0px rgba(black, 0.25);
      @include ellipsis(100%);

      &.-is-low {
        background: red;
      }
      &.-is-medium {
        background: #FFD700;
      }
      &.-is-high {
        background: lighten($green, 10);
      }
    }

    .button.-is-document {
      background: $action-color;
      @include ellipsis(100%);
      &:hover {
        background: darken($action-color, 10);
      }
    }

    >button {
      display: block;
      background: rgba($green, 0.25);
      width: 100%;
      // padding: 16px 0;

      line-height: 30px;
      margin: 16px;
      text-align: center;

      font-size: em(12);
      border: none;
    }

    >button:hover {
      background: $green;
    }

    // >button.-selected {
    //   background: $green;
    // }

    &:not(:last-of-type) {
      border-right: 1px solid tint(black, 20);
    }

    &.-selected {
      @include media($medium-screen-up) {
        // color: white;
        background: tint(black, 20);
      }
      >button {
        background: lighten($green, 10);
      }
    }

    &.-is-unavailable {
      color: tint(black, 20);
      pointer-events: none;

      button {
        background-color: tint(black, 25);
      }
    }
  }

  .no-visible-offers {
    padding: 2rem;
    text-align: center;
  }
}

.investment-description {
  background: tint(black, 10);
  color: white;

  margin: 10px;
  padding: $gutter;
  border-radius: $base-border-radius;
  font-size: em(14);
}

form#add-investment-form {
  .-actions button {
    @extend %invest-button;
  }

  color: #666666;

  .step-info {
    text-align: center;
    margin: 0 auto -40px;
  }
}

#account-reasoning {
  margin: 20px;
  border: 2px solid green;
  border-radius: 5px;
  padding: 10px 10px 0;
}
