.standalone-footer {

  @include outer-container;

  max-width: 100%;
  background: black;
  height: 300px;

  .row {
    padding-top: 50px;
    margin: 0 auto;
  }

  p, a {
    text-align: center;
    color: inherit;
  }
  
}
