.wip-page-section {
  & {
     overflow: hidden;

  }
  &.-bg-white {
    background: tint(black, 94);
    color: tint(black, 20);
  }
  &.-bg-green {
    background: tint($green, 96);
    color: tint($dark-green, 20);
    border: 0 solid tint($dark-green, 80);
    border-width: 1px 0;
  }
}

.restauranteurs-block {
  & {
    position: relative;
    background: tint(black, 100);
  }

  .-grid {
    @include outer-container();
  }
  .-row {
    // @include row(table);
    @include row(block, $direction: LTR);
    // @include row(block);
  }
  .-row-table {
    @include row(table);
  }
  >.body {
    overflow: hidden;
  }

  >.body >.textblock {
    & {
      // @include span-columns(1 of 1);
      padding: em($gutter * 1);
    }
    @include media(min-width 1024px) {
      @include span-columns(7 of 12);
      padding: em($gutter * 2);
    }

    @extend .type-system-sans;

    // padding: em($gutter * 3) 0;

    h1 {font-size: em(28)}
    h2 {font-size: em(16)}

    h3 {
      font-size: em(12);
      min-height: em(60);
    }
    p {font-size: em(16)}
  }

  >.body >.textblock >.info >.col{
    @include media(min-width 1024px) {
      @include span-columns(2 of 4);
      @include pad();
    }
  }
  >.body >.textblock >.info {
    & {
      padding: 0;
    }
    @include media(min-width 1024px) {
      padding-left: em($gutter * 2);
    }
  }
  >.body >.textblock >.header {
    margin-bottom: em($gutter * 2);
    padding-bottom: em($gutter * 1);
    border-bottom: 1px solid tint(black, 90);
  }


  .figure  {
    & {
      height: em(200);
      // background: tint(black, 90);
      // border-radius: 50%;
      //
      // margin-top: em(60);
      position: relative;
      overflow: hidden;
      // @include span-columns(1 of 1);
      // >.image.bottom{
      //   margin-top: em(200);
      // }

      background: black 100% center no-repeat;
      background-image: url("/images/css/chef-photo-01.jpg");
      background-size: cover;
    }

    &.-for-desktop {
        display: none;
      @include media(min-width 1024px) {
        display: table-cell;
      }
    }
    &.-for-mobile {
        display: block;
      @include media(min-width 1024px) {
        display: none;
      }
    }

    @include media(min-width 1024px) {
      @include span-columns(5 of 12);
      @include omega(2n);
      // height: em(400);
    }


  }

}

//
// .investors-block {
//   & {
//     position: relative;
//     background: tint(black, 95);
//   }
//
//   .-grid { @include outer-container(); }
//   .-row { @include row(block); }
//   .-row-table { @include row(table); }
//
//   >.body >.textblock {
//     & {
//       // @include span-columns(1 of 1);
//       padding: em($gutter * 1);
//     }
//     @include media(min-width 1024px) {
//       @include span-columns(7 of 12);
//       padding: em($gutter * 2);
//     }
//
//     @extend .type-system-sans;
//
//
//     h1 {font-size: em(28)}
//     h2 {font-size: em(16)}
//
//     h3 {
//       font-size: em(12);
//       min-height: em(60);
//     }
//     p {font-size: em(16)}
//
//   }
//   >.body >.textblock >.info >.col{
//     @include media(min-width 1024px) {
//       @include span-columns(4 of 8);
//       @include pad();
//     }
//   }
//
//   >.body >.textblock >.info {
//     & {
//       padding: 0;
//     }
//     @include media(min-width 1024px) {
//       padding-left: em($gutter * 1);
//     }
//   }
//   >.body >.textblock >.header {
//     margin-bottom: em($gutter * 2);
//     padding-bottom: em($gutter * 1);
//     border-bottom: 1px solid tint(black, 90);
//   }
//
//   .figure  {
//     & {
//       height: em(300);
//
//       background: black 100% center no-repeat;
//       background-image: url("/images/css/people-photo-01.jpg");
//       background-size: cover;
//     }
//     @include media(min-width 1024px) {
//       @include span-columns(5 of 12);
//     }
//   }
//
// }

.process-block {
  & {
    position: relative;
    // background: tint(black, 20);
  }

  .-grid { @include outer-container(); }
  .-row { @include row(block); }
  .-row-table { @include row(table); }


  >.body {
    @extend .type-system-sans;

    ul {
      list-style: none;
      padding: 0;
    }
  }
  >.body >.section {
    & {
      font-size: em(14);
      color: tint(black, 90);
      padding: em($gutter * 1) em($gutter * 0.2);
    }

    @include media(min-width 1024px) {
      padding: em($gutter * 2) em($gutter * 2);
      font-size: em(18);
      // @include span-columns(1 of 2);
    }

    &:nth-child(1) {
      background: tint(black, 10);
    }
    &:nth-child(2) {
      background: tint(black, 20);
    }
  }

  >.body >.section >.textblock {
    & {
      font-size: em(12);
      margin: 0 auto;
      max-width: 92%;
    }
    @include media($medium-screen-up) {
      max-width: 400px;
    }


    h1 {
      text-align: center;
      margin-bottom: ($gutter * 2);
    }
    a {
      display: block;
      margin: 0 auto;
      text-align: center;
      max-width: em(200);
    }
    ul {
      margin-bottom: ($gutter * 2);
    }
    li {
      margin-bottom: em(24);
    }
  }

  .icon {
    @include size(24px);
    border-radius: 50%;
    display: inline-block;
    margin-right: em(16);
    margin-bottom: em(-8);

    background: 100% center no-repeat;
    background-image: url("/images/svg/checkmark-icon.svg");
  }

}

//
%row {
  & {
    @include row();
  }

  &.-col-2 {
    >.block {
      @include span-columns(6 of 12);
    }
  }
}

.two-block {
  & {
    @extend .type-system-sans;

    margin: em(60) auto;
  }

  >.row {
    @extend %row;
  }

  >.headline {
    color: white;
    text-align: center;
  }

  .block {
    color: white;
    text-align: center;
    padding: em(90);
  }

  .avatar {
    @include size(80px);
    background: white;
    margin: 0 auto;
    border-radius: 50%;
  }

  .headline {
    font-size: em(12);
    text-align: center;
    margin: em(30) 0 em(60);
  }
}
//
.page-header .header-block {
  & {
    @extend .type-system-sans;

    position: relative;
    z-index: 0;
    margin: 0 auto;
    max-width: em(900);
    overflow: hidden;
  }

  >.text {
    & {
      font-size: em(10);

      // max-width: 90%;
      margin: 20% auto;
      padding: em($gutter);
    }
    @include media($medium-screen-up) {
      font-size: em(13);
      // max-width: 80%;
      margin: 20% 0 0;
    }

  }

  >.actions {
    margin-top: 10%;

    .button {
      margin-right: em(16);
      color: tint(black, 20);
      background: tint(black, 100);
      border-color: tint(black, 100);
    }
  }


}

.tile-block {
  & {
    @include row(block);
    margin: em($gutter * 3) auto;

  }

  >.tile {
    background: white;
    border: 1px solid tint(black, 90);
    border-radius: $base-border-radius;
    @include span-columns( 1 of 3 );
  }

  >.tile >.figure {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }
}

.titleblock {
  & {
    @extend .type-system-sans;

    padding: em($gutter);
    margin: em($gutter * 3) auto;

    text-align: center;
  }

  &.-green {
    color: $green;
  }

  &.-dark {
    color: $dark-green;
  }
}


.cta-block {
  & {
    position: relative;
    padding: em($gutter * 2) em($gutter * 1);
    color: inherit;
  }

  &.-gray {
    background: tint(black, 90);
    color: $dark-green;
  }
  &.-black {
    background: tint($dark-green, 0);
    color: white;
  }

  >.title {
    & {
      @include row();
      margin: 0 auto;
      max-width: 98%;
    }
    @include media($medium-screen-up) {
      text-align: center;
      // font-size: em(12);
    }
    @extend .type-system-sans;
  }

  >.row {
    @include outer-container();

    p {
      text-align: center;
    }
  }
  .form-block {
    display: flex;
    flex-flow: column;
    // align-items: center;

    @include media($medium-screen-up) {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      >div {
        width: 30%;
      }
    }

    >div {
      height: 40px;
      flex: 1 1 auto;
      margin: 10px;
    }

    button {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  //
  // .form {
  //   @include row();
  //   color: tint(black, 20);
  //
  //   .cell {
  //     @include media($medium-screen-up) {
  //       @include span-columns( 4 of 12 );
  //     }
  //   }
  //
  //   .button {
  //     display: block;
  //     width: 100%;
  //   }
  // }
}

.anchor-scrolldown {
  & {
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: auto;

    // max-width: em(200);

    // background: red;

    text-align: center;

    color: white;
    padding: em(40) em(20) em(90);

    opacity: 0.8;
    @include transition(opacity 300ms ease);
  }

  &:focus,
  &:hover {
    color: inherit;
    opacity: 1.0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    margin: auto;

    // width: 100%;
    // height: 100%;
    @include size(32px);

    background: none center no-repeat;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
    background-size: contain;

    @include animation(bounce 2s infinite);
  }
}


@include keyframes(bounce) {
	0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
	40% {
    @include transform(translateY(-20px));
  }
	60% {
    @include transform(translateY(-10px));
  }
}
