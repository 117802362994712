.menu-card {
  & {
    @extend %card-style;

    margin-bottom: em($gutter);
  }

  >.head {
    padding: em($gutter) 0;
  }
  >.figure {
    overflow: hidden;
  }
  >.body {
    overflow: hidden;
    margin: em($gutter) auto;
    // padding: 0 em($gutter*1.2);
  }
}
