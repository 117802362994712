.start-show {

  .text-block {
    padding: 2rem;
  }

}

.start-list {
  list-style-type: circle;
  margin-left: 2rem;
}
