.apply-bar {
  & {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;

    width: 100%;
    height: em(80);

    // color: white;
    // background: lighten($green, 10);
    color: white;
    background: rgba(lighten(black, 10), 0.9);

    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.6);
  }

  >.wrapper {
    @include outer-container(800px);

    display: flex;
    flex-flow: column;
    align-items: center;
    // justify-content: center;

    height: inherit;
    overflow: hidden;

    @include media($medium-screen-up) {
      flex-flow: row nowrap;
    }
  }

  >.wrapper >.text {
    flex: 1 0 auto;
    // display: inline-flex;

    padding: ($gutter * 0.2) ($gutter);

    font-size: em(14);
    font-weight: 600;
    text-align: center;

    @include ellipsis(100%);

    @include media($medium-screen-up) {
      font-size: em(18);
    }
  }

  >.wrapper >.actions  {
    flex: 1 0 auto;
    display: inline-flex;

    height: 32px;
    width: 100%;
    padding: 0 ($gutter * 0.6);

    border-color: $dark-green;

    @include media($medium-screen-up) {
      height: 40px;
      width: auto;
      padding: 0 ($gutter);
    }

    // input {
    //   height: 100%;
    //
    //   color: rgba(white, 0.75);
    //   background: lighten($green, 5);
    //
    //   margin: 0;
    //   padding: 0 ($gutter * 0.4);
    //
    //   border: 1px solid  lighten($green, 5);
    //   border-radius: 4px 0 0 4px;
    //
    //   box-shadow: none;
    //   outline: none;
    //
    //   @include ellipsis(100%);
    //   @include transition(border 600ms ease);
    //
    //   &:active,
    //   &:focus {
    //     color: rgba(white, 1.0);
    //     background: lighten($green, 0);
    //     border-color: white;
    //   }
    // }

    button {
      flex: 1 0 auto;
      height: 100%;

      font-size: em(13);

      color: white;
      background: lighten($green, 10);

      margin: 0;
      padding: 0 1em;

      border: none;
      // border-radius: 0 4px 4px 0;
      border-radius: 4px;

      @include ellipsis(100%);
    }

  }

}
