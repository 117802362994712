.main-nav {
  & {
    @include clearfix;
    position: relative;
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    /* height: em($column); */
    height: em(48 + 24);
    /* width: 90%; */
    width: 100%;

    background: $dark-green;
  }

  >.container {
    @include outer-container;
    @include clearfix;
    position: relative;
    height: inherit;
  }

  &.-abs {
    position: absolute;
    border-bottom: 1px solid tint(black, 90);
  }

  .logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    @include size(48px);

    overflow: hidden;

    @include media($medium-screen-up) {
      overflow: visible;
    };
    /* width: em(200px); */
    /* overflow: hidden; */
    svg {
      fill: white;
      height: 100%;
    }

    &.-tx {
      overflow: visible;
    }
  }

  .actions {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    @include size(auto 48px);

    color: white;

    display: none;

    @include media($medium-screen-up) {
      display: block;
    };
  }
  // something
  .actions > ul {
    @include clearfix;
    // position: relative;
    // width: 50%;
    // float: right;
    height: inherit;
  }

  .actions > ul li {
    height: inherit;
    // float: right;
    float: left;
    margin-left: em($gutter);
    padding-top: em(12);
  }

  .actions > ul li a {
    color: inherit;
    // height: inherit;
    // display: table-cell;
    // vertical-align: middle;
    display: block;
    @include size(100%);
  }
}


.mobile-nav {
  & {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    width: 100%;
    padding: em(12);
    color: white;
    background: none;

    @include media($medium-screen-up) {
      display: none;
    }
  }

  >.actions {
    position: relative;
    z-index: 0;
    border-radius: $base-border-radius;
    /* background: tint(black, 98); */
    background: rgba($dark-green, 0.92);
  }

  >.actions {
    ul {
      display: block;
      width: 100%;
      padding-top: em(48 + 12);
    }
    ul li {
      border-top: 1px solid shade($green, 70);
    }
    ul li a {
      display: block;
      padding: em($gutter * 0.6) em($gutter * 1);
    }
    .user {
      display: block;
      padding: em($gutter * 0.6) em($gutter * 1);
    }
  }

  >.trigger {
    @include size(48px);

    position: absolute;
    z-index: 2;

    top: 0;
    bottom: 0;
    right: 0;

    margin: em(12);
    border-radius: $base-border-radius;
    background: rgba($dark-green, 0.92);

    span {
      /* the span element is used to create the menu icon */
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      /* background: $color-3; */
      background: white;
      top: 50%;
      margin-top: -1px;
      left: 50%;
      margin-left: -10px;
      -webkit-transition: background 0.3s;
      -moz-transition: background 0.3s;
      transition: background 0.3s;

      &::before, &::after {
        content: '';
        position: absolute;
        left: 0;
        background: inherit;
        width: 100%;
        height: 100%;

        /* Force Hardware Acceleration in WebKit */
        @include transform(translateZ(0));
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        -webkit-transition: -webkit-transform 0.3s, background 0s;
        -moz-transition: -moz-transform 0.3s, background 0s;
        transition: transform 0.3s, background 0s;
      }

      &::before {
        top: -6px;
        @include transform(rotate(0));
      }

      &::after {
        bottom: -6px;
        @include transform(rotate(0));
      }
    }

  }

}

//

.layout {
  background: black;
  padding-top: em(48 + 24);
}

.navigation-block {
  & {
    display: block;
    position: fixed;
    top: em(0);
    left: 0;
    right: 0;
    z-index: (0 + 99);

    @include transition(top 300ms ease);
  }

  &.-nav-up {
    top: em(-(48 + 24));
  }
  &.-nav-down {
    top: em(0);
  }
}

.user-menu {
  $avatar-size: 28;

  & {
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    // right: 0;
    right: em($avatar-size * 1);

    margin: auto;
    height: 100%;

    // width: em(200);
  }

  .user {
    // @include row();
    display: block;
    position: relative;

    height: inherit;
    cursor: pointer;

    &::after {
      content:'';
      position: absolute;

      top: 0;
      bottom: 0;
      left: auto;
      right: -($avatar-size * 0.8px);

      margin: auto;

      @include size(24px);
      @include triangle(8px, white, down);
      
      opacity: 0.6;
    }
    // &::after {
    //   content:'';
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   right: 0;
    //   left: 0;
    //   // right: em(-$avatar-size * 0.6);
    //   margin: auto 0;
    //
    //   @include triangle(8px, white, down);
    //   opacity: 0.6;
    // }
  }

  >.user >.name {
    height: inherit;

    margin-left: em($avatar-size * 1.2);
    padding-top: 26px;

    font-size: em(14);

    color: white;
    white-space: nowrap;

    // display: none;
    width: 0;
    overflow: hidden;

    @include media($medium-screen-up) {
      // display: block;
      width: auto;
      margin-left: em($avatar-size * 2);
    };
  }
  >.user >.avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    // @include size($avatar-size);
    // @include size(24);
    height: 28px;
    width: 28px;


    background: gray center no-repeat;
    background-size: cover;

    border: 1px solid white;
    border-radius: 50%;
  }

  .menu {
    display: none;
    // display: block;
    position: absolute;
    top: 90%;
    right: 0;

    margin: 0 auto;

    font-size: em(14);

    color: tint(black, 10);
    background: white;

    // overflow: hidden;
    border-radius: $base-border-radius;

    &::after {
      content:'';
      position: absolute;
      top: -5px;
      right: 12px;
      margin: 0 auto;

      @include triangle(12px, white, up);
      // opacity: 0.6;
    }

    &.-is-visible {
      display: block;
    }

    //
    li {
      & {
        padding: em(8) em(16);
        white-space: nowrap;
      }
      &:not(:last-child) {
        border-bottom: 1px solid tint(black, 90);
      }
    }
    a {
      color: inherit;
      font-weight: normal;
    }
    //

  }
}

.site-menu {
  @extend .user-menu;

  & {
    left: em(80);
    right: auto;
  }

  @include clearfix;
  @include media($medium-screen-up) {
    left: em(200);
  }

  .menutrigger {
    position: relative;
    float: left;
    height: inherit;
    min-height: 100%;
    @include size(24px);

    cursor: pointer;
    // @include row(block);
    // @include clearfix;

    &::after {
      content:'';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      // right: em(-$avatar-size * 0.6);
      margin: auto 0;

      @include triangle(8px, white, down);
      opacity: 0.6;
    }
  }

  .menu {
    top: 80%;
    left: em(-12);
    right: auto;
  }
  .menu::after {
    margin: auto;
    left: em(10);
    right: auto;
  }

  .inlinemenu {

    & {
      display: none;

      float: left;
      @include clearfix;

      position: relative;
      height: inherit;

      padding: 0 em($gutter);
      padding-top: em(22);
    }

    @include media($medium-screen-up) {
      display: inherit;
    }

    >li {
      & {
        display: block;
        float: left;
        position: relative;

        color: white;
        font-weight: bold;
      }
      &:not(:last-of-type) {
        margin-right: em($gutter * 0.6);
      }

      >a {
        color: inherit;
      }
    }
  }
}
