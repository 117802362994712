table {
  @include font-feature-settings("kern", "liga", "tnum");
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid darken($base-border-color, 15%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}

//
%vhide,
%visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

$breakpoint-alpha: 480px; // adjust to your needs

.rwd-table {
  // margin: 1em 0;
  // min-width: 300px; // adjust to your needs

  tr {
    font-size: em(14);
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    @media (min-width: $breakpoint-alpha) {
      font-size: em(16);
      border: none;
    }
  }

  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
    // @extend %vhide;
  }

  td {
    display: block;

    // &:first-child {
    //   padding-top: .5em;
    // }
    // &:last-child {
    //   padding-bottom: .5em;
    // }

    &:before {
      content: attr(data-th) ": "; // who knew you could do this? The internet, that's who.
      font-weight: bold;

      // optional stuff to make it look nicer
      // width: 6.5em; // magic number :( adjust according to your own content
      display: block;
      // end options

      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  th, td {
    text-align: left;

    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      // padding: .25em .5em;
      //
      // &:first-child {
      //   padding-left: 0;
      // }
      //
      // &:last-child {
      //   padding-right: 0;
      // }
    }

  }

}
