.invest-block {
  & {
    display: block;
    position: relative;
    overflow: hidden;

    margin-top: em($gutter);
    margin-bottom: em($gutter);


    border-radius: 4px;

    color: white;
    background: tint(black, 10);
    border-color: tint(black, 20);

    z-index: 10;
  }

  @include media($medium-screen-up) {
    margin-top: 90px;
    transform: translateY( -100% );

    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
  }

  &.is_stuck {
    transform: none;
  }

  >.body {
    padding: ($gutter * 0.8) ($gutter * 1);
    font-size: em(12);
  }

  >.actions {
    padding: ($gutter * 0.8) ($gutter * 1);

    border: solid tint(black, 20);
    border-color: inherit;
    border-width: 1px 0 0 0 ;
  }

  >.metrics {
    background: black;
    // padding: ($gutter * 0.8) ($gutter * 1);
    border: solid tint(black, 20);
    border-color: inherit;
    border-width: 1px 0 0 0 ;

  }
  >.metrics >.row {
    @extend %flex-row;
  }

  >.waitlist {
    background: tint(black, 10);
    padding: ($gutter * 0.8) ($gutter * 1);

    border: solid tint(black, 20);
    border-color: inherit;
    border-width: 1px 0 0 0 ;
  }
  >.waitlist >.text {
    font-size: em(14);
    font-weight: 600;
    margin-bottom: 20px;

    // text-align: center;
  }
  >.waitlist >.invest-button {
    @extend %invest-button ;
  }

  // >.actions button {
  //   @extend %invest-button ;
  // }

  // >.actions label {
  //   display: block;
  //   font-size: em(12);
  //   margin: em(18) 0 0 em(4);
  //   abbr {display: inline-block;}
  // }

  >.actions >.invest-button {
    @extend %invest-button ;
  }

  .otheractions {
    background: black;
    padding: ($gutter * 0.8) ($gutter * 1);
    // padding: em(10) ($gutter * 1);

    border: solid tint(black, 20);
    border-color: inherit;
    border-width: 1px 0 0 0 ;

    text-align: center;
  }

  .otheractions >button {
    display: block;
    // background: lighten($green, 10);
    color: white;
    background: none;
    border: 1px solid white;

    width: 100%;

    margin: (10px) auto;
    padding: 0;

    font-size: (12px);
    line-height: (40px);
    // font-weight: normal;

    text-align: center;
    font-weight: 600;
    border-radius: $base-border-radius;

    &:hover {
      color: black;
      background: white;
      // background: lighten($green, 10);
    }

    @include transition(
      background 400ms ease
    );
  }

}


.email-input {
  display: flex;
  flex-flow: row nowrap;

  overflow: hidden;
  // margin-top: ($gutter * 1);

  // padding: ($gutter * 0.8) ($gutter * 1);
  font-size: 16px;

  >input {
    flex: 1 1 auto;

    // height: (40px);
    margin: 0;
    // padding: 0 1em;
    // border: none;
    // border-radius: $base-border-radius;
    // background: tint(black, 20);

    // @include transition(
    //   background 200ms ease
    // );

    @include appearance(none);
    background-color: $base-background-color;
    border: $base-border;
    border-width: 1px;
    border-radius: $base-border-radius;
    box-shadow: none;
    box-sizing: border-box;
    font-family: $base-font-family;
    font-size: $base-font-size;
    height: 40px;
    padding: 6px 12px;
    transition: border-color;

    &:hover {
      border-color: darken($base-border-color, 10%);
    }

    border-color: $action-color;
    color: tint(black, 20);
    background: tint(black, 100);
    // outline: none;
    box-shadow: none;

    button {
      width: (40px);
    }
  }
  >button {
    flex: 0 0 auto;

    padding: 0;

    overflow: hidden;
    border: none;

    background: none center right no-repeat;
    background-size: 30px;
    background-image: url('/images/svg/arrow-circle-on-right.svg');

    @include hide-text;

    @include transition(
      width 200ms ease
    );

    width: (40px);
    outline: none;
    box-shadow: none;
  }
}


%flex-row {
  & {
    display: flex;
    flex-flow: row nowrap;
  }

  >.cell {
    flex: 1 0 50%;
    padding: ($gutter * 0.8) ($gutter * 1);

    &:not(:last-of-type) {
      border-right: 1px solid tint(black, 20);
    }
  }

  >.cell .label {
    font-size: em(12);
    color: tint(black, 90);
  }
  >.cell .value {
    font-size: em(24);
    font-weight: 500;
  }
}
