.project-card {
  & {
    /* @extend %card-style; */
    @include row();

    position: relative;
    z-index: 10;

    @include media($large-screen-up) {
      margin: em($gutter * 2) auto;
    };
  }

  >.figure {
    position: relative;
    z-index: 1;

    height: auto;

    @include media($large-screen-up) {
      @include span-columns(7 of 10);
    };
  }

  >.body {
    position: relative;
    z-index: 0;

    @include media($large-screen-up) {
      @include span-columns(3 of 10);
    };
  }

  >.body > .data {
    display: none;
    font-size: em(14);
    padding: em($gutter);
  }

  >.body > .actions {
    padding: em($gutter * 0.6);
  }

}

.projectimage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  margin: auto;

  background: #DDD center no-repeat;
  background-size: cover;

  @include size(100%);

  &:after {
    content: '';
    display: block;
    position: absolute;
    @include size(100%);

    /* background-image:
      linear-gradient(transparent 50%, black 150%),
      linear-gradient(-90deg, transparent 20%, black 200%)
    ; */

    @include background(
      linear-gradient(transparent 50%, black 150%),
      linear-gradient(-90deg, transparent 20%, black 200%)
    );
  }
}

.projectvideo {
  position: relative;
  overflow: hidden;
  z-index: 10;
  background: black;

  height: 0;
  width: 100%;
  padding-top: (100/16*9) * 1%;

  /* margin: em($gutter * 2) auto; */
  margin: auto;


  .player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  // FIXME:
  // this and the .player class can be the same thing, kinda
  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;

    background: black center no-repeat;
    background-size: cover;
  }
}

.projectavatar {
  position: absolute;
  /* left: 0; */
  right: 0;
  z-index: 5;

  overflow: hidden;

  top: auto;
  bottom: 0;
  margin-bottom: em(-28);

  @include size(60px);

  margin-top: em($gutter * 1.4);
  margin-right: em($gutter * 1);

  border-radius: $base-border-radius;
  /* @include margin($gutter*1.6 null $gutter*1); */

  /* @include media($medium-screen-up) {
    @include pad($gutter*1.6 null $gutter*1.4);
  }; */

  background: gray 100% center no-repeat;
  background-size: cover;

  border: 1px solid rgba(white, 0.9);

  @include media($medium-screen-up) {
    top: 0;
    bottom: auto;
    /* margin-top: em($gutter * 1.4); */
    margin-right: em($gutter * 1.4);
    margin-bottom: 0;

    @include size(80px);
  };
}

.projecttitle-section {
  & {
    display: block;
    overflow: visible;
    position: relative;
    z-index: 0;

    background: black center top no-repeat;
    background-size: cover;
    background-attachment: scroll;

    padding-top: em(90);
  }

  @include media($medium-screen-up) {
    overflow: hidden;
    // z-index: 0;
    background-attachment: fixed;
    padding-top: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include linear-gradient(
      180deg,
      rgba(tint(black, 50), 0.1) 0%,
      rgba(tint(black, 5), 0.8) 100%
    );
  }

}

.projecttitle {
  @extend %type-system;

  position: relative;
  z-index: 1;
  width: 100%;
  // height: 100%;
  /* color: tint(black, 10); */
  color: white;
  // background: rgba(black, 0.9);

  font-size: em(14);
  text-align: center;


  @include pad($gutter*1.6 null $gutter*1);

  /* padding-bottom: em($gutter * 3); */

  @include media($medium-screen-up) {

    @include pad($gutter*1.6 null $gutter*1.4);
    // FIXME:
    padding-right: em(($gutter * 1.4 * 2) + 80);
    font-size: em(16);
    text-align: left;

    background: none;
  };

  >.headline {
    position: relative;
    z-index: 5;
    font-size: em(32);
    margin-bottom: em(4);
  }
  >.subhead {
    position: relative;
    z-index: 5;
    font-size: em(16);
    margin: 0;
  }
  >.avatar {
    position: absolute;
    overflow: hidden;

    top: 0;
    /* bottom: auto; */
    right: 0;
    left: 0;
    z-index: 5;

    @include size(48px);

    margin: auto;
    // margin-top: em(-20);
    /* margin-top: em($gutter * 1.4); */
    /* margin-right: em($gutter * 1); */

    border: 1px solid rgba(white, 0.9);
    border-radius: $base-border-radius;

    background: gray 100% center no-repeat;
    background-size: cover;

    @include media($medium-screen-up) {
      top: 0;
      left: auto;

      margin-top: em($gutter * 1.4);
      margin-right: em($gutter * 1.4);
      margin-bottom: 0;

      @include size(80px);
    };
  }

}

.projectstatus {
  display: block;
  /* position: absolute;
  position: relative;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  /* height: 100%; */
  font-size: em(13);
  color: white;

  padding: em($gutter*0.8) em($gutter*1);
  /* padding-bottom: em(48 * 1.2); */

  margin: em($gutter * 2) auto;

  @include media($medium-screen-up) {
    font-size: em(14);
    /* padding-bottom: auto; */
    padding: em($gutter*0.8) em($gutter*1.4);
  };

  &.-raising {
    color: tint(black, 75);
    background: rgba( tint(black, 10), 0.9 );
  }
  &.-completed {
    background: rgba( tint($green, 10), 0.9 );
  }
  &.-failed {
    background: rgba( tint(black, 25), 0.8 );
  }
  &.-planning,
  &.-faq {
    background: rgba( tint( #F9AF48, 05), 0.9 );
  }

  >.actions {
    @extend .button;
    background: white;
    color: black;
    float: right;
  }
  //
}

.projectkeymetrics {
  & {
    @include row(table);

    margin: em($gutter * 1) auto;

    /* @include pad($gutter*0 null $gutter*1 ); */

    font-size: em(13);
    padding: 0 em($gutter * 0.5);

  }

  @include media($medium-screen-up) {
    font-size: em(16);
    padding: 0;
  }

  >.cell {
    @include span-columns(1 of 3);

    padding: em($gutter * 0.5) em($gutter * 0.6);

    /* background: rgba(white, 0.1); */
    color: tint(black, 90);

    border: 1px solid;
    border-color: rgba( tint(black, 75), 0.5 );

    vertical-align: bottom;

    cursor: help;

    @include transition(background 400ms $ease-in-out-expo);

    &:hover {
      background: rgba($light-green, 0.9);
      color: $dark-green;
    }
  }

  > .cell + .cell {
    border-left: none;
  }

  .title {
    font-weight: normal;
    font-size: em(16 * 0.8);
    margin-bottom: em($gutter * 0.3);
  }
  .value {
    font-weight: bold;
    font-size: em(16 * 1.0);
    /* font-size: em(16 * 1.6); */
    margin-left: em(16 * -0.1);

    // FIXME:
    /* cursor: help; */
  }

}

.projectinfo {
  /* position: absolute; */
  width: 100%;
  // height: 100%;
  z-index: 1;
  /* bottom: 0; */

  @extend %type-system;
  /* @include pad($gutter*0 null $gutter*1.4 ); */

  // background: rgba(skyblue, 0.5);
  color: white;
  // font-size: em(12);

  dl {
    @include margin( $gutter auto $gutter);
    @include pad($gutter*0 null $gutter*1 );

    @include media($medium-screen-up) {
      @include pad($gutter*0 null $gutter*1.4 );
    };
  }

  dl dt {
    font-weight: normal;
    font-size: em(16 * 0.8);
  }
  dl dd {
    font-weight: normal;
    font-size: em(16 * 1.6);
    margin-left: em(16 * -0.1);

    // FIXME:
    cursor: help;
  }
}

.progressbar {
  overflow: hidden;
  width: 100%;
  height: em(7);
  border-radius: 2px;
  padding: 0;
  background: rgba(tint(black, 90), 0.5);

  margin-bottom: em($gutter*0.5);

  >.progressfill {
    background: tint(black, 100);
    background: $green;
    height: 100%;
    width: 0%;
  }
}

.project-metrics {
  & {
    background: white;
    overflow: hidden;
  }
  >.section {
    overflow: hidden;
    /* min-height: em($column*1); */

    @include pad(0 null $column/4);

    border-bottom: 1px solid tint(black, 90);
    margin: 0;

    &:last-child {border: none;}


    dl {
      font-size: em(14);
      @include margin( $gutter*0.8 auto $gutter);
    }

    dl dt {
      font-weight: normal;
      font-size: em(12);
    }
    dl dd {
      font-weight: bold;
      font-size: em(16 * 2);
      margin-left: em(16 * -0.1);

      .label {
        font-size: 12px;
      }
    }
    dl dd span {
      font-size: em(16 * 0.76);
      color: tint(black, 50);
    }
  }

  .valuelist {
    font-size: em(14);
    @include margin( $gutter auto $gutter);
    @include row(table);

    .label {
      font-weight: normal;
      font-size: em(12);
    }
    .value {
      font-weight: bold;
      font-size: em(16 * 1.5);
      /* font-size: 160%; */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.-gray {
       color: tint(black, 50%);
      }
      &.-small {
        /* height: em(16 *  1.5); */
        /* line-height: em(16 * 1.5); */
        /* font-size: 120%; */
        font-size: em(18);
      }

      /* margin-left: em(16 * -0.1); */
    }

    >.cell {
      @include span-columns(1 of 2);
    }
  }

  >.section.actions {
    /* background: tint(black, 10); */
    /* background: $dark-green; */
    /* position: relative; */
    padding: em($gutter * 0.6);
    /* min-height: none; */


    &.-table {
      display: table;
      width: 100%;

      >.cell {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
      }
    }

     a {
      /* @extend .button; */
      display: block;
      /* position: absolute; */
      /* top: 0; */
      /* bottom: 0; */
      /* left: 0; */
      /* right: 0; */
      /* margin: auto; */
      width: 100%;
      /* height: em(46); */
      /* max-width: 80%; */
      text-align: center;
      padding: 0.75em 1em;

     }
  }

  // >.section.location {
  //   padding: em(8);
  //   border: none;
  //
  //   @include media($medium-screen-up) {
  //     padding: 0;
  //
  //     // FIXME:
  //     border-bottom: 1px solid tint(black, 90);
  //   };
  //
  //   >.map {
  //     overflow: hidden;
  //     background: #E9E5DC 100% auto center top no-repeat;
  //     background-size: cover;
  //
  //     height: 0;
  //     width: 100%;
  //     padding-bottom: (100/5*3 * 1%);
  //   }
  //   >.address {
  //     font-size: em(12);
  //     padding: em($column*0.2) em($column/4) ;
  //     background: tint(black, 98);
  //     background: whitesmoke;
  //   }
  // }
}


// FIXME:
.star-button {
  & {
    position: relative;
    font-size: em(14);
    display: block;
    width: 100%;

    text-align: center;
    background: none;
    color: tint(black, 100);
    /* border: 2px solid tint(black, 50); */

    @include transition(color 500ms ease);
  }

  &:focus,
  &:hover {
    background: none;
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: tint($green, 10);
    border-color: tint($green, 20);
    @include transition(color 100ms ease);
  }

  &.-is-true {
    color: $green;
    border-color: tint($green, 0);
  }


  >.svg-star {
    display: inline-block;
    overflow: hidden;
    @include size(18px);

    margin-right: em(8);
    margin-left: em(-10);
    margin-bottom: em(-4);

    fill: currentColor;

    svg {@include size(100%);}
  }
}

.remindme-block {
  & {
    padding: em(16);
  }

  .infoicon {
    @include size(14px);
    // display: inline-block;
    border-radius: 50%;

    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: em(20);
    margin: auto;

    cursor: help;

    background: url("/images/svg/i-icon.svg") center 1px no-repeat;
    background-color: rgba(white, 0.75);
    background-size: 90%;
  }
}

.share-btns-block {
  margin-top: em($gutter);
  text-align: center;

  > div {
    margin: 0 auto 10px;
  }

  .email-btn {
    background-color: #21ac59;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    height: 35px;
    width: 75px;

    img {
      height: 20px;
      display: inline-block;
      padding-right: 5px;
      padding-top: 5px;
    }
    
    span {
      color: #fff;
    }
  }
}
