.project-udpate {
  & {
    @extend %card-style;

    @include row(table);

    /* @include margin($column/3 null auto); */
    margin: em($gutter * 2) auto;
  }

  >.type {
    @extend %type-system;
    /* @include span-columns(2 of 12); */
    @include pad();

    text-align: center;
    /* vertical-align: middle; */
    border-right: 1px solid tint(black, 80);
    display: none;

    @include media($medium-screen-up) {
      @include span-columns(2 of 12);
      /* display: block; */
    };
  }
  >.body {
    @extend %type-system;
    padding: em($gutter*1) em($gutter*1);

    font-size: em(13);

    @include media($medium-screen-up) {
      @include span-columns(7 of 12);
      padding: em($gutter*1) em($gutter*1.6);
      font-size: em(16);
    };

  }
  >.actions {
    padding: em($gutter*1) em($gutter*1);
    border-top: 1px solid tint(black, 90);
    background: tint(black, 98);

    @include media($medium-screen-up) {
      @include span-columns(3 of 12);
      border-top: none;
      background: none;
    };

  }

  // FIXME: separate styles
  .author {
    @include row(table);
    // margin-left: em(-40);
    margin-bottom: em($gutter);

    >.avatar {
      display: table-cell;
      vertical-align: middle;
      // @include span-columns(1);
      /* @include size(40); */

      height: em(32);
      width: em(32);

      border-radius: 50%;

      background: none 100% center no-repeat;
      background-size: cover;
      background-image: url("/images/svg/missing-avatar-02.svg");
    }
    >.name {
      display: table-cell;
      vertical-align: middle;

      width: auto;
      padding-left: em($gutter * 0.5);
    }
  }

}
