
.grid-container {
  @include outer-container;

  // FIXME:
  @include row(block);

  // &.-col-two {
  //   >* {
  //     @include span-columns(1 );
  //   }
  // }
}


// FIXME:
.row {
  & {
    @include row;
    /* font-size: em(14); */
  }
  &.-col-two {
    >*{

      @include media($medium-screen-up) {
        @include span-columns(6 of 12);
      }
    }
  }
}


// .hit-diagram,
.diagram-block {
  & {
    /* font-size: 70%; */
    font-size: em(13);

    @include media($medium-screen-up) {
      /* font-size: 100%; */
      font-size: em(16);
    }
  }

  .dblock {
    @extend %type-system;
    /* @include margin(em($gutter*4) null em($gutter*4)); */
    color: white;
    padding: em(12);
    margin: em($gutter) auto ;

  }
  .dblock > .head {
    text-align: center;
  }
  .dblock > .body {
    text-align: center;

    /* @include size( 60% auto); */
    @include margin($gutter auto $gutter);
    @include size( 90% auto);
    max-width: em($gutter*20);

    p {
      /* font-size: em(18); */
      /* font-size: em(14); */
      line-height: 1.8em;
    }
  }
  .dblock > .figure {
    @include size(80px);
    @include margin(null auto em($gutter*2));
    /* background: rgba(#45D886, 1.0); */

    color: rgba(#45D886, 1.0);
    fill: rgba(#45D886, 1.0);
  }

  .svgline {
    fill: white;
    width: 60%;
    margin: 0 auto;
    display: none;

    @include media($medium-screen-up) {
      display: block;
    }

  }

}
