.project-faq-page {

  .faq-wrapper {
    @include outer-container();
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .textblock {
    padding: 2rem;
    background-color: #fff;
    color: #666666;
  }

  h1 {
    font-size: 24px;
  }

  h1, a.footer-link {
    text-align: center;
    display: block;
  }

  a.footer-link {
    margin: 1rem auto 3rem;
  }

  a {
    color: inherit;
    font-weight: bold;
  }

}
