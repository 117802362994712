
.project-qa {
  & {
    @extend %card-style;
    /* @include row(table); */
    position: relative;
    z-index: 10;

    /* @include margin($column/3 null auto); */
    margin: em($gutter * 2) auto;
    /* overflow: visible; */

  }

  >.head {
    padding: em($gutter * 1.0) em($gutter * 1.6);
    background: #F9AF48;
    color: white;

    /* @include span-columns(3 of 12); */
  }

  /* >.body {
    @include span-columns(9 of 12);
  } */

  >.body >.qa {
    overflow-y: scroll;
    max-height: 400px;

    font-size: em(12);
    @include media($medium-screen-up) {
      font-size: em(16);
    }
  }

  >.body .question {
    padding: em($gutter * 1.0);
    /* border-left: 8px solid tint(#F9AF48, 50); */
    border-left: 8px solid tint(gray, 50);
    /* border-top: 1px solid tint(gray, 80%); */
    border-bottom: 1px solid tint(gray, 80%);
    background: tint(gray, 96%);

    b {
      padding-right: 10px;
    }
  }
  >.body .answer {
    @extend %type-system;
    padding: em($gutter * 1.2) em($gutter * 1.6);
    border-left: 8px solid tint(gray, 80);
    border-bottom: 1px solid tint(gray, 80%);
    background: tint(gray, 90%);
    /* border-bottom: 1px solid tint(gray, 80%); */

    display: block;

    @include media($medium-screen-up) {
      padding: em($gutter * 1.2) em($gutter * 3.2);
    }

    &.-is-hidden {
      display: none;
    }
  }

  .question >.author {
    /* float: none; */

    @include media($medium-screen-up) {
      /* float: left; */
      display: inline-block;
    }
  }

  .answer >.author {
    @include row(table);
    margin-left: em(-56);
    margin-bottom: em($gutter);

    >.avatar {
      @include span-columns(1);
      /* @include size(40); */

      height: em(40);
      width: em(40);

      border-radius: 50%;
      background: none 100% center no-repeat;
      background-size: cover;
      background-image: url("/images/svg/missing-avatar-02.svg");
    }
    >.name {
      /* @include span-columns(3); */
      display: table-cell;
      vertical-align: middle;
      width: auto;

      padding-left: em($gutter * 0.5);
      /* @include span-columns(); */
    }
  }

  .input {
    @include pad();

    border-top: 1px solid #F9AF48;
    background: tint(#F9AF48, 90);
  }

}


.project-qa-02 {
  & {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    height: 100%;
    width: 100%;

    overflow: hidden;

    pointer-events: none;

    @include media($medium-screen-up) {
      max-height: em(480);
      max-width: em(320);

      left: auto;
      right: em(100);
    };
  }

  >.actions {
    display: block;
  }

  &.-is-visible {
    >.view {
      @include transform(translateY(0));
    }
    .input {
      // display: block;
      @include transform(translateY(0));
    }
    >.actions {
      display: none;
    }
  }

  >.view {
    @include transform(translateY(100%));
    @include transition(transform 300ms ease);

    pointer-events: auto;

    position: relative;
    // top: auto;
    // bottom: 0;
    // right: 0;
    // left: 0;

    z-index: 1;

    width: 100%;
    height: 100%;
    //
    // max-height: em(480);
    // max-width: em(320);

    color: tint(black, 20);
    background: tint(black, 98);

    border: 1px solid tint(#FBB03B, 20);
    border-width: 1px 1px 0;
    border-radius: $base-border-radius $base-border-radius 0 0;

    // padding-bottom: em(80);

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      height: em(80);
      width: 100%;
      @include linear-gradient(to bottom, rgba(white, 0.0), rgba(white, 0.9));
    }
  }

  >.view >.head {
    // height: 10vh;
    color: white;
    background: #FBB03B;

    text-align: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    height: em(60);

    .exit {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;

      margin: auto;
      // background: rgba(white, 0.5);
      background: url("/images/svg/x-icon-02.svg") center no-repeat;
      background-size: 40%;

      @include size(40px);
    }

    .title {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      height: em(24);
      width: 90%;

      text-align: center;
      font-weight: bold;
    }

  }
  >.view >.body {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    max-height: 100%;
    width: 100%;

    padding-top: em(60);
    padding-bottom: em(80);
  }

  .subtitle {
    font-size: em(14);
    font-weight: bold;

    list-height: 1.25em;

    padding: em($gutter * 0.4) em($gutter * 1);

    color: tint(black, 100);
    background: tint(#FBB03B, 25);
  }

  >.actions {
    // background: orange;
    pointer-events: auto;

    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;

    min-height: 48px;
    width: 100%;

    z-index: 10;

    padding: em($gutter * 0.4) em($gutter * 0.5);

  }

  >.input {
    pointer-events: auto;

    position: absolute;
    top: auto;
    bottom: 0;
    z-index: 10;

    // max-width: em(300);

    // background: white;
    // padding: em($gutter);
    // border: 1px solid tint(black, 90);
    // border-radius: $base-border-radius;
    border: 1px solid tint(#FBB03B, 20);
    border-top: 1px solid tint(black, 80);
    border-bottom: none;

    background: tint(black, 90);

    width: 100%;
    min-height: 60px;
    max-height: 160px;
    // padding: em(10);

    @include transform(translateY(100%));
    @include transition(transform 500ms $ease-in-out-back);

    >a {
      width: calc(100% - 20px);
      margin: 10px auto;
    }

    pre {
      position: relative;
      display: block;
      margin: 0;
      width: 100%;
      // min-height: 100%;

      opacity: 0;
      visibility: hidden;

      // background: salmon;
    }

    form {
      overflow: hidden;
      position: absolute;
      // display: block;
      display: flex;
      flex-flow: row;
      // position: relative;

      top: 0;
      z-index: 0;

      height: 100%;
      width: 100%;
    }

    form textarea {
      flex: 0 1 auto;

      padding: (8px);
      margin: (10px);

      border: none;
      box-shadow: none;
    }

    form button {
      flex: 1 0 auto;
      align-self: flex-end;

      text-align: center;
      // font-size: 12px;
      // padding: 0 12px;
      // height: 100%;
      max-height: 60px;
      // line-height: 60px;
      margin: (10px) (10px) (10px) 0 ;
    }
  }

  .qa-list,
  %question-list {
    & {
      position: relative;
    }

    >.item {
      background: white;

      &:not(:last-of-type) {
        border-bottom: 1px solid tint(black, 90);
      }
      &.-is-expanded {
        .answer { display: block; }
      }
    }

    >.item >.question {
      // @include clearfix();
      position: relative;
      display: block;
      overflow: hidden;

      font-size: em(14);
      font-weight: bold;
      // color: tint(black, 25);
      color: tint(#0085FF, 10);
      padding: em($gutter * 0.6) em($gutter * 0.8);

      p {
        word-break: break-all
      }

      .author {
        display: inline-block;
        font-size: em(13);
        font-weight: normal;
        color: tint(black, 50);
        margin-top: em($gutter * 0.5);

        float: left;
      }

      .status {
        display: inline-block;
        float: right;

        overflow: hidden;

        font-size: em(12);
        margin-top: em($gutter * 0.5);
        padding: em(4) em(12);

        // height: 30px;
        // min-width: 30px;

        color: tint(#447BCD, 0);
        // border: 1px solid tint(#447BCD, 0);
        background: tint(#447BCD, 80);

        border-radius: $base-border-radius;
      }
    }
    > .item >.answer {
      font-size: em(13);
      color: tint(black, 25);
      padding: em($gutter * 1) em($gutter * 1);
      // background: tint(black, 96);

      display: none;

      .author {
        font-weight: bold;
        font-size: em(13);
      }
      .timestamp {
        font-size: em(13);
      }

    }
  }
}


// FIXME: move somewhere else
body.-noscroll {
  overflow: hidden;

  @include media($medium-screen-up) {
    overflow: scroll;
  };
}
