.legal-footer {
  @include outer-container();

  color: white;
  font-size: rem(12);
  padding: 2rem 2rem 120px ;

}


.site-footer {
  @extend .grid-container;
  @extend .subsection;
  /* @extend .-pad-y; */

  font-size: rem(14);
  text-align: center;
  color: white;

  @include media($medium-screen-up) {
    text-align: left;
    font-size: rem(16);

    >.table-row {
      @include row(table);
    }

    .col {
      @include span-columns(1 of 4);
      /* @include pad(); */
    }
  };

  /* >.table-row {
    @include row(table);
  } */

  .col {
    /* @include span-columns(1 of 1); */
    @include pad();
  }

  .logo {
    margin-top: em($gutter);
    text-align: center;
  }

  b {
    font-size: em(13);
    color: tint($green, 50);
  }

  ul {
    margin-top: em(16);
  }

  a {
    color: white;
    line-height: em(24);
  }

}
