
/* @import "../jquery-ui.css"; */
@import "lib/lib";

// .error {
//   color: red !important;
//   font-weight: bold !important;
// }

.loading-gif {
  width: 80px;
  height: 80px;
  margin: 0 auto;

  > img {
    height: 100%;
    width: 100%;
    /* background-size: 100px 100px; */
  }
}

.error {
  color: red;
}
