.fbmessenger{
  position:fixed;
  bottom:100px;
  right:15px;
  z-index:10000;

  @media screen and (max-width: 639px) {
    bottom: 60px;
    right: auto;
    left: 15px;
  }
}

.fbmessenger img{
  width:50px;
  filter: drop-shadow(2px 6px 4px rgba(0,0,0,.3));
  -webkit-filter: drop-shadow(2px 6px 4px rgba(0,0,0,.3));
}
 
.tooltiptext {
  width: 120px;
  background-color: white;
  color:#2c2c2c; ;
  text-align: center;
  padding: 5px 0;
  border:1px solid #eeeeee;
  border-radius: 6px;
  position: fixed;
  bottom:110px;
  right:75px;
  // text-transform:uppercase;
  filter: drop-shadow(2px 6px 4px rgba(0,0,0,.3));
  -webkit-filter: drop-shadow(2px 6px 4px rgba(0,0,0,.3));

  @media screen and (max-width: 639px) {
    display: none;
  }
}
  
.tooltiptext:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #eeeeee;
}
