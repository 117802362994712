
.grid-container {
  @include outer-container;

  // FIXME:
  @include row(block);
}

.testimonial-card {
  & {
    @extend %type-system;

    position: relative;
    overflow-y: visible;
    overflow-x: visible;

    // background: palette(green, dark);
    background: #021512;
    color: white;

    font-size: em(12);

    /* @include margin($gutter null $gutter); */
    margin: em($gutter * 0.6) auto;

    @include span-columns(11 of 12);
    @include shift(0.5);

    @include media($medium-screen-up) {
      // FIXME:
      @include span-columns(5 of 12);
      @include shift(0.75);
      @include omega(2n);

      font-size: em(16);
      margin-top: em($gutter * 1);
      margin-bottom: em($gutter * 1);
    };
  }

  >.head {
    padding: em($gutter);
    margin-top: em($gutter*1);
    margin-left: em($gutter*1);

    h1 {
      font-size: em(32);
      font-weight: 300;
      font-style: italic;
    }
  }
  >.body {
    padding: 0 em($gutter*2) em($gutter) em($gutter*2);

    p {
      font-size: em(14);
      font-weight: 400;
      line-height: 1.8;
    }
  }
  >.avatar {
    position: absolute;
    border-radius: 50%;
    background: white 100% center no-repeat;
    background-size: cover;

    @include size(60px);
    @include margin($gutter null null -30px);

    @include media($medium-screen-up) {
      @include size(80px);
      @include margin($gutter null null -40px);
    };
  }

}
