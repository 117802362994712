%-noselect {
  user-select: none;
  // -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.tier-list {
  & {
    display: block;
    position: relative;
    z-index: 0;
  }
}

.tier-item {
  & {
    display: block;
    position: relative;
    overflow: hidden;

    margin: em(16) auto;
    // border-radius: $base-border-radius;
    border-radius: 4px;

    color: white;
    background: tint(black, 10);
    border-color: tint(black, 20);
  }

  // &.-is-featured {}

  &.-is-soldout {
    background: tint(black, 60);
    border-color: tint(black, 50);

    opacity: 0.8;

    >.head-row,
    >.ti-row {
      background-color: inherit;
      border-color: inherit;
    }
    // >.actions >button {
    //   cursor: not-allowed;
    // }
    >.info {
      display: none;
    }
    // >div {
    //   opacity: 0.75;
    // }
  }

  &.-is-early-option {
    &::before {
      @include ellipsis();
      content: attr(data-label) "";

      color: white;
      background: lighten($green, 10);

      display: block;
      text-align: center;

      line-height: (40px);
      font-size: em(12px);
      font-weight: bold;
    }
  }

  >.description {
    font-size: em(12);
    // font-weight: bold;
    padding: ($gutter * 0.8) ($gutter * 1);
  }

  >.actions {
    padding: ($gutter * 0.8) ($gutter * 1);

    border: solid tint(black, 20);
    border-color: inherit;
    border-width: 1px 0 0 0 ;
  }

  >.actions button {
    @extend %invest-button;
  }

  // // FIXME:
  // >.actions label {
  //   display: block;
  //   // line-height: em(40);
  //   font-size: em(13);
  //   // text-align: center;
  //   margin: em(18) 0 0 em(4);
  //   // height: em(40);
  //   abbr {display: inline-block;}
  // }

  >.info {
    font-size: em(11);
    font-weight: bold;
    padding: ($gutter * 0.8) ($gutter * 1);

    border: solid tint(black, 20);
    border-color: inherit;
    border-width: 1px 0 0 0;

    color: tint(black, 80);

    a {
      // color: $light-green;
      color: #4F88FF;
      text-decoration: underline;
    }
  }

}

.head-row {
  display: flex;
  align-items: center;

  overflow: hidden;

  background: black;
  color: white;

  height: (120px);
  padding: (0) ($gutter * 1);

  border: solid tint(black, 10);
  border-width: 0 0 1px 0;


  >.amount {
    @include ellipsis();

    flex: 1 auto;
    font-size: (42px);
  }
  >.quantity {
    flex: 0 auto;

    font-size: (12px);
    // padding: (0) ($gutter * 1);

    >.item {
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;

      @include ellipsis();
      display: block;

      text-align: center;
      font-size: (10px);
      font-weight: bold;

      // height: (20px);
      line-height: (24px);

      padding: 0 (12px);
      // border-radius: (12px);
      border-radius: $base-border-radius;
      background: tint(black, 50);

      &:not(:last-of-type) {
        margin-bottom: em(12px);
      }
      &.-remaining{
        color: tint(black, 20);
        background: darken(#F6D156, 0);
      }
    }

  }
}

.ti-row {
  display: flex;

  flex-flow: row;
  align-items: center;

  font-weight: bold;
  height: (60px);
  // line-height: em(60);
  background: black;

  padding: 0 ($gutter * 0.8);

  border: solid tint(black, 10);
  border-width: 0 0 1px 0;

  >div {
    // padding: 16px;
    // width: 100%;
    margin: 0 6px;
    font-size: em(14px)
  }

  >.name {
    flex: 1;

    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    display: block;
    @include ellipsis();

  }
  >.ror,
  >.fbc {
    font-size: (12px);
    font-weight: bold;
    list-height: (20px);
    border-radius: (20px);
    // color: black;
    padding: 4px 12px;
    background: tint(black, 10);
  }

  >.ror {
  // span {
    // border-radius: (20px);
    // list-height: (20px);
    // font-size: (12px);
    // color: white;
    background: lighten($green, 10);
    // padding: 4px 12px;
    // font-weight: bold;
  // }
  }
}



.projections {
  & {
    border: solid tint(black, 20);
    border-color: inherit;
    border-width: 1px 0 0 0;
  }

  >.label {
    @extend %-noselect;

    position: relative;
    display: flex;

    flex-flow: row;
    align-items: center;

    font-size: em(12px);
    font-weight: bold;
    height: (60px);
    // line-height: em(60);
    // background: black;

    padding: 0 ($gutter * 1.0);

    // border: solid tint(black, 20);
    // border-width: 1px 0 0 0;

    cursor: pointer;

    &:hover {
      text-decoration: underline;

      &:after {border-top-color: white;}
    }

    &:after {
      content: '';
      display: block;
      position: relative;

      margin-right: em(16px);
      margin-left: auto;

      @include triangle(12px, gray, down);

      @include transform(rotate(-90deg));

      @include transition(
        border 600ms ease,
        transform 500ms $ease-in-out-expo
      );
    }

    &.-is-expanded {
      &:after {
        @include transform(rotate(0));
        border-top-color: white;
      }
    }
  }

  >.content {
    padding: ($gutter * 1.0) ($gutter * 1.0);
  }

  abbr {
    @extend %-noselect;
    cursor: help;
    line-height: 150%;
    display: inline-block;
  }

  table {
    position: relative;
    margin: 0;
    font-size: em(12);
  }
  thead {
    color: tint(black, 10);
    background: tint(black, 100);
  }
  tbody {
    color: tint(black, 100);
    background: tint(black, 15);
  }
  // tr {}
  th {
    padding: (8px) (16px);
    border-color: tint(black, 100);
  }
  td {
    padding: (12px) (16px);
    border-color: tint(black, 50);

    font-weight: bold;
  }
}
