fieldset {
  background-color: lighten($base-border-color, 10%);
  border: $base-border;
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  // prevent ios zooming
  // font-size: 16px;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 3;
  transition: border-color;
  width: 100%;

  &:hover {
    border-color: darken($base-border-color, 10%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

input[type="file"] {
  padding-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}


// FIXME:
.form-block {
  & {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding-bottom: em(10);

    @include clearfix;
  }

  &.-card {
    @extend %card-style;

    color: tint(black, 20);
    font-size: em(16);
    max-width: em($column * 5);
  }
  &.-card.-wide {
    max-width: em($column * 8);
  }
  &.-card.-full {
    width: 100%;
    max-width: none;
    fieldset {max-width: none;}
  }
  &.-pad {
    padding: em($gutter);
  }


  >.info-block {
    @extend .type-system-sans;
    padding: em($gutter * 2) em($gutter * 1.6);
    background: tint(black, 98);
    border-bottom: 1px solid tint(black, 92);
    font-size: em(14);
    /* text-align: center; */
  }

  .m-signature-pad {
    width: 100%;
    max-width: 100%;
    margin: em(24) auto;
  }

  form {
    width: 100%;
    padding: em($gutter) 0;
  }

  fieldset {
    background:none;
    border: none;
    margin: 0 auto;
    max-width: 800px;
    // padding: 0 em($gutter * 2);
  }

  legend {
    display: block;
    width: 100%;
    @include appearance(none);

    border-bottom: 1px solid tint(black, 90);
    margin-bottom: em($gutter * 0.6);
    padding-bottom: em($gutter * 0.2);
  }

  .-fieldset > div,
  fieldset > div,
  form > div {
    /* form element wrapper */
    position: relative;
    margin: em($gutter * 0.6) 0;
  }
  .-submit,
  .-actions {
    margin: em($gutter * 1) 0 0;
  }

  // FIXME:
  // .-actions button {
  //   @extend %invest-button;
  // }

  .-field-row {
    display: flex;
    flex-flow: row;

    align-items: center;

    height: 60px;

    label,
    input[type="text"],
    select,
    .select {
      flex: 1 0 ;
      margin: 0;
    }

    input:not(:last-child) {
      margin-right: 10px;
    }

  }

  input,
  label,
  select {
    display: block;
    font-family: $base-font-family;
    font-size: $base-font-size;
  }

  label {
    font-weight: 600;
    margin-bottom: $small-spacing / 2;
    color: tint(black, 40);

    &.required::after {
      content: "*";
      margin-left: em(6);
    }

    &.-pad-checkbox {
      padding-left: em(24);
      input[type="checkbox"] {
        margin-left: em(-24);
        margin-right: em(12);
      }
    }

    abbr {
      display: none;
    }
  }

  //

  #{$all-text-inputs},
  select[multiple=multiple],
  select,
  textarea {
    display: block;
    width: 100%;
    @include appearance(none);

    background-color: $base-background-color;
    border: $base-border;
    border-width: 1px;
    border-radius: $base-border-radius;
    box-shadow: none;
    box-sizing: border-box;
    font-family: $base-font-family;
    font-size: $base-font-size;
    /* margin-bottom: $base-spacing / 2; */
    margin-bottom: 0;
    // padding: em($gutter * 0.4);
    // line-height: 1.5em;
    height: 40px;
    padding: 6px 12px;
    // padding: $base-spacing / 3;

    transition: border-color;
    /* width: 100%; */

    &:hover {
      border-color: darken($base-border-color, 10%);
    }

    &:focus {
      border-color: $action-color;
      /* border-color: $light-green; */
      box-shadow: none;
      outline: none;
    }
  }

  textarea {
    min-height: 60px;
    resize: vertical;
    overflow: auto;
  }

  input[type="search"] {
    @include appearance(none);
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: inline;
    margin-right: $small-spacing / 2;
  }

  input[type="file"] {
    padding-bottom: $small-spacing;
    width: 100%;
  }

  select {
    margin-bottom: $base-spacing;
    max-width: 100%;
    /* width: auto; */
    cursor: pointer;
  }

  select::-ms-expand {
		display: none;
	}

  .select {
    /* select element wapper */
    width: 100%;
    position: relative;

    &:after {
      /* arrow icon for select element */
      content: '';
      position: absolute;
      z-index: 1;
      right: 16px;
      top: 50%;
      margin-top: -8px;

      display: block;
      width: 16px;
      height: 16px;

      background: url('/images/svg/optimized/cd-icon-arrow.svg') no-repeat center;
      pointer-events: none;
    }

    select {
      // background: none;
      background: white;
    }
  }

/*
  .promo input {
    // font-size: em(16);
    background: tint(black, 96);
    font-weight: bold;
    text-align: center;
  }
*/

  .infoblock {
    // background: tint(black, 96);
    background: tint(#FFF8DB, 25);
    border: 1px solid tint(#FFF8DB, 0);
    border-radius: $base-border-radius;
    padding: ($gutter);
  }

  // FIXME: ?
  .button + .button {
    margin-top: em($gutter * 0.6);
  }

  .thumbnails {
    overflow: hidden;
    border-radius: $base-border-radius ;
    background: tint(black, 80);
    /* padding: em($gutter); */
    margin-bottom: 0;

    img {
      display: block;
      margin: em($gutter * 0.6) auto;
      width: auto;
      max-width: 100%;
    }
  }

  .progress_bar {
    height: 4px;
    background: $green;
    width: 0;
    margin-top: 2px;
    border-radius: 2px;
  }


  [required] {
    & {
      background: url('/images/svg/optimized/cd-required.svg') no-repeat top ;
      background-position-x: 100%;
      @include transition(all 200ms ease);
    }
    &:focus {
      background-position-x: 110%;
      @include transition(all 200ms ease);
    }
  }

  //
}


.anchor {
  &.-block {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top: em($gutter * 0.6);
    text-align: center;
  }
}

.divider {
  display: block;
  position: relative;
  z-index: 0;
  margin: em(16) auto;

  text-align: center;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    height: 0;
    width: auto;
    margin: auto em(8);

    border-bottom: 1px solid tint(black, 80);
  }

  >span {
    position: relative;
    z-index: 4;
    padding: 0 em(12);
    color: tint(black, 40);
    background: white;
  }
}
