// @import "../application/lib/_lib/chartist/settings/_chartist-settings.scss";
// @import "../application/lib/_lib/chartist/chartist.scss";


.payout-block {
  & {

  }

  >.head {
    text-align: center;
    font-size: em(16*3);
    margin: $column auto $gutter;

    width: 100%;
  }

  >.body {

    background: tint(black, 100);
    border: 1px solid tint(black, 90);
  }

  >.body >.text {
    padding: $gutter*1;
  }


  @include media($medium-screen-up) {

    >.body {
      display: flex;
      flex-flow: row nowrap;

      max-width: 100%;

      background: tint(black, 100);
    }

    >.body >.text {
      flex: 0 0 auto;
      width: 45%;
      // padding: 3em;
      padding: $gutter*2;
    }

  }

}

.payout-calculator {
  & {
    flex: 1 auto;

    // padding: $gutter;

    color: tint(black, 20);
    background: tint(black, 100);

    // border-left: 1px solid tint(black, 90);

    max-width: 900px;
    margin: 0 auto;
  }

  > div {
    // margin: $gutter/2;
    margin: 1em;
    // overflow: scroll;
  }

  @include media($medium-screen-up) {

    > div {
      margin: $gutter;
    }
  }

}


.payout-table-wip {
  & {
    background: tint(black, 10);
    color: white;

    margin: 0;
    padding: 0;

    overflow: hidden;
    // border-radius: $base-border-radius;
    border-radius: 4px;

    // width: auto;

    // @include media($medium-screen-up) {
      width: 100%;
    // }
  }

  tr {
    border: none;
  }

  // thead {
  //   border: 1px solid tint(black, 50);
  // }

  th {
    font-size: 12px;
    font-weight: bold;

    line-height: 2.6em;
    padding: 8px 12px;

    border: 1px solid tint(black, 10);
    background: black;

    white-space: nowrap;
    text-align: center;
  }

  td {
    font-size: 12px;
    // display: block;
    border: none;
    border: 1px solid tint(black, 25);
    // padding: 8px 12px;
    padding: 0;
    width: auto;

    white-space: nowrap;
    text-align: center;
  }

  span {
    @include appearance(none);

    font-size: 13px;
    white-space: nowrap;

    display: block;
    // width: 120px;
    width: 100%;

    color: inherit;
    border: none;
    background: none;

    margin: 0;
    // padding: 0;
    padding: 8px 12px;

    line-height: 2em;
    // box-shadow: none;
    // outline: none;

    // border: 1px solid tint(black, 25);
    // border-radius: 0;

    // @include media($medium-screen-up) {
    //   width: 100%;
    // }
  }

  // span,
  input {
    @include appearance(none);

    font-size: 13px;
    white-space: nowrap;

    display: block;
    // width: 120px;
    width: 100%;

    color: inherit;
    border: none;
    background: none;

    margin: 0;
    // padding: 0;
    padding: 8px 12px;

    line-height: 2em;
    box-shadow: none;
    outline: none;

    // border: 1px solid tint(black, 25);
    border-radius: 0;

    &:hover,
    &:focus,
    &:active {
      outline: 1px solid $blue;
      // border: 1px solid $blue;
      // box-shadow: 0 0 0 2px $blue;
    }

    // @include media($medium-screen-up) {
      // width: 100%;
    // }

  }

}

.investment-select {
  & {
    position: relative;
    // color: white;
    text-align: center;
    height: 80px;
  }

  >select {
    @include appearance(none);

    background: tint(black, 10);
    border: none;
    // color: inherit;
    color: white;

    font-size: 18px;
    font-weight: bold;
    height: inherit;
    // text-align: center;
    margin: 0;
    padding: 0 2em ;
    display: block;
    width: 100%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.5em;
    left: auto;
    margin: auto;

    @include triangle(12px, white, down);
  }
}
