

#modal {
  & {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;

    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;

    margin: 0;
    padding: 60px ($gutter * 0.4) ($gutter * 0.4);

    color: white;
    background: rgba(black, 0.6);

    display: flex;
    flex-flow: column;

    align-items: center;
    justify-content: center;

    overflow: hidden;
    overflow-y: scroll;
  }

  &.vip {
    background: rgba(0, 0, 0, 0.9);
  }

  >.wrapper {
    @include outer-container();

    flex: 0 1 auto;
    width: 100%;
    // height: 100%;

    // padding:($gutter * 0.6);

    overflow: hidden;
    overflow-y: scroll;
  }

  >.exit {
    position: absolute;
    top: 0;
    right: 0;

    @include size(60px);

    background: url("/images/svg/optimized/x-icon-02.svg") center no-repeat;
    background-size: 24px;

    cursor: pointer;

  }
}
