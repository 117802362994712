#layout > .body {
  & {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100vh;
  }
}

// .resources-page {
//   & {
//     // padding-top: 120px;
//   }
// }

.resources-page > .header-block {
  & {
    display: block;
    background: $dark-green;

    width: 100%;
    height: calc(80vh - 120px);
    padding-top: 120px;
  }
}

.resources-page > .body-block {
  & {
    display: block;
    background: white;

    width: 100%;
    min-height: 100vh;
  }

  >.grid {
    position: relative;
    @include outer-container(1200px);
  }

  >.grid >.figureblock {
    @include span-columns(4.5 of 12);
    @include shift(0.5);

    position: relative;
    transform: translateY(-30%);
  }
  >.grid >.textblock {
    @include span-columns(6 of 12);
    @include shift(0.5);
    @extend .type-system-sans;

    padding: 3rem 1rem;
  }
}

// figure
.resources-page >.body-block >.grid >.figureblock >.figure {
  // position: absolute;
  // top: 0;
  // left: 0;
  // transform: translateY(-60%);
  display: block;
  overflow: hidden;

  height: 0;
  width: 100%;
  padding-top: 125%;

  background: tint(black, 95);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

// email form
.email-form > form {
  display: block;
  margin: 2rem auto;
  // padding: 0 1rem;

  // max-width: 600px;
  // width: 320px;
  max-width: 100%;

  input[type='email'] {
    @include ellipsis();

    display: block;

    margin: 0.8em auto ;
    padding: 0 2em;

    line-height: (60px);

    // color: tint(black, 20);
    // background: rgba(black, 0.75);
    color: gray;
    background: tint(black, 92);

    outline: none;
    border: none;

    border-radius: 4px;

    @include transition(background-color 600ms ease);

    &::-webkit-input-placeholder {
      color: tint(black, 50);
      text-align: center;
    }

    &:focus,
    &:active {
      background: rgba(white, 1);
    }

  }
  button[type='submit'] {
    display: block;
    // flex: 1 0 auto;
    text-align: center;
    line-height: (60px);

    width: 100%;
    max-width: none;
    padding: 0 1.6em;

    color: tint(black, 20);
    color: white;
    // background: white;
    background: lighten($green, 10);
    border-radius: 4px;
  }
}

// resource index page


.resources-index-page > .header-block {
  & {
    display: block;
    // background: $dark-green;

    width: 100%;
    min-height: calc(100vh - 1rem);

    padding-top: 120px;

    background: center no-repeat;
    background-attachment: scroll, fixed;
    background-size: cover;
    background-image:
      linear-gradient(0, $dark-green, rgba(black, 0.8)),
      url('/images/css/proposal-bg--large@2x.jpg')
    ;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  >.grid {
    @include outer-container(1000px);
    position: relative;
  }
  >.grid >.textblock {
    @include span-columns(12 of 12);
    // @include shift(0.5);

    @extend .type-system-sans;

    position: relative;
    // transform: translateY(-30%);

    color: white;
    font-size: rem(16);
    padding: 1rem;

    @include media($large-screen-up) {
      @include span-columns(6.5 of 12);
    }

  }
  >.grid >.figureblock {
    @include span-columns(12 of 12);
    // @include shift(0.5);

    // @extend .type-system-sans;
    //
    padding: 1rem;
    color: white;
    font-size: rem(12);
    // padding: 0 1rem;
    @include media($large-screen-up) {
      @include span-columns(5 of 12);
      @include shift(0.5);
    }


    >.figure {
      background: gray center no-repear;
      background-size: cover;
      background-image: url('/images/css/brick-and-mortar--medium@2x.jpg');

      height: 0;
      width: 100%;
      margin: 2rem 0 1rem;
      padding-bottom: 100%;

      box-shadow: 0 2px 8px 0 rgba(0,0,0,.2);

    }
  }
}

.resources-index-page > .body-block {
  & {
    display: block;
    background: tint(black, 98);

    overflow: hidden;

    width: 100%;
    min-height: 100vh;
  }

  >.grid {
    position: relative;
    @include outer-container(1000px);
  }

  .list-block {
    display: block;
    // overflow-y: hidden;
    // overflow-x: visible;
    // background: white;
    @include outer-container();

    >.title {
      color: tint(black, 50);
      margin: 4rem auto 3rem;
      padding: 0 2rem;
      font-size: rem(16);
      font-weight: 600;
      text-transform: uppercase;
    }

    >.list {
      margin: 3rem auto;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,.2);
    }

    >.list >.item {
      background: white;
      line-height: 2rem;
      padding: 1rem 2rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid tint(black, 90)
      }
    }

    >.list >.item > a {
      // margin: 1rem 0;
      font-size: rem(18);
      font-weight: 500;
      // margin: 1rem 0 2rem;
    }

    >.list >.item >.question {
      // margin: 1rem 0;
      font-size: rem(18);
      font-weight: 500;
      // margin: 1rem 0 2rem;
    }
    >.list >.item >.answer {
      margin: 2rem 0 1rem;
      font-size: rem(16);
    }

     >.list >.item.-ask-question {
      padding: 0;

      form {
        display: flex;
        flex-flow: row nowrap;
      }

      input {
        flex: auto;
        border: none;
        outline: none;
        margin: 0;
        padding: 1rem 2rem;
        border-radius: 0;

        font-size: rem(18);
        color: tint(black, 40);
        font-weight: 500;
        box-shadow: none;

        border: solid;
        border-width: 1px 0 1px 1px;
        border-color: lighten($green, 10);

        @include transition(
          border-color 600ms ease
        );

        &:focus,
        &:active {
          box-shadow: none;
          border-color: lighten($green, 20);
        }
        &:focus + button,
        &:active + button {
          background: lighten($green, 20);
        }
      }
      button {
        flex: auto;
        margin: 0;
        padding: 0 4rem;
        border-radius: 0;
        background: lighten($green, 10);

        line-height: 5rem;

        @include transition(
          background-color 600ms ease
        );

        &:hover,
        &:focus,
        &:active {
          background: lighten($green, 20);
        }
      }
     }
  }
}

//

.resources-index-page .press-block {
  width: 100%;
  z-index: 1;
  position: relative;
  background: $dark-green;

  >.list {
    @include outer-container();
    display: flex;
    flex-flow: row wrap;
    // align-items: center;

    // height: $column * 2;
  }

  >.list>.item {
    flex: 1 50%;
    height: $column;
    // width: 100%;
    background: none center no-repeat;
    background-size: 50%;
    opacity: 0.75;
    // margin: $gutter 0;
    border-top: 1px solid tint($dark-green, 10);
    // border: 1px solid tint($dark-green, 10);
  }

  >.list>.item:nth-of-type(2n) {
    border-left: 1px solid tint($dark-green, 10);
  }


  @include media($medium-screen-up) {
    >.list {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      height: $column * 2;
    }
    >.list>.item {
      flex: 1 auto;
      // height: $column;
      // width: auto;
      background: none center no-repeat;
      background-size: contain;
      // opacity: 0.75;
      border: none;
    }
    >.list>.item:not(:last-of-type) {
      margin-right: $gutter*2;
    }
    >.list>.item:nth-of-type(2n) {
      border: none;
    }
  }

}



.useravatar-block {
  & {
    flex: 0;
    // align-self: flex-end;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    margin: 0 auto;
    padding: 2rem 0;
  }

  >.avatar {
    @include size(5.5rem);
    flex: 0 0 auto;
    background: gray center no-repeat;
    background-size: cover;

    overflow: hidden;
    border-radius: 50%;

    margin-right: 1rem;
  }
  >.name {
    font-size: rem(14);
    font-weight: 600;
  }
}
