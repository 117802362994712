// @goose you probably want to move this.
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; // probably change this.
  z-index: 9999998;

  >.modal-body {

    & {
      @extend %card-style;
      padding: em($gutter);
    }

    position: absolute;
    z-index: 9999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    width: 100%;
    max-width: 400px;
    max-height: 100%;
    overflow: scroll;
    /* max-height: 90%; */
    @include media(min-width 1024px) {
      top: 10%;
      bottom: auto;
    }

  }
}
