%valuelist {
  font-size: em(14);
  @include margin( $gutter auto $gutter);
  @include row(table);

  .label {
    font-weight: normal;
    font-size: em(14);
  }
  .value {
    font-weight: bold;
    font-size: em(16 * 1.5);
    /* font-size: 160%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-bottom: em(14);

    &.-gray {
     color: tint(black, 50%);
    }
    &.-small {
      font-size: em(18);
    }
  }

  >.cell {
    @include span-columns(1 of 2);
  }
}

.profile-block {
  & {
    @extend %card-style;
  }

  >.head {
    @include row(table);
    font-size: em(14);

    padding: em($gutter) 0;
    text-align: center;

    @include media($medium-screen-up) {
      text-align: left;
    }
  }

  >.head >.avatar {
    vertical-align: top;
    @include media($medium-screen-up) {
      @include span-columns(3 of 12);
    }

    img {
      display: block;
      @include size(180px);
      margin: 0 auto;
      padding: 0;
    }
  }
  >.head >.user {
    @include media($medium-screen-up) {
      @include span-columns(3 of 12);
    }

    @extend %valuelist;
    vertical-align: top;
  }
  >.head >.location {
    @include media($medium-screen-up) {
      @include span-columns(3 of 12);
    }
    @extend %valuelist;
    vertical-align: top;
  }
  >.head >.actions {
    @include media($medium-screen-up) {
      @include span-columns(3 of 12);
    }
    @extend %valuelist;
    vertical-align: top;

    padding: 0 em($gutter);
    a {
      width: 100%;
      margin-bottom: em(8);
      text-align: center;
    }
  }

  >.body {
    overflow: hidden;
    /* @include row(table); */
    font-size: em(14);
    background: #E9EAED;

    >ul {
      @include row();
      margin: em($gutter) auto;
      li {
        @include media($medium-screen-up) {
          @include span-columns(4 of 12);
          @include omega(3n);
        }
      }
    }

    .-list {
      & {
      }
      >.item {
        text-align: center;
        margin-bottom: em($gutter * 1);
        /* border: 1px solid tint(black, 50); */
        background: white;
      }
      /* >.item + .item {
        border-left: none;
      } */

      >.item >.name {
        padding: em($gutter);
      }
      >.item >.actions {
        padding: em($gutter * 0.6);
      }
    }

    >.title {
      padding: em($gutter) 0 em($gutter * 0.3);
      margin: 0 auto;
      font-size: em(18);
      text-align: center;
    }
  }

}
