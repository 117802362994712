
.flash-message {
  & {
    color: white;
    text-align: center;

    background: tint(black, 20);
  }

  >.container {
    @include outer-container();
    position: relative;
    padding: em($gutter * 0.6);
  }
  >.container >.close {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    height: em(40);
    width: em(40);

    background: url('/images/svg/x-icon-02.svg') no-repeat center;
    background-size: 40%;
  }

  &.error,
  &.-error {
    color: white;
    background: rgba(red, 0.9);
    font-weight: bold;
  }
  &.success,
  &.-success {
    color: white;
    background: rgba($green, 0.9);
    // font-weight: bold;
  }
  &.notice,
  &.-notice {
    color: white;
    background: rgba(#F9AF48, 0.9);
    font-weight: bold;
  }
  /* &.-info {} */
}
