
// FIXME:
.project-thumbnail {
  & {
    @extend %card-style;
    /* font-size: em(14); */
  }

  &+& { margin-top: em($gutter);}

  >.head {
    position: relative;
    z-index: 0;

    width: 100%;
    height: 0;
    padding-top: (100/3*2 * 1%);
  }
  >.head >.image {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    margin: auto;
    background: tint(black, 80) 100% center no-repeat;
    background-size: cover;

    &:after {
      content: '';
      display: block;
      position: absolute;
      @include size(100%);

      @include linear-gradient(transparent 40%, rgba(black, 0.9) 100%);
    }
  }
  >.head >.title {
    position: absolute;
    z-index: 3;
    /* top: 0; */
    bottom: 0;
    left: 0;
    right: 0;
    padding: em($gutter);
    margin: auto;
    margin-bottom: em($gutter * 1.4);

    color: white;
    text-align: center;
    @extend %type-system;

    /* font-size: em(14); */

    h2 {
      font-weight: bold;
      margin-bottom: em(6);
    }

    p {
      margin: 0;
    }

  }

  >.head >.avatar {
    @include size(80px);
    position: absolute;
    z-index: 2;
    /* top: 0; */
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;

    margin: auto;
    margin-bottom: -40px;

    background: gray 100% center no-repeat;
    background-size: cover;
  }
  >.head >.status {
    position: absolute;
    top: em($gutter * 1);
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;

    height: auto;
    // width: 20%;
    // min-width: em($gutter * 4);
    max-width: 40%;
    /* border-radius: $base-border-radius; */
    border-radius: em($gutter * 1.6) /2;
    line-height: em($gutter * 1.6);

    /* border-top: 1px solid tint(black, 60); */
    font-size: em(13);
    text-align: center;
    font-weight: bold;
    color: tint(white, 0);
    box-shadow: -2px 2px 4px 0 rgba(black, 0.2);

    background-color: $dark-green;

    &.-complete {
      background-color: tint($green, 10);
    }
    &.-raising {
      /* blue? gray? */
      background-color: darken($blue, 10);
    }
    &.-faq {
      /* yellow? */
      background-color: tint(#F9AF48, 10);
    }
  }

  /* >.body {} */

  >.actions {
    /* @extend %type-system; */
    display: block;
    font-weight: bold;
    text-align: center;
    padding: em($gutter);
    margin-top: em($gutter * 1.2);
  }

}

// // FIXME:
// .projects-list {
//
//   & {
//     @include row();
//     /* display: block; */
//     /* overflow: hidden; */
//   }
//
//   & {
//     >.project-thumbnail {
//       font-size: em(16);
//
//       /* @include span-columns(1 of 1); */
//       /* @include omega(3n); */
//
//       /* margin-bottom: em($gutter); */
//     }
//   }
//
//   &.-col-3 {
//     @include media( $medium-screen-up ) {
//       >.project-thumbnail {
//         font-size: em(14);
//
//         margin-top: 0;
//         margin-bottom: em($gutter * 1.6);
//
//         @include span-columns(4 of 12);
//         @include omega(3n);
//       }
//     };
//   }
//
//   &.-col-2 {
//     @include media( $medium-screen-up ) {
//       >.project-thumbnail {
//         font-size: em(14);
//
//         margin-top: 0;
//         margin-bottom: em($gutter * 1.6);
//
//         @include span-columns(6 of 12);
//         @include omega(2n);
//       }
//     };
//   }
//
// }
