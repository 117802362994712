
.city-page {
  & {
    position: relative;
    z-index: 0;

    overflow: hidden;
    // overflow-y: scroll;

    // min-height: 100vh;
    height: 100vh;

    background: tint(black, 10) center bottom no-repeat;
    background-size: cover;
    background-image: url('/images/css/photo-1414005987108-a6d06de8769f.jpeg');
    // background-attachment: fixed;

    // display: flex;
    // flex-flow: column;
    // // align-items: center;
    // justify-content: flex-end;;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: 0;

    height: 100%;
    width: 100%;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    // background: rgba(black, 0.5);
    background: linear-gradient(0, black, transparent);
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 0;

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    background: rgba(black, 0.25);
  }

  >.wrapper {
    // flex: 1 0 auto;
    // @include outer-container();
    position: relative;
    z-index: 2;

    height: 100%;
    padding: 1em;
    padding-bottom: 60px;

    overflow: hidden;
    overflow-y: scroll;
    // scrollbar stuff
    // overflow-y: hidden;
    // overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar { display: none;}

    // @include media($medium-screen-up) {
    //   padding: 0;
    // }
  }
  >.logo {
    // flex: 0 0 auto;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    margin: 20px auto;
    // margin: 0 auto;
    // background: white;

    @include size(40px);

    fill: white;
  }

}

.initial-block {
  & {
    display: block;
    position: relative;

    // display: flex;
    // flex-flow: column;
    // align-items: center;
    // justify-content: center;

    min-height: 100%;
    overflow: hidden;

    padding-top: 10vh;

    font-size: em(12);

    @include media($medium-screen-up) {
      font-size: em(16);
    }
  }

  >.headline {
    color: white;
    font-size: em(16 * 4);
    font-weight: 300;
    text-align: center;
  }
  >.subline {
    color: white;
    font-size: em(16 * 2);
    font-weight: 300;
    text-align: center;
  }
  >.text {
    color: white;
    font-size: em(14);
    // font-weight: 300;
    text-align: center;

    margin: 1em auto;
    max-width: (600px);
  }

  .email-field {
    // display: block;
    margin: 80px auto 0;
    max-width: 400px;

    font-size: 16px;
  }

  .email-field form {
    display: flex;
    flex-flow: row nowrap;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
  }

  .email-field button {
    background: white;
    color: tint(black, 20);
    border-radius:  0 4px 4px 0;
    border-left: 1px solid tint(black, 90);

    flex: 1 0 auto;
  }

  .email-field input {
    @include ellipsis();
    // @include size(80px);

    display: block;
    // width: 100%;
    // overflow: hidden;
    margin: 0 ;
    // margin-right: 10px;
    padding: 0 2em;
    border-radius: 4px 0  0 4px;

    // text-align: center;
    line-height: (80px);

    color: tint(black, 20);
    background: rgba(black, 0.75);


    // box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
    outline: none;
    border: none;

    outline: none;
    // border: 1px solid rgba(white, 0.5);

    @include transition(background-color 600ms ease);

    &::-webkit-input-placeholder {
      color: tint(black, 50);
      // text-align: center;
    }

    &:focus,
    &:active {
      background: rgba(white, 1);
    }
  }

}

.questionnaire-block {
  & {
    max-width: (600px);

    margin: 1em auto;
    padding: 2rem 2rem;
    background: white;
    border-radius: 4px;

    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
  }
}

.thankyou-block {
  color: white;
  // font-size: em(18);
  // font-weight: 300;
  text-align: center;

  // margin: 10em auto;
  height: 80%;

  display: flex;
  flex-flow: column;
  justify-content: center;


  >.headline {
    font-size: em(24);
    font-weight: 600;
  }
}
