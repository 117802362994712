$tooltip-bg: tint(black, 0);

#tooltip
{
    text-align: center;
    color: #fff;
    position: absolute;
    z-index: 9999999999;

    font-size: em(12);
    padding: em($gutter * 0.6);
    background: $tooltip-bg;
    border-radius: $base-border-radius;

    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
}

    #tooltip:before /* triangle decoration */
    {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $tooltip-bg;
        content: '';
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -10px;
    }

        #tooltip.top:before
        {
            border-top-color: transparent;
            border-bottom: 10px solid $tooltip-bg;
            top: -20px;
            bottom: auto;
        }

        #tooltip.left:before
        {
            left: 10px;
            margin: 0;
        }

        #tooltip.right:before
        {
            right: 10px;
            left: auto;
            margin: 0;
        }

#tooltip:after {
  content: "tap to close";
  position: relative;
  display: block;
  color: tint(black, 50);
  font-size: em(14);
  margin-top: em(12);

  @include media(min-width 1024px) {
    display: none;
  }
}
