.lead-investors {
  & {
    display: block;
    position: absolute;
    // overflow: hidden;
    overflow: scroll;

    width: 100%;
    height: 100%;

    color: white;

    padding: $gutter;

    background:
      linear-gradient(to top, $dark-green, rgba(black, 0.75)),
      url('https://images.unsplash.com/photo-1414235077428-338989a2e8c0?crop=entropy&dpr=2&fit=crop&fm=jpg&h=800&ixjsv=2.1.0&ixlib=rb-0.3.5&q=50&w=1000') black center no-repeat
    ;
    background-size: auto, cover;

  }

  >.text-row {
    color: white;
    font-size: rem(18);

    margin: 2rem auto;
    // padding: 0 2em;

    max-width: 600px;

    @extend .type-system-sans;
  }

  >.form-row {
    @extend .form-block;

    form {
      display: block;
      // background: white;
      background: none;

      font-size: 18px;

      margin: 2em auto;
      padding: 0;

      color: black;

      max-width: 600px;

      // box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);

      label {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: rgba(white, 0.75);

        line-height: 24px;

        background: none;
        border: none;
        outline: none;

        box-shadow: none;
      }

      textarea,
      input {
        font-size: 14px;
        font-weight: 400;
      //   // text-transform: uppercase;
      //
        // color: white;
        line-height: 48px;
        padding: 0 1em;
      //
      //   background: rgba(black, 0.9);
        border: none;
        outline: none;
        box-shadow: none;
      //
      //   &:focus,
      //   &:active {
      //     outline: none;
      //     box-shadow: none;
      //     background: rgba(black, 0.1);
      //     // box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
      //   }
      }

      // textarea {
      //   padding: 1em;
      //   line-height: 1.7;
      // }
    }

    button[type='submit'] {
      display: block;

      font-size: 16px;
      font-weight: 600;
      text-align: center;

      color: white;
      background: lighten($green, 10);
      // background: white;
      border: 1px solid lighten($green, 10);

      width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: 48px;

      margin: 2em auto;
      padding: 0 40px;

      border: none;


      // transition: background-color 500ms ease;
      // transform: translateY(75%);

      &:hover,
      &:focus {
      //   color: white;
      //   background: lighten($green, 10);
        outline: none;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
      }
    }
  }

  >.logo {
    position: relative;;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    margin: 1rem auto;

    @include size(3rem);

    fill: white;
  }
}
