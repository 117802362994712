.home-page-v2 {
  & {
    display: block;
    // overflow: hidden;
    width: 100%;
    height: auto;
    min-height: 100vh;

    background: white;

    // padding-top: 120px;
  }

}

.home-page-v2 >.header-block {
  & {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 0;
    padding-top: 120px;
    height: auto;
    min-height: 100vh;
    color: white;
    background: #000;
  }

  >.gridblock {
    position: relative;
    z-index: 1;
    @include outer-container();
    display: flex;
    flex-flow: column;
    // align-items: center;
    justify-content: center;
  }

  .headline {
    @extend .type-system-sans;
    padding: 5rem 2rem 2rem;
  }

  .actions {

    padding: 0 2rem;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
    }

    a {
      @extend %round-button;

      margin: 1rem auto;
      width: 100%;

      background-color: lighten($green, 10);
      border: none;

      @include media($medium-screen-up) {
        flex: 0 0 auto;
        width: 200px;
        margin-right: 1rem;
        margin-left: 0;
      }
    }

  }
  >.press {
    display: none;
    position: absolute;
    z-index: 1;
    bottom: 0;

    height: 7rem;
    width: 100%;

    @include media($medium-screen-up) {
      display: block;
    }

    ul {
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      @include outer-container(1000px);
    }

    li {
      flex: auto;
      opacity: 0.75;
      margin: 2rem 1.5rem;
      background: none center no-repeat;
      background-size: contain;
    }

  }

	.projects-list {
		margin-top: 2rem;
    
    .body {
      text-align: center;
      padding: 5px 0;
      height: 2em;
    }

		@media screen and (max-height: 842px) {
			display: none;
		}
	}

}

.home-page-v2 > .about-block {
  & {
    background: tint(black, 98);
  }

  >.gridblock {
    @include outer-container(1000px);
    @extend .type-system-sans;

    padding: 6rem 2rem 2rem;
    // background: white;
    font-size: rem(12);
    // line-height: 10rem;
    color: tint(black, 25);
  }

  >.testimonials-block {
    @include outer-container(1000px);

    margin: 0 auto;
    padding: 1rem 0 4rem;

    background: none;
    overflow: visible;

    .item {
      border-radius: 4px;
      box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.06);
    }

    .quoteblock {
      font-size: 14px;
    }
  }
}

.home-page-v2 > .prequel-block {
  & {
    display: block;
    position: relative;

    height: 90vh;
    width: 100%;

    background: #000 center no-repeat;
    background-size: cover;
    background-image: url("/images/css/brick-and-mortar--medium@2x.jpg");
    // background-attachment: fixed;

    border: 1rem solid tint(black, 98);

    @include media($medium-screen-up) {
      background-attachment: fixed;
    }
  }

  >.textblock {
    @include outer-container(1000px);
    @extend .type-system-sans;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 4rem 3rem;
    background: white;
    box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.2);

    transform: translateY(8rem);

    h1 {
      text-align: center;
      margin-bottom: 3rem;
    }
    p {
      margin: 0 auto;
      max-width: 600px;
    }
  }
}

.home-page-v2 >.restaurants-block {
  & {
    background: tint(black, 98);
    padding-top: 8rem;

    // position: relative;
    overflow: hidden;
  }

  >.gridblock {
    @include outer-container(1000px);

    margin: 3rem auto;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;

    }
  }

  .new-restaurants {
    flex: 1 1 50%;

    // font-size: rem(18);
    width: 100%;

    padding: 1rem 2rem;

    @include media($medium-screen-up) {
      padding: 1rem 2rem 1rem 4rem;
      border-left: 1px solid tint(black, 90);
    }

    @extend .type-system-sans;
    >a {
      @extend %round-button;
      background-color: lighten($green, 10);
      max-width: 200px;
      // margin: 1rem auto;
      margin: 3rem 0;
    }
  }
  .existing-restaurants {
    flex: 1 1 50%;

    // font-size: rem(18);
    width: 100%;
    padding: 1rem 2rem;

    @extend .type-system-sans;


    >a {
      @extend %round-button;
      background-color: lighten($green, 10);
      max-width: 200px;
      // margin: 1rem auto;
      margin: 3rem 0;
    }
  }
}


.home-page-v2 >.mobileapp-block {
  & {
    display: block;
    overflow: hidden;
    color: #000;
    background: white;
    padding: 4rem 0;
  }

  >.gridblock {
    @include outer-container(1000px);

    display: block;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
      // margin: 3rem auto;
      align-items: center;
    };
  }

  .textblock {
    @extend .type-system-sans;
    // padding: 2rem 3rem 2rem 0;
    padding: 2rem 2rem ;

    ul {
      font-size: rem(16);
      margin: 2rem 0;
      color: tint(black, 25);

      li {
        margin-bottom: 1rem;
      }
    }

    @include media($medium-screen-up) {
      max-width: 50%;
    };
  }

  .figureblock {
    flex: 1 1 auto;

    display: block;

    width: 100%;
    height: 400px;

    border-bottom: 1px solid tint(black, 90);

    background: none top center no-repeat;
    background-image: url('/images/css/IMG_4720_iphone6_silver_portrait.png');
    background-size: 150% auto;
    // background-size: contain;

    @include media($medium-screen-up) {
      max-width: 40%;
      padding-bottom: 60%;
      border-bottom: none;
    }
  }
}

.home-page-v2 >.structure-block {
  & {
    background: white;
    padding: 5rem 0;
  }

  >.optionslist {
    @include outer-container(1000px);
    // display: flex;
    // flex-flow: row nowrap;
    margin: 0 auto 6rem;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
    }

    >.item {
      flex: 1;
      position: relative;

      margin: 3rem 2px;
      padding: 2rem 2rem 4rem;
      min-height: 200px;

      color: lighten($dark-green, 10);

      background: tint($green, 10);
      border-radius: 4px;

      @extend .type-system-sans;

      @include media($medium-screen-up) {
        // height: 360px;
        min-height: 21rem;
        margin: 1rem 2px;
      }

      &:nth-child(1) {
        background: lighten($green, 50);
      }
      &:nth-child(2) {
        background: lighten($green, 35);
      }
      &:nth-child(3) {
        background: lighten($green, 20);
      }

    }
    >.item >.figure {
      // flex: 0 0 auto;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      @include size(6rem);
      overflow: hidden;
      margin: 1rem auto;
      padding: 1.5rem;

      fill: lighten($green, 25);
      background: $dark-green;
      border-radius: 50%;

      transform: translateY(50%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
  >.headline {
    @extend .type-system-sans;
    text-align: center;
    margin: 0 0 2rem;
    padding: 0 1rem;
  }
  >.textblock {
    @include outer-container(1000px);
    @extend .type-system-sans;

    padding: 1rem 2rem;
    margin: 2rem auto 2rem;
  }

  >.valuelist {
    @include outer-container(1000px);

    display: flex;
    flex-flow: row wrap;

    padding: 1rem;
    // margin: 4rem auto;

    >.item {
      // flex: auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      font-weight: bold;

      width: 100%;
      padding: 1rem;

      @include media($medium-screen-up) {
        width: calc(100% / 3);
      };
    }
    >.item >.figure {
      flex: 0 0 auto;
      // background: gray;
      // border-radius: 50%;
      overflow: hidden;
      margin-right: 1rem;

      @include size(2.5rem);

      fill: lighten($green, 10);
    }

    // >.item >.text {
    //
    // }
  }
}


.home-page-v2 >.pricing-block {
  & {
    display: block;
    overflow: hidden;
    padding: 2rem;
    background: tint(black, 98);
  }

  >.gridblock {
    display: block;
    width: 100%;
    min-height: 800px;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  >.gridblock >.item {
      flex: 1 1 50%;
      min-height: 500px;

      background: none center no-repeat;
      background-size: contain;

    &.-is-other {
      background-image: url('/images/svg/pricing-section-copy-01.svg');
    }
    &.-is-ee {
      background-image: url('/images/svg/pricing-section-copy-02.svg');
    }
  }

}

.home-page-v2 .cta-box {
  & {
    @include outer-container(1000px);

    margin: 3rem auto;
    padding: 2rem 2rem;

    background: white;

    border: 1px solid white;
    border-radius: 4px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);

    transition: border 500ms ease;

    &:hover {
      border: 1px solid lighten($green, 20);
    }

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  >.text {
    text-align: center;
    margin: 0.5rem auto 2rem;

    color: tint(black, 20);
    font-size: rem(28);
    font-weight: 500;

    @include media($medium-screen-up) {
      margin: 0;
      margin-left: 1rem;
      text-align: left;
    }
  }

  >.action-button {
    flex: 0 0 auto;
    // margin-right: 0;

    @extend %round-button;

    display: inline;;
    padding: 0 1rem;

    height: 3.5rem;
    line-height: 3.5rem;

    width: 100%;
    // max-width: 200px;

    background-color: lighten($green, 10);
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

    @include media($medium-screen-up) {
      max-width: 200px;
      margin-right:0 ;
    }
  }

}
