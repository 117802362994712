.social-block {
  & {
    @include row(table);

    width: em(320);
    margin: em($gutter) auto;
  }

  .item {
    @include span-columns(1 of 4);

    background: none center no-repeat;
    background-size: contain;
    /* background-image: url(asset-path('css/social/facebook-256.png')); */

    height: em(48);

    &.-fb {
      background-image: url('/images/css/social/facebook-256.png');
    }
    &.-tw {
      background-image: url('/images/css/social/twitter-256.png');
    }
    &.-ig {
      background-image: url('/images/css/social/instagram-256.png');
    }
    &.-li {
      background-image: url('/images/css/social/linkedin-256.png');
    }
  }
}
