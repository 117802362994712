%project-card {
  & {
    display: block;
    overflow: hidden;

    background: white;
    border-radius: $base-border-radius;
  }
  >.head {
    position: relative;

  }
  >.head >.figure {
    background: tint(black, 75) center no-repeat;
    background-size: cover;

    display: block;
    height: 0;
    width: 100%;
    padding-top: (100% / 16*9);
  }
  >.head >.label {
    position: absolute;
    bottom: 0;
    width: 100%;

    line-height: em(40);
    font-size: em(14);
    font-weight: bold;
    text-align: center;
    color: white;
    background: rgba( tint(black, 50), 0.9 );

    &.-raising {
      background: rgba( tint(#4F88FF, 0), 0.9 );
    }
    &.-complete {
      background: rgba( tint($green, 0), 0.9 );
    }
  }

  >.body {
    padding: em($gutter);
  }

  >.actions {
    text-align: center;
    padding: em($gutter/2);
    border-top: 1px solid tint(black, 90);
  }
  >.actions a {
    display: block;
    line-height: em(40);

    font-size: em(14);
    font-weight: bold;

    color: lighten($green, 10);
    // color: lighten($light-green, 0);

    @include transition(color 600ms ease);

    &:hover {
      color: $green;
      text-decoration: underline;
    }

  }
}

.project-list {
  & {
    display: flex;
    flex-flow: row wrap;

    // padding: (5px);
    margin: 0 (-5px);

  }
  >.project-item {
    & {
      flex: 0 1 auto;

      width: 100%;
      margin: (6px) (10px) ;
      // width: calc( 100%/4 - 10px);
      // margin: (6px) (5px) ;
    }

    @extend %project-card;

    @include media($medium-screen-up) {
      width: calc( 100%/4 - 10px);
      margin: (6px) (5px) ;
    }

  }
}
