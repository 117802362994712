.text-card {
  & {
    @extend %card-style;
    @extend .type-system-sans;
    color: tint(black, 20);
    background: white;
    padding: em($gutter * 1.6) em($gutter * 1) ;

    @include media($medium-screen-up) {
      padding: em($gutter * 2) em($gutter * 2) ;
    };
  }
}
