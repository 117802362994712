.referral-block {
  & {
    display: block;
    position: relative;
    overflow: hidden;

    margin: ($gutter) auto;

    background: tint(black, 95);
    border-radius: $base-border-radius;

    text-align: center;
  }
  >.head {
    font-size: em(16 * 2);
    font-weight: bold;
    // text-align: center;
    padding: ($gutter);
  }
  >.body {
    margin: 0 auto;
    max-width: (600px);

    p {
      margin: 0;
      line-height: 150%;
    }
  }

  >.actions {
    margin: 0 auto;
    padding: ($gutter);
    max-width: (600px);
  }

}

.flex-row {
  & {
    position: relative;

    display: flex;
    flex-flow: column-reverse;
  }

  >.content {
    overflow-y: auto;
    overflow-x: hidden;
  }

  @include media($medium-screen-up) {
    // display: flex;
    flex-flow: row nowrap;

    >.content {
      @include span-columns(7 of 12);

      position: relative;
      display: block;

      flex: 1 0 auto;
    }

    >.aside {
      // @include span-columns(4.5 of 12);
      @include shift(0.5);

      position: relative;
      display: block;

      flex: 0 1 auto;
    }
  }

}

.waitlist-block {
  & {
    background: white;

    // padding: em($gutter);

    color: tint(black, 20);

    // text-align: center;

    width: 100%;
    max-width: 600px;

    border-radius: $base-border-radius;

    margin: 0 auto;

    overflow: hidden;
  }

  .waitlist {
    padding: em($gutter);
  }

  .title {
    font-size: em(24);
    margin-bottom: em(12);
    font-weight: bold;
  }

  .email-input button {
    background-image: url('/images/svg/arrow-circle-on-right-black.svg');
  }

  .approved-message {
    padding: 2em;
    text-align: center;

    >.headline {
      font-size: em(32);
      margin-bottom: 1em;
    }
    >.body {
      margin-bottom: 2em;
    }
  }
  .approved-message > .invest-button{
    @extend %invest-button;
  }
}

.combodate {
  display: flex;
  flex-flow: row nowrap;

  align-items: center;

  select {
    margin: 0;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    -ms-appearance: menulist;
    -o-appearance: menulist;
    appearance: menulist;
  }
}
