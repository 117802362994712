#{$all-button-inputs},
button {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  background-color: $action-color;
  border-radius: $base-border-radius;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: darken($action-color, 15%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.button {
  & {
    @extend button;
    // display: block;
    color: white;
    background: #F9AF48;
    border: 2px solid #F9AF48;
    // width: 100%;
    // text-align: center;
    // padding: ($gutter * 0.6);
    // padding: em(10) em(12);
    font-size: em(14);
  }

  & + & {
    margin-top: em(8);
  }

  &:hover,
  &:focus {
    background-color: darken(#F9AF48, 15%);
    color: #fff;
  }

  &.-default {
    @extend button;
    color: white;
    background: #F9AF48;
    border: 2px solid #F9AF48;
    font-size: em(14);
  }

  &.-block {
    display: block;
    width: 100%;
    text-align: center;
    padding: ($gutter * 0.5);
    font-size: em(16);
  }

  &.-right {
    float: right;
  }

  &.-outline {
    background: transparent;
    color: #F9AF48;
    border-color: #F9AF48;
  }

  &.-label {
    display: block;
    width: 100%;
    text-align: center;
    background: transparent;
    color: $green;
    border-color: $green;
    cursor: default;
  }

  &.-outline.-green {
    background: transparent;
    color: $green;
    border-color: $green;
    &:hover,
    &:focus {
      background: $green;
      color: white;
    }
  }
  &.-outline.-grey {
    background: transparent;
    color: tint(black, 50);
    border-color: tint(black, 50);
    &:hover,
    &:focus {
      background: tint(black, 50);
      color: white;
    }
  }

  &.-green {
    background: $green;
    color: white;
    border-color: $green;
  }
  &.-yellow {
    background: #F9AF48;
    color: white;
    border-color: #F9AF48;
  }
  &.-grey {
    background: tint(black, 50);
    color: white;
    border-color: tint(black, 50);
  }
  &.-white {
    background: white;
    color: $dark-green;
    border-color: white;
  }

}
