// FIXME

%card-style {
  overflow: hidden;
  background: white;
  /* border: 1px solid rgba(tint(black, 100), 0.0); */
  border-radius: 3px;
}

%panel-style {
  // overflow: hidden;
  background: tint(black, 100);
  border: 1px solid tint(black, 90);
  // border-radius: $var;

  // & + & {
  //
  // }
}
