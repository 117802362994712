@import "../neat/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

/* $visual-grid: true; */
$visual-grid-index: front;
$visual-grid-color: #eee;
$visual-grid-opacity: 0.1;

// Neat Overrides
$column: 90px;
$gutter: 30px;
$grid-columns: 12;
/* $max-width: em(1088); */
$max-width: em(980);

// Neat Breakpoints
$medium-screen: em(640);
$large-screen: em(860);

$medium-screen-up: new-breakpoint(min-width $medium-screen 8);
$large-screen-up: new-breakpoint(min-width $large-screen 12);

// FIXME:
// add breakpoints for devices at some point…
// Device Breakpoints
/* $medium-screen: em(640); */
/* $large-screen: em(860); */
/* $medium-screen-up: new-breakpoint(min-width $medium-screen 8); */
/* $large-screen-up: new-breakpoint(min-width $large-screen 12); */
