.test-drive-page {
  & {
    // display: block;
    position: relative;
    z-index: 0;
    color: white;
    // background: black;
    background: center no-repeat tint(black, 20);
    background-size: cover;

    width: 100%;
    height: auto;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4rem 1rem 2rem;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;


    background: rgba(black, 0.5);
  }

  >.content {
    position: relative;
    z-index: 1;
    background: white;
    color: dimgrey;

    box-shadow: 0 6px 12px 0 rgba(black, 0.5);
    overflow: hidden;
    border-radius: 6px;


    width: 100%;
    max-width: 980px;

    // display: flex;
    // flex-flow: row nowrap;
    display: block;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
    }

    >.panelone {
      flex: 1 1 auto;

      width: 100%;
      padding-top: 60%;

      background: gray center no-repeat;
      background-size: cover;

      @include media($medium-screen-up) {
        width: auto;
        padding: 0;
      }

    }

    >.paneltwo {
      flex: 1 1 auto;
      // max-width: 60%;
      padding: 2.8rem 2rem;
      text-align: center;

      @include media($medium-screen-up) {
        max-width: 60%;
        padding: 3rem 3rem;
      }
    }

    .textblock {
      @extend .type-system-sans;
      font-size: rem(14);
    }

    // form styles
    form button {
      @extend %round-button;
      width: 100%;
      margin: 0;
      border: none;
      background-color: lighten($green, 10);
      box-shadow: 0 6px 12px 0 rgba(black, 0.05);
    }

    form label {
      position: relative;
      margin: 1rem 0;
      padding: 2rem 0;

      // background-color: tint($green, 90);
      background: tint(black, 94);

      &.radio-checked {
        background-color: tint($green, 90);
        border: 2px solid lighten($green, 10);
        border-radius: 4px;
      }
    }
    form label input {
      @include appearance(none);
      opacity: 0;

      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      background-color: tint($green, 90);
      border: 2px solid lighten($green, 10);
      border-radius: 4px;
    }
    form label input:checked {
      opacity: 1;
      outline: none;
    }
    form label span {
      position: relative;
      z-index: 1;
    }
    form label input:checked + span {
      color: $dark-green;
    }

    .purchased-amount {
      position: relative;
      margin: 1rem 0;
      padding: 2rem 0;

      font-size: rem(18);
      font-weight: 500;
      text-align: center;
      color: white;
      // border: 2px solid lighten($green, 10);
      border-radius: 4px;
      background-color: lighten($green, 10);
    }

    // .select-wrapper {
    //   display: block;
    //   position: relative;
    //   overflow: hidden;
    //   color: lighten($green, 10);
    //   // background: lighten($green, 10);
    //   border: 1px solid lighten($green, 10);
    //   border-radius: 4px;
    //   margin: 1rem auto;
    //
    //   &:hover {
    //     background-color: tint($green, 90);
    //   }
    //
    //   >.amount {
    //     display: block;
    //     text-align: center;
    //
    //     font-weight: 400;
    //     font-size: 3rem;
    //     line-height: 9rem;
    //
    //     margin-left: -08%;
    //   }
    //
    //   >select {
    //     @include appearance(none);
    //     opacity: 0;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     display: block;
    //     margin: 0;
    //     cursor: pointer;
    //   }
    // }

  }

}
