
%press-page {
  & {
    background: tint(black, 90);

    width: 100%;
    min-height: 100vh;

    overflow: hidden;
  }

  >.figure {
    position: relative;

    background: tint(black, 50) center no-repeat;
    background-image: url('/images/css/proposal-bg--large@2x.jpg');
    background-attachment: fixed;
    background-size: cover;

    width: 100%;
    min-height: 600px;
    height: auto;

    // padding: $gutter ;
    padding: ($gutter*4) $gutter em(80);

    @media screen and (max-width: 441px) {
      padding-top: $gutter * 5;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: rgba($dark-green, 0.9);

    }
  }

  >.figure .text {

    flex: 0 1 auto;

    padding: $gutter ;
    position: relative;
    color: white;

    a {
      display: inline-block;
      line-height: em(60);
      padding: 0 $gutter;

      font-weight: bold;

      color: lighten($green, 10);
      background: white;
      border-radius:$base-border-radius;

      &:hover {
        color: white;
        background: lighten($green, 10);
      }
    }
  }

  >.head {
    @include outer-container();

    position: relative;

    margin: (-64px) auto 0;
    background: white;
    border-bottom: 1px solid tint(black, 90);
    // padding: ($gutter * 2);

  }

  >.body {
    @include outer-container();

    // position: relative;
    background: white;
    padding: ($gutter * 2);

    margin: 0 auto $gutter;
  }

  .text {
    @extend %type-system;

    font-size: em(16);

    // p.-display {
    //   width: em(500);
    //   margin-left: auto;
    //   margin-right: auto;
    // }

    max-width: em(600);
    margin: 0 auto;

    ol {
      @extend %default-ol;
    }

    ul {
      @extend %default-ul;
    }
  }

  .text.-center {
    text-align: center;

    p {
      max-width: em(600);
      margin-left: auto;
      margin-right: auto;
    }
  }
  
}

#press-page {
  @extend %press-page;
}

.tabs {
  & {
    display: flex;
    flex-flow: row nowrap;

    align-items: center;

    max-width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }

  @include media($medium-screen-up) {
    justify-content: center;
  }

  >li {
    flex: 0 0 auto;

    line-height: em(60);
    padding: 0 $gutter;

    // &:not(:last-of-type) {
    //   margin-right: 4px;
    // }
  }

  .tab {
    display: block;
    border-bottom: 4px solid transparent;

    &.selected {
      // font-weight: bold;
      color: $green;
      // border-bottom: 4px solid $green;
      border-color: $green;
    }
  }
}


.figure-row {
  & {
    width: 100%;

    display: flex;
    flex-flow: column;
    // flex-flow: row;
    align-items: center;

    // justify-content: space-around;
    // justify-content: center;

    // margin: $gutter auto;
  }

  @include media($medium-screen-up) {
    flex-flow: row;
    justify-content: space-around;

    li {
      width: 25%;
    }
  }


  li {
    flex: 0 1 auto;
    background: tint(black, 95);

    // width: 30%;

    text-align: center;

    margin-bottom: ($gutter * 0.5);
    padding: ($gutter * 0.6);
    // border: 1px solid black;
  }
}
