%type-system,
.type-system-sans {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-border-radius: 3px !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $base-accent-color: #477DCA !default;
  $base-link-color: $base-accent-color !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $medium-screen: em(640) !default;
  $base-font-color: $dark-gray !default;

  h1, h2, h3, p {
    margin: 0;
  }

  hr {
    border-bottom: 1px solid $light-gray;
    border-left: none;
    border-right: none;
    border-top: none;
    margin: $base-spacing 0;
  }

  p {
    color: $base-font-color;
    color: inherit;
    line-height: $base-line-height;
  }

  a {
    text-decoration: none;
    // color: $base-link-color;
  }
  //////////////////////////////////////////////////////////////////////////////////

  // $sans-serif: 'PT Sans', sans-serif;
  // $sans-serif-2: 'Titillium Web', sans-serif;
  $sans-serif: 'proxima-nova', sans-serif;
  $sans-serif-2: $sans-serif;

  @include clearfix;
  text-align: left;

  .type {
    border-bottom: 1px solid;
    display: inline-block;
    font-family: $sans-serif-2;
    font-size: 0.7em;
    font-weight: 900;
    letter-spacing: 1px;
    margin-bottom: 2em;
    padding: 0.1em 0;
    text-align: left;
    text-transform: uppercase;
  }

  h1 {
    font-family: $sans-serif-2;
    font-size: 2em;
    // font-weight: 600;
    font-weight: 300;
    margin-bottom: 0.6em;

    @include media($medium-screen) {
      // font-size: 2.4em;
      // FIXME:
      font-size: 2.8em;
    }
  }

  h2 {
    font-family: $sans-serif;
    font-size: 1.2em;
    // font-weight: 400;
    // FIXME:
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 1.2em;

    @include media($medium-screen) {
      font-size: 1.4em;
    }
  }

  code {
    background: #F7F7F7;
    border-radius: $base-border-radius * 1.5;
    border: 1px solid #E0E0E0;
    font-family: monaco;
    font-size: 0.65em;
    font-style: normal;
    padding: 0.1em 0.4em;
    white-space: nowrap;
  }

  h3 {
   font-family: $sans-serif-2;
   font-size: 1em;
   font-weight: 700;
   line-height: 1.4em;
   margin-bottom: 0.5em;
   text-transform: uppercase;
  }

  p.date {
    color: transparentize($base-font-color, 0.6);
    font-family: $sans-serif;
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }

  p {
    font-family: $sans-serif;
    font-weight: 300;
    letter-spacing: 1;
    margin-bottom: 1.5em;

    span {
      font-family: $sans-serif;
      font-size: 0.8em;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  hr {
    width: 3em;
  }

  a.read-more {
    display: inline-block;
    font-family: $sans-serif;
    font-size: 0.8em;
    font-weight: 700;
    margin-left: 0.2em;
    position: relative;
    text-transform: uppercase;

    span {
      font-size: 1.7em;
      position: absolute;
      right: -10px;
      top: -2px;
    }
  }

  p.author {
    font-family: $sans-serif;
    font-style: italic;
  }

  //
  figure {
    display: block;
    position: relative;
    width: 100%;
    /* max-width: 100%; */
    /* background-color: tint(black, 96); */
    margin-bottom: em(16);

    figcaption {
      /* background-color: tint(black, 96); */
      padding: em($gutter * 0.6) em($gutter * 0.4);
      font-size: em(13);
      color: tint(black, 50);
    }

  }

  img {
    display: block;
    max-width: 100%;
    /* margin: 0 auto; */
  }

  //
  ul {
    @extend ul%default-ul;
  }

  ol {
    @extend ul%default-ol;
  }

}
