.project-page {
  & {
    display: block;
    position: relative;
    color: black;
    background: tint(black, 96);
    overflow: hidden;
  }

  .avatar {
    @include size(4rem);

    overflow: hidden;
    box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.1);

    margin: 1rem auto 1rem;
    border-radius: 50%;

    background: white center no-repeat;
    background-size: cover;
    background-image: url('/images/css/placeholder-avatar-01.png');
  }
}

.project-page >.header {
  & {
    position: relative;
    overflow: hidden;

    width: 100%;
    // min-height: 500px;
    height: 60vh;

    background: black center center no-repeat;
    // background-image: url('https://images.unsplash.com/photo-1460400355256-e87506dcec4f');
    background-size: cover;
    // background-attachment: fixed;
  }

  @include media($medium-screen-up) {
    width: 100%;
    height: 500px;
    height: calc(100vh - 220px);
    min-height: 0;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .headline {
    text-align: center;
    color: #fff;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;

    h1 {
      font-size: 2.5em;
      font-weight: 600;
    }

    h2 {
      font-size: 1.5em;
      font-weight: 400;
      font-style: italic;
    }
  }
}

.project-page >.content {
  position: relative;
  overflow: hidden;
  padding-bottom: 120px;
}

.project-page >.content >.section {
  & {
    @include outer-container(1200px);

    margin: $column auto;
    // padding: ($gutter) 0 ;

    color: tint(black, 25);
    // background: tint(black, 100);
    // border-bottom: 1px solid tint(black, 90);

    background: white;
    border-bottom: 1px solid tint(black, 80);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);

  }

  >.headline {
    color: gray;
    text-align: center;
    font-size: 32px;
    line-height: 2;
    margin: 0 auto;
    padding: 30px 30px;
    background: white;
    border-bottom: 1px solid #e6e6e6;
  }
  >.body {
    // color: tint(black, 25);
    font-size: 16px;
    line-height: 1.7;

    margin: 0 auto;
    padding: $gutter;
    max-width: 600px;
  }
}

.about-section {
  & {
    @include outer-container(1200px);

    overflow: hidden;

    background: white;
    margin: $column auto;

    background: white;
    border-bottom: 1px solid tint(black, 80);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  }

  >.headline {
    color: tint(black, 50);
    // text-align: center;
    font-size: (16px * 2);
    line-height: 2;

    max-width: 600px;
    margin: $gutter auto ($gutter*1);
    // margin: 0 auto;
    padding: 0 $gutter ;
  }
  >.body {
    // color: tint(black, 25);
    font-size: 16px;
    line-height: 1.7;

    margin: 0 auto;
    padding: $gutter;
    max-width: 600px;

		ul {
			list-style-type: inherit;
			padding-left: 2rem;
		}
  }

  >.owners {
    display: flex;
    flex-flow: row nowrap;

    justify-content: center;

    background: tint(black, 96);
    border-top: 1px solid tint(black, 90);

    padding: 1em $gutter;

    >.owner-avatar {
      @include size(80px);
      background: gray;
      border-radius: 50%;
      margin-right: 1em;
      overflow: hidden;
      background-size: cover;
    }

  }

}

.primary-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(black, 0.75) 8px, rgba(black, 0.0));
}

.header-bar {
  & {
    background: white;
    border: 0 solid rgba(black, 0.1);
    border-width: 1px 0;

    @include media(min-width 1200px) {
      position: absolute;
      z-index: 9999;

      top: 0;
      // bottom: 0;
      left: 0;
      right: 0;

      width: 100%;
      height: 120px;
    }

    &.is_stuck {
      top: auto;
      bottom: 0;
      box-shadow: 0 -8px 12px 0 rgba(black, 0.04);
    }

  }

  >.wrapper {
    @include outer-container(1200px);

    flex-flow: column;
    align-items: center;
    // padding: 1em 1em;

    @include media(min-width 1200px) {
      display: flex;
      flex-flow: row nowrap;
      // align-items: center;
      height: 120px;
      padding: 0 1em;
    }
  }

  .name {
    display: none;
    width: 100%;
    margin: 1em auto 2em;
    text-align: center;

    padding: 1em 1em;

    // border-right: 1px solid tint(black, 90);

    @include media(min-width 1200px) {
      flex: 1 0 auto;
      width: auto;
      margin: 0;
      text-align: left;
      display: block;
    }
  }

  h1 {
    // color: tint(black, 50);
    font-size: 24px;
    line-height: 1.8;
    font-weight: 400;

    margin: 0;
  }

  h2 {
    color: tint(black, 50);

    font-size: 16px;
    font-weight: 200;
    line-height: 1.0;

    margin: 0;
    margin-left: 2px;
    margin-bottom: 6px;
  }

  .action {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;

    flex: 1 0 auto;
    display: flex;
    align-items: center;

    // width: 100%;
    text-align: center;

    width: 100%;
    padding: 1em 1em;
    border-top: 1px solid tint(black, 90);

    background: white;

    @include media(min-width 1200px) {
      position: relative;
      z-index: auto;

      flex: 1 0 auto;
      // min-width: 220px;
      width: auto;
      max-width: 50%;

      border: none;
      padding: 0;
    }
  }

  .invest-button {
    @extend %invest-button;

    border-radius: 60px;
    max-width: 400px;
  }

  .ask {
    @include ellipsis(100%);
    flex: 1 0 auto;

    margin: 0 $gutter;

    line-height: 40px;

    font-weight: bold;
    font-size: 16px;

    color: lighten($green, 10);

  }

  .countdown {
    flex: 1 0 auto;
    width: auto;
    display: flex;
    flex-flow: column;

    align-content: flex-end;

    padding: 1rem 2rem;

    // border-left: 1px solid tint(black, 90);

    .project-funding-date-countdown {
      @include ellipsis();

      font-weight: 600;
      color: tint(black, 50);
      font-size: rem(18);
      line-height: 2rem;
    }

  }
}

.investment-process {
  & {
    display: none;
    // @include outer-container();
    // overflow: hidden;

    position: relative;
    // margin-top: 50vh;
    // bottom: 0;
    // left: 0;
    width: 100%;
    margin: ($gutter*2) auto $gutter;

    @include media($medium-screen-up) {
      // display: block;
      position: relative;
      bottom: 0;
      left: 0;
    }
  }

  &.-is-mobile {
      display: block;
      @include media($medium-screen-up) {
      display: none;
    }
  }
  &.-is-desktop {
      display: none;
      @include media($medium-screen-up) {
      display: block;
    }
  }

  ul {
    @include outer-container();

    // display: flex;
    // flex-flow: row nowrap;

    margin: 0 auto;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  ul li {
    flex: 1;

    background: #1a1a1a;
    color: #fff;
    text-align: center;
    // padding:  0 $gutter;
    margin: 2px;

    overflow: hidden;

    border: 2px solid $green;

    @include media($medium-screen-up) {
      overflow: visible;
      margin: 0;
      &:not(:last-of-type) {
        margin-right: 4px;
      }
    }

    &.-none-remaining {
      pointer-events: none;
      background-color: #888;

      .button {
        background-color: #888;
      }
    }

    &.gco-box-851 {
      background-color: #a91010;
    }
  }


  .number {
    // background: tint(black, 10);
    background: lighten($green, 10);
    color: white;

    font-weight: bold;

    padding: 8px 10px 12px;

    border-radius: 50%;
    overflow: hidden;

    @include size(40px);

    text-align: center;

    // margin-top: -20px;
    // margin-left: $gutter;
    margin: 1em auto;

    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);

    @include media($medium-screen-up) {
      margin: 0;
      margin-top: -20px;
      margin-left: $gutter;
      // overflow: visible;
    }
  }

  .body {
    padding: ($gutter * 0.8) ($gutter * 0.8) ($gutter * 0.6);
    font-size: 14px;
    font-weight: bold;

    a {
      color: $green;
    }

    .button {
      margin-top: -15px;
      color: #fff;
			background-color: $green;
			border: 2px solid $green;
    }

    .get-amount {
      font-size: 18px;
      color: #fff;
    }

    .few-remaining-warning {
      color: #ff1a1a;
      margin-top: 8px;
    }
  }

}

.structure-section {
  & {
    color: tint(black, 25);
    // background: white;
    // padding: 30px 20px;
    @include outer-container(1200px);

    background: white;
    border-bottom: 1px solid tint(black, 80);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  }

  ul {
    // @extend %default-ul;
    margin: 3em auto 4em;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row;

      margin: 3em auto 4em;

      max-width: 90%;
    }
  }

  li {
    flex: 1;
    // margin: 1em;
    padding: $gutter;

    font-size: 18px;
    font-weight: 13px
  }
  li:not(:last-of-type) {
    margin-right: 3em;
    // margin-right: $gutter;
  }

  .headline {
    color: tint(black, 50);
    text-align: center;
    font-size: (16px * 2);
    line-height: 2;
    padding: 1em 1em;
    // background: tint(black, 90);
    border-top: 1px solid tint(black, 96);
    border-bottom: 1px solid tint(black, 96);
  }

  .figure {
    background: none;
    // border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 1em;

    padding: 0 8px;

    @include size(60px);

  }

  .figure svg {
    width: 100%;
    height: 100%;
    fill: lighten($green, 10);
  }
}

.managecredit-section {
  & {
    @include outer-container(1200px);

    margin: $column auto;
    // padding: $gutter $column;

    // border: 2px solid tint(black, 75);
    // border-radius: 6px;

    background: white;
    border-bottom: 1px solid tint(black, 80);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);

  }

  .flex-block {
    @include media($medium-screen-up) {
      padding: $gutter $column;
      display: flex;
      flex-flow: row row-reverse;
    }
  }

  .flex-block > div {
    flex: 1;

  }

  .flex-block > .body {
    padding: $column;
  }

  .flex-block > .figure {
    flex: 0 1 auto;;
    border-radius: 6px;
    overflow: hidden;

    width: 375px;
    height: 667px;

    background: none center no-repeat;
    background-image: url('/images/css/IMG_4311_iphone6plus_gold_portrait.png');
    background-size: auto 100%;

    &.-img-4317 {
      background-image: url('/images/css/IMG_4317_iphone6_gold_portrait.png');
    }
  }

  ul {
    @extend %default-ul;
  }
}

.benefits-section {
  & {
    @include outer-container(1200px);

    background: white;
    border-bottom: 1px solid tint(black, 80);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);

  }

  >.headline {
    color: tint(black, 50);
    text-align: center;
    font-size: (16px * 2);
    line-height: 2;

    // margin: $gutter auto ($gutter*1);
    // margin: 0 auto;
    padding: $gutter $gutter ;
    // max-width: 600px;
    background: white;
    border-bottom: 1px solid tint(black, 90);

  }

  .flex-block {
    // display: flex;
    // flex-flow: row wrap;

    width: 100%;
    // max-width: 900px;
    margin: 0 auto;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row wrap;
    }

  }

  .flex-block .item {
    text-align: center;

    flex: 1 1 auto;
    // width: 300px;
    // width: (30%);
    min-height: 260px;
    padding: $gutter;
    // border: 1px solid tint(black, 50);

    background: white;
    margin-bottom: 2px;

    display: flex;
    flex-flow: column;
    // align-items: center;
    justify-content: center;

    // &:not(:last-of-type) {
    //   margin-right: 2px;
    //   margin-bottom: 2px;
    // }

    @include media($medium-screen-up) {
      width: (30%);
      &:not(:last-of-type) {
        margin-right: 2px;
        margin-bottom: 2px;
      }
    }
  }

  .flex-block .item .figure {
    background: none;
    // border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 2em;

    @include size(60px);
  }

  .figure svg {
    width: 100%;
    height: 100%;
    fill: lighten($green, 10);
  }
}

.whyinvest-section {
  & {
    @include outer-container(1200px);
    margin: $column auto;

    background: white;
    border-bottom: 1px solid tint(black, 80);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  }

  >.headline {
    color: tint(black, 50);
    text-align: center;
    font-size: (16px * 2);
    line-height: 2;

    // margin: $gutter auto ($gutter*1);
    // margin: 0 auto;
    padding: $gutter $gutter ;
    // max-width: 600px;
    background: white;
    border-bottom: 1px solid tint(black, 90);

  }
  .flex-block {
    // display: flex;
    // flex-flow: row wrap;

    width: 100%;
    margin: 0 auto;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row wrap;
    }
  }

  .flex-block .item {
    text-align: center;

    flex: 1 0 auto;
    // width: 300px;
    // width: (24%);
    // min-height: 260px;
    padding: $gutter;
    // border: 1px solid tint(black, 50);

    background: white;
    margin-bottom: 2px;

    display: flex;
    flex-flow: column;
    // align-items: center;
    justify-content: flex-start;

    @include media($medium-screen-up) {
      width: (24%);
      min-height: 260px;
      &:not(:last-of-type) {
        margin-right: 2px;
        margin-bottom: 2px;
      }
    }

  }

  .flex-block .item .figure {
    background: none;
    // border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 2em;

    @include size(60px);
  }

  .figure svg {
    width: 100%;
    height: 100%;
    fill: lighten($green, 10);
  }
}

.featuredinvestors-section {
  & {
    @include outer-container(1200px);
    margin: $column auto;

    background: white;
    border-bottom: 1px solid tint(black, 80);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  }

  >.headline {
    color: tint(black, 50);
    text-align: center;
    font-size: (16px * 2);
    line-height: 2;

    // margin: $gutter auto ($gutter*1);
    // margin: 0 auto;
    padding: $gutter $gutter ;
    // max-width: 600px;
    background: white;
    border-bottom: 1px solid tint(black, 90);

  }
  .flex-block {
    // display: flex;
    // flex-flow: row wrap;

    width: 100%;
    margin: 0 auto;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row wrap;
    }

  }

  .flex-block .item {
    text-align: center;

    flex: 1 0 auto;
    // width: 300px;
    // width: (30%);
    // height: 260px;
    padding: $gutter;
    // border: 1px solid tint(black, 50);

    background: white;
    margin-bottom: 2px;

    display: flex;
    flex-flow: column;
    // align-items: center;
    justify-content: flex-start;


    @include media($medium-screen-up) {
      width: (30%);
      &:not(:last-of-type) {
        margin-right: 2px;
        margin-bottom: 2px;
      }
    }

  }

  .flex-block .item .figure {
    background: gray center no-repeat;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 2em;

    @include size(60px);
  }
}

.dl-disclaimer {
  text-align: center;
  margin: 12px;
  color: $green;
}

.contest-text {
  text-align: center;
  margin: 12px auto 36px;
  padding: 12px;
  color: $green;
  border: 2px solid $green;

  @media screen and (min-width: 767px) {
    max-width: 60%;
  }
}

