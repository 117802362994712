.page-section {
  & {
    position: relative;
    overflow: hidden;
    // background: skyblue;
    // background: tint(black, 90%);
  }
  &.-bg {
    background: #F1F1F1;
    // background-color: palette(green);
  }
  &.-bg-white {
    background: white;
    // background-color: palette(green);
  }
  &.-bg-primary {
    background-color: $green;
    // background-color: palette(green);
  }
  &.-bg-secondary {
    background-color: $dark-green;
    // background-color: palette(green, dark);
    // color: white;
  }
  &.-bd-green {
    border-bottom: 1px solid;
    border-color: $midlight-green;
    // border-color: palette(green, mid-light);
    // color: white;
  }
  &.-bg-gray {
    background: #E9EAED;
  }
  &.-pad-y {
    padding: em($gutter * 2) 0 ;
  }
  &.-margin-y {
    margin: em($gutter * 2) 0 ;
  }

  // &.-bg-img {
  //   position: relative;
  //   overflow: visible;
  //   z-index: 10;
  //
  //   background: black center no-repeat;
  //   background-size: cover;
  //
  //   @include media($medium-screen-up) {
  //     & {
  //       background: black center no-repeat;
  //       background-size: cover;
  //     }
  //
  //     &:before {
  //       content: '';
  //       display: block;
  //       position: absolute;
  //       z-index: 1;
  //       /* top: 0; */
  //       left: 0;
  //       /* right: 0; */
  //       bottom: 0;
  //       width: 100%;
  //       height: 100%;
  //
  //       @include linear-gradient(
  //         180deg,
  //         rgba(tint(black, 50), 0.1) 0%,
  //         rgba(tint(black, 5), 0.8) 100%
  //       );
  //     }
  //   }
  // }

  > .subsection {
    @extend .page-section;
  }

  .grid-container {
    @include outer-container;
    position: relative;
    // background: #DDD;

    >.col {
      /* @include span-columns(12 of 12); */
      @include fill-parent;
    }

    >.row {
      @include row(block);
    }
  }

  .textblock {
    // @include outer-container;
    @extend %type-system;
    // background: #DDD;
    &.-center {
      text-align: center;
    }
    &.-white {
      color: white;
    }
    &.-green-light {
      // color: palette(green, light);
      color: #021512;
    }
    &.-green-midlight {
      // color: palette(green, mid-light);
      color: #117362;
    }
    &.-green-dark {
      // color: palette(green, dark);
      color: #021512;
    }
    &.-pad-y {
      padding: em($gutter * 2) 0 ;
    }

    a {
      color: inherit;
    }
  }
  //

  .title {
    color: white;
    margin-top: 50px;
    @media screen and (min-width: 767px) {
      margin-top: 100px;
    }
  }
}

.page-header {
  @extend .page-section;
  // @include row(table);

  position: relative;
  z-index: 0;

  height: auto;
  // height: em(480);
  // min-height: 90vh;

  color: white;
  background: black center top no-repeat;
  background-image: url("/images/css/homepage-bg--small@2x.jpg");
  // background-size: cover;
  background-size: auto ;

  @include media(min-width 320px) {
    background-image: url("/images/css/homepage-bg--medium@2x.jpg");
  };

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    @include size(100%);
    @include linear-gradient(
      0deg,
      rgba(black, 0.75) 0%,
      rgba(black, 0.1) 40%
    );
  }

  >.textblock {
    @include span-columns(12);
    position: relative;
    z-index: 1;

    /* text-align: center; */
    /* text-align: left; */
    /* font-size: em(14); */

    // @include row(table);
    vertical-align: middle;

    h1 {
      font-size: em(18);
      margin-bottom: em(16);
      padding: 0 5%;
      text-align: left;

      @include media($medium-screen-up) {
        font-size: em(40);
      };
      @include media(min-width 1040px) {
        padding: 0;
        text-align: center;
      };

    }

    h2 {
      padding: 0 5%;
      font-size: em(14);
      text-align: left;

      @include media($medium-screen-up) {
        font-size: em(24);
      };
      @include media(min-width 1040px) {
        text-align: center;
      };
    }

  }
}


.offerings-section {
  & {
    @include row(block);


    position: relative;
    margin: 0 auto;
    background: tint(black, 94);


    @include media($medium-screen-up) {
      @include row(table);
      // width: 60%;
      padding: 0 em($column);
    }
  }

  > .item {
    & {
      text-align: center;
      color: tint($green, 0);
      padding: em($gutter) em($gutter * 0.4);

      border: solid tint(black, 90);
      border-width: 1px 0;
    }

    @include media($medium-screen-up) {
      @include span-columns(1 of 1);

      border-width: 0 1px;
      &:not(:last-of-type) {border-right-width: 0;}
    }
  }

  >.item.-single {
    width: 100%;
    border: none;
  }

  > .item > .figure {
    @include size(80px);
    @include margin(null auto $gutter);

    /* background: #DDD; */
    fill: currentColor;
  }
}
