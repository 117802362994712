%user-modal {
  & {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 99999;

    display: flex;
    flex-flow: column;

    align-items: center;
    justify-content: center;

    color: tint(black, 20);
    background: rgba(tint(black, 90), 0.95);

    overflow: hidden;
    // overflow-y: scroll;

    // padding: ($gutter * 0.25);
    padding: 60px ($gutter * 0.4) ($gutter * 0.4);
  }

  >.exit {
    position: absolute;
    top: 0;
    right: 0;

    @include size(60px);

    background: url("/images/svg/optimized/x-icon-01.svg") center no-repeat;
    background-size: 24px;

    cursor: pointer;
  }

}

#user-modal {
  @extend %user-modal;

  >.wrapper {
    // @include outer-container();

    // flex: 1 0 auto;
    width: 100%;
    height: 100%;

    // padding:($gutter * 0.6);

    overflow: hidden;
    overflow-y: scroll;
  }

  // FIXME:
  .error {
    color: #AF3939;
  }
}
