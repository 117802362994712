// bower:scss
// endbower

// FIXME: slick is not being used, remove
// @import "../vendor/styles/_slick.scss";
// @import "../vendor/styles/_slick-theme.scss";

@import "application/_application.scss";

@import "components/_video-background.scss";
@import "components/_invest-button.scss";
@import "components/_invest-block.scss";

@import "components/_project-list.scss";
@import "components/_projects-list.scss";
@import "components/_project-tiers.scss";

@import "components/_investment-new.scss";
@import "components/_purchase-new.scss";
@import "components/_claim-investment.scss";
// @import "components/_what-is-ee-video.scss";

@import "components/_modal.scss";
@import "components/_user-modal.scss";
@import "components/_navigation.scss";
@import "components/_apply-bar.scss";
@import "components/_payout-calculator.scss";
@import "components/_popup-block.scss";
@import "components/_standalone-navigation.scss";
@import "components/_standalone-footer.scss";

@import "pages/_home-page.scss";
@import "pages/_start-page.scss";
@import "pages/_home-page-v2.scss";

@import "pages/_press-page.scss";
@import "pages/_project-page2.scss";
@import "pages/_upcoming-project-page.scss";
// @import "pages/_project-page.scss";
// @import "pages/_vip-page.scss";
@import "pages/_test-drive-page.scss";
@import "pages/_restauranteurs-page2.scss";
@import "pages/_memberships-page.scss";
@import "pages/_city-page.scss";
@import "pages/_guide-page.scss";
@import "pages/_lead-investors.scss";
@import "pages/_resources-page.scss";
@import "pages/_project-faq-page.scss";
@import "pages/_agreement-page.scss";
// @import "pages/_testdrives.scss";
@import "pages/_partners-page.scss";

@import "components/_wip.scss";
@import "components/_fb_messenger.scss";
@import "components/_embed-container.scss";
@import "components/_stripe_connect.scss";

// html {
//   background: url("data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=");
//   background-attachment: fixed;
// }

body {
  position: relative;
  // font-family: "proxima-nova", $helvetica;
  color: tint(black, 20);
  // background: white;
  background: #333;
}


.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@include selection(true) {
  background-color: tint($light-green, 50);
}


.investmentshow-page {
  & {
    position: relative;
    min-height: inherit;
    padding: 3rem 1rem;

    background: tint(black, 96);
  }

  .done-button {
    @extend %round-button;
    @extend %invest-button;

    position: relative;;
    font-size: rem(18);
    height: 4rem;
    line-height: 4rem;
    margin-top: 4rem;
    border: none;
  }

  .app-store-icons {
    margin: 0 auto;
    text-align: center;

    a {
      padding: 20px;
      display: inline-block;
    }

    img {
      width: 200px;
    }
  }
}

.back-to-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  text-align: left;
  color: white;
  z-index: 10000;
  padding: 20px 0 10px 40px;
  cursor: pointer;
  display: block;
  background-color: green;
}

.text-centered {
  text-align: center;
  display: block;
}

.account-header {
  height: 80px;

  h1 {
    color: #fff;
    text-align: center;
    font-size: 1.7rem;
    padding-top: 20px;
  }
}
