%gifted-item {
  & {
    position: relative;
    display: block;
    overflow: hidden;

    margin: em($gutter * 0.6) auto;
    padding: ($gutter * 0.4) ($gutter * 0.6);

    background: tint($dark-green, 95);
    border: 1px solid tint($dark-green, 75);
    border-radius: $base-border-radius;
  }

  >.head {
    font-size: em(16 * 2);
    text-align: right;
    padding: ($gutter * 0.7) ($gutter * 0.6);
    // border-bottom: 1px solid tint($light-green, 50);
    border-bottom: 1px solid rgba(black, 0.1);
  }
  >.body {
    font-size: em(14);
    padding: ($gutter * 0.4) ($gutter * 0.6);
    margin: em($gutter * 0.6) auto;
    // font-weight: bold;
  }
}

.gifted-list {
  & {
    position: relative;
    margin: em($column) auto;
  }

  >.gifted-item {
    @extend %gifted-item;
  }
}


// radio tiers
.radiotier-list {
  >.item {
    position: relative;

    // cursor: pointer;
    // overflow: hidden;
    // color: white;
    // background: tint($dark-green, 95);
    // border: 1px solid tint($dark-green, 75);
    // border-radius: $base-border-radius;

    // padding: ($gutter * 0.4) ($gutter * 0.6);

    &:not(:last-of-type) {
      margin-bottom: em($gutter * 0.6);
    }

    [type="radio"] {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;

      opacity: 0;
      cursor: pointer;
    }
    [type="radio"]:checked + .subitem {
      color: white;
      // color: shade($light-green, 50);
      background: $light-green;
      border-color: shade($light-green, 20);

      transform: scale(1.02);

      >.body {
        color: shade($light-green, 80);
      }
    }
  }
  >.item > .subitem {
    display: block;
    overflow: hidden;
    // position: relative;

    margin: 0;
    padding: ($gutter * 0.4) ($gutter * 0.6);

    background: tint($dark-green, 95);
    border: 1px solid tint($dark-green, 75);
    border-radius: $base-border-radius;

    >.head {
      font-size: em(16 * 2);
      text-align: right;
      padding: ($gutter * 0.7) ($gutter * 0.6);
      // border-bottom: 1px solid tint($light-green, 50);
      border-bottom: 1px solid rgba(black, 0.1);
    }
    >.body {
      font-size: em(14);
      padding: ($gutter * 0.4) ($gutter * 0.6);
      margin: em($gutter * 0.6) auto;
      // font-weight: bold;
    }

  }
}
