.upcoming-page-body {
  & {
    // position: relative;
    z-index: 1;
    overflow: hidden;

    min-height: 100vh;
    height: 100%;
    width: 100%;

    // height: 100vh;
    // width: 100vw;
    //
    // overflow: hidden;
    // overflow-y: scroll;

    // padding: 1rem;

    background: black center no-repeat;
    // background-size: cover;

    display: flex;
    flex-flow: column;
    flex-direction: column-reverse;

    @include media($medium-screen-up) {
      display: flex;
      flex-flow: row nowrap;
    }
  }
}

.upcoming-page-body >.photo-block {
  & {
    flex: 1 1 auto;

    position: relative;
    min-height: 40vh;

    background: center no-repeat;
    background-size: cover;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 0;

    height: 25%;
    width: 100%;

    bottom: 0;
    background: linear-gradient(0, rgba(black, 0.5), transparent);
  }

  >.logo-block {
    position: relative;
    z-index: 1;
    margin: 1rem auto;
    fill: white;

    @include size(3rem);

    svg {
      height: inherit;
      width: inherit;

    }

    @include media($medium-screen-up) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      margin: 2rem auto;

      @include size(4rem);
    }

  }
}

.upcoming-page-body >.project-block {
  & {
    // display: block;
    // position: relative;
    z-index: 10;

    // width: 40vw;
    // height: 100vh;

    color: tint(black, 20);
    background: white;

    overflow: hidden;
    overflow-y: scroll;

    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);

    padding-bottom: 95px;

    @include media($medium-screen-up) {
      position: relative;
      width: 40vw;
      height: 100vh;
    }
  }

  >.profile {
    padding: 1rem 2rem 2rem;
  }

  >.profile >.avatar {
    @include size(4rem);

    overflow: hidden;
    box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.1);

    margin: 1rem auto 1rem;
    border-radius: 50%;

    background: white center no-repeat;
    background-size: cover;
    background-image: url('/images/css/placeholder-avatar-01.png');
  }

  >.profile >.title {
    color: black;
    font-size: rem(28);
    font-weight: 600;
    text-align: center;
    // margin: 1rem auto;
  }

  >.profile >.location {
    // color: white;
    font-size: rem(14);
    font-weight: 400;
    text-align: center;
    // margin: 1rem auto;
  }

  >.text {
    font-size: rem(16);
    // text-align: center;
    color: tint(black, 40);

    margin: 0 auto;
    padding: 3rem 2rem;

    border-top: 1px solid tint(black, 90);

    @extend .type-system-sans;
  }
}

.upcoming-page-body {

  .email-field {
    // position: absolute;
    position: fixed;
    bottom: 0;

    overflow: hidden;

    font-size: 16px;

    width: 100%;
    padding: 1rem;

    background: white;
    // border-top: 1px solid tint(black, 90);

    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);

    // border: 1px solid tint(black, 80);
    // border-radius: 4px;

    @include media($medium-screen-up) {
      padding: 2rem;
      width: 40vw;
    }
  }

  .email-field form {
    display: flex;
    flex-flow: row nowrap;
    // box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);

    overflow: hidden;

    border: 1px solid tint(black, 80);
    border-radius: 4px;
  }

  .email-field button {
    flex: 1 0 auto;
    padding: 0 1.5rem;

    // text-transform: uppercase;
    // font-size: rem(13);

    color: tint(black, 20);
    background: white;

    border-radius: 0;
    border-left: 1px solid tint(black, 90);

    @include transition(background-color 600ms ease);
  }

  .email-field input {
    @include ellipsis();

    display: block;
    margin: 0 ;
    padding: 0 2em;
    border-radius: 0;

    line-height: (60px);

    // color: tint(black, 20);
    color: lighten($green, 10);
    font-weight: 600;
    background: tint(black, 94);
    // background: white;

    // box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
    border: none;
    outline: none;

    // @include transition(background-color 600ms ease);

    &::-webkit-input-placeholder {
      color: tint(black, 50);
      // text-align: center;
    }

    &:focus + button,
    &:active + button {
      color: white;
      background: lighten($green, 10);
    }
  }
}
