// palette map
// $palettes:(
//   green: (
//     base: #0E8241,
//     light: #45D886,
//     mid-light: #117362,
//     dark: #021512,
//   )
// );

// palette func
// @function palette($palette, $tone: 'base') {
//     @return map-get(map-get($palettes, $palette), $tone);
// }
//

// .section {
  // background: skyblue;
// }

.grid-container {
  @include outer-container;

  // FIXME:
  @include row(block);

  // &.-col-two {
  //   >* {
  //     @include span-columns(1 );
  //   }
  // }
}


// FIXME:
.row {
  & {
    @include row;
    /* font-size: em(14); */
  }
  &.-col-two {
    >*{

      @include media($medium-screen-up) {
        @include span-columns(6 of 12);
      }
    }
  }
}

// .hit-diagram,
/* .diagram-block {
  & {
    font-size: 80%;

    @include media($medium-screen-up) {
      font-size: 100%;
    }
  }

  .dblock {
    @extend %type-system;
    @include margin(em($gutter*4) null em($gutter*4));

    color: white;
  }
  .dblock > .head {
    text-align: center;
  }
  .dblock > .body {
    text-align: center;

    @include size( 60% auto);
    @include margin($gutter auto $gutter);
    @include size( 90% auto);
    max-width: em($gutter*20);

    p {
      font-size: em(18);
      font-size: em(14);
      line-height: 1.8em;
    }
  }
  .dblock > .figure {
    @include size(80px);
    @include margin(null auto em($gutter*2));
    background: rgba(#45D886, 1.0);

    color: rgba(#45D886, 1.0);
    fill: rgba(#45D886, 1.0);
  }

  .svgline {
    fill: white;
    width: 60%;
    margin: 0 auto;
    display: none;

    @include media($medium-screen-up) {
      display: block;
    }

  }

}
 */


.section-card {
  & {
    @extend %card-style;

    margin-bottom: em($gutter * 1);
  }

  /* & + & {
    margin-top: em($gutter * 1);
  } */
  .headline {
    font-size: em(12);
    padding: em($gutter) 0 em($gutter * 0.5);
    margin-bottom: em($gutter);
    // border-bottom: 1px solid tint(black, 90);

  }

  >.head {
    @include pad($gutter);
  }
  >.body {
    @extend .type-system-sans;
    @include pad( $gutter );

    overflow: hidden;
    // height: auto;
    height: em(400);
    // max-height: 400px;

    &.-is-expanded {
      height: auto;
      // max-height: none;
    }
  }
  >.actions {
    @include pad($gutter);

    cursor: pointer;
    /* border-top: 1px solid tint(black, 90); */
    border-top: 1px solid tint($green, 75);

  }


  // FIXME:
  /* display: none; */

  /* &.-is-visible {
    display: block;
  }
  @include media($medium-screen-up) {
    display: block;
  } */
}

//
.section-wrapper {
  // FIXME:
  & {
    display: none;
  }
  &.-is-visible {
    display: block;
  }
  @include media($medium-screen-up) {
    display: block;
  }
  //
  &.-bg {
    background: tint(black, 100);
  }
}
//
.documents-block {
  & {
    // @extend .section-card;
  }

  >.head {
    // font-size: em(12);
    // padding: em($gutter * 1.4) 0 em($gutter * 1.0);
    padding: em($gutter * 1.0) 0 ;
  }

  .documentlist {
    @include row(table);
    /* @include pad( $gutter ); */
    background: whitesmoke;
    border: 0 solid shade(whitesmoke, 10);
    border-width: 1px 0;
  }

  .document {
    display: block;
    /* background: whitesmoke; */
    border: 0 solid shade(whitesmoke, 10);
    /* border-width: 1px 0; */

    @include media($medium-screen-up) {

      @include span-columns(1 of 4);
      @include omega(4n);
    };

    &:hover {
      cursor: pointer;
      background: shade(whitesmoke, 5);

      .name {
        background: tint($midlight-green, 10);
        color: white;
      }
    }

    >.figure {
      @include pad( $gutter );
      overflow: hidden;

      svg {
        display: block;
        width: auto;
        height: 100%;
        max-height: em($gutter * 3);
        margin: 0 auto;
      }
    }

    >.name {
      overflow: hidden;
      font-size: em(12);
      text-align: center;
      line-height: em($gutter * 1.6);
      /* padding-bottom: em($gutter * 0.6); */
    }

  }
  .document + .document {
    border-left-width: 1px;
  }
}

.proposal-block {
  & {
    padding-top: 90px;
    
    overflow: hidden;
    position: relative;
    z-index: 0;

    background: black fixed 100% center no-repeat;
    background-size: cover;

    background-image: url("/images/css/proposal-bg--medium@2x.jpg");

    @include media(min-width 375px) {
      /* small */
      background-image: url("/images/css/proposal-bg--small@2x.jpg");
    };
    @include media(min-width 1024px) {
      /* medium */
      background-image: url("/images/css/proposal-bg--medium@2x.jpg");
    };
    @include media(min-width 1440px) {
      /* large */
      background-image: url("/images/css/proposal-bg--large@2x.jpg");
    };
    @include media(min-width 1920px) {
      /* full */
      background-image: url("/images/css/proposal-bg--full@2x.jpg");
    };

    /* min-height: 50vh; */
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @include size(100%);

    @include background(
      linear-gradient( rgba(black, 0.8) 20%, black 120% )
    );
  }

  >.container {
    @include outer-container();

    position: relative;
    z-index: 10;
    @include row(block);
  }

  >.container >.message {

    @extend %type-system;
    color: white;

    margin-top: em($gutter * 2);
    padding: em($gutter);
    font-size: em(14);
    /* margin-bottom: em($gutter * 2); */

    @include media($medium-screen-up) {
      @include span-columns(6 of 12);
      margin-top: em($gutter * 5);
    }
    @include media(min-width 1040px) {
      padding: 0;
      font-size: em(16);
    }

  }

  >.container >.form {
    /* @include span-columns(6 of 12); */
    @extend %card-style;
    /* background: white; */

    /* min-height: 600px; */
    padding: em($gutter);

    margin-top: em($gutter * 1);
    margin-bottom: em(-4px);

    font-size: em(14);

    @include media($medium-screen-up) {
      @include span-columns(6 of 12);
      margin-top: em($gutter * 3);
      margin-bottom: em($gutter * 4);

      /* font-size: em(16); */
    }

    .button + .button,
    .field + .field,
    .field + .button {
      margin-top: em(14);
    }

  }

}

.prequel-banner {
  & {
    @include clearfix;
    overflow: hidden;
    position: relative;
    z-index: 0;

    padding-top: 15%;
    background: gray;
    color: white;
  }

  >.body {
    position: relative;
    z-index: 10;
    /* top: 0; */
    /* bottom: 0; */
    /* left: 0; */
    /* right: 0; */
    margin: em(16*3) auto;

    bottom: 0;

    min-width: 300px;
    width: 60%;

    @extend .type-system-sans;

    @include media(min-width 800px) {
      margin: em(16*3);
      margin-left: em(16*4);
    };

    @include media(min-width 500px) {
      margin: em(16*3);
    };
  }

  >.figure {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    background: #DDD center no-repeat;
    background-size: cover;

    background-image: url('/images/css/brick-and-mortar--small@2x.jpg');

    @include media(min-width 1024px) {
      background-image: url('/images/css/brick-and-mortar--medium@2x.jpg');
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include size(100%);
      @include linear-gradient(
        0deg,
        rgba(black, 0.4) ,
        rgba(black, 0.0) 50%
      );
      z-index: 0;
    }
  }

  .logo {
    @include size(80px);
    position: relative;
    margin: auto;
    bottom: em(20);
    z-index: 2;

    fill: white;

    @include media(min-width 500px) {
      position: absolute;
      right: 4%;
    };
  }

}

.popup-update {
  & {
    @include clearfix;
    overflow: hidden;
    background: #058117;
    color: white;

    /* padding: em(16); */
    padding-top: 10%;

    position: relative;
    z-index: 0;
  }

  h1, h2 {
    float: none;
  }

  .button {
    /* @extend %button; */
    display: inline-block;
    background: white;
    color: black;
    text-align: center;

    padding: em(12) em(16);
    border-radius: $base-border-radius;
  }

  .logo {
    @include size(80px);
    position: relative;
    margin: auto;
    bottom: em(20);
    z-index: 2;

    fill: white;

    @include media(min-width 500px) {
      position: absolute;
      right: 4%;
    };
  }

  >.body {
    position: relative;
    z-index: 10;
    /* top: 0; */
    /* bottom: 0; */
    /* left: 0; */
    /* right: 0; */
    margin: em(16*3) auto;

    /* float: left; */
    bottom: 0;

    min-width: 300px;
    width: 40%;

    @extend .type-system-sans;

    @include media(min-width 800px) {
      margin: em(16*3);
      margin-left: em(16*4);
    };

    @include media(min-width 500px) {
      margin: em(16*3);
    };
  }
  >.figure {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    background: #DDD center no-repeat;
    background-size: cover;

    /* padding-bottom: 100/1 * 1%; */
    background-clip: padding-box;
    box-sizing: border-box;

    &.-green-bd {
      border: em(8) solid #058117;
      @include media(min-width 500px) {
        border-width: em(16);
      };
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);

      @include background(
        linear-gradient(0deg, black -20%, transparent 80%)
      );

    }
  }
}


#PopupSignupForm_0 {
  .mc-modal {
    top: 10%;
  }
  .mc-layout__modalContent {
    overflow-y: scroll;
  }
}


.mixpanel-badge {
  display: block;
  margin: 0 auto;
  width: 100%;

  img {
    display: block;
    margin: 0 auto em($gutter);
  }
}

.location-card {
  & {
    @extend .section-card;
  }

  .map {
    overflow: hidden;
    background: #E9E5DC 100% auto center top no-repeat;
    background-size: cover;
    height: 0;
    width: 100%;
    padding-bottom: 60%;

    border: 1px solid tint(black, 90);
  }
}

@import "wip-homepage";
@import "wip-showcase-page";
