// navigation

// FIXME:
.primary-nav,
.secondary-nav {
  position: relative;
  z-index: 9999;
}


.primary-nav {
  & {
    display: block;
    overflow: hidden;

    height: (60px);

    color: tint(black, 0);
    background: tint(white, 0);
  }

  // & {
  //   // display: block;
  //   position: fixed;
  //   top: em(0);
  //   left: 0;
  //   right: 0;
  //   z-index: 9999999;
  //   @include transition(top 300ms ease);
  // }
  //
  // &.-nav-up {
  //   top: em(-(48 + 24));
  // }
  // &.-nav-down {
  //   top: em(0);
  // }

  >.wrapper {
    @include outer-container();

    display: flex;
    // flex-flow: column;
    flex-flow: row nowrap;

    height: inherit;
  }

  .home {
    flex: 0 1 auto;
    // align-self: center;
    // height: auto;
    // background: tint(black, 50);

    padding: (12px);

    height: 100%;
    // width: ;

    color: white;
    // fill: white;
  }

  .home >svg {
    display: block;

    height: 100%;
    width: auto;

    fill: currentColor;
  }

  .account {
    flex: 0 0 auto;
    align-self: center;

    margin-right: 0;
    margin-left: auto;

    // line-height: 100%;
    // background: salmon;

    // font-weight: 500;
    // color: white;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    li {
      display: inline-block;
      margin-right: 10px;
    }

    li a {
      display: block;
      color: black;
      font-weight: 500;
      font-size: em(15);
    }
  }

}

.user-block {
  $avatar-size: 28px;

  & {
    position: relative;
    // display: block;
    display: flex;
    flex-flow: row nowrap;

    align-items: center;
    // justify-content: center;
  }

  >.avatar {
    @include size($avatar-size);

    background: gray center no-repeat;
    background-size: cover;

    border: 1px solid white;
    border-radius: 50%;

    margin-right: (10px);

    // display: inline-block;
    // display: block;
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: -($avatar-size * 1.5);
    // right: auto;
  }

  >.name {
    font-weight: 500;
    // display: inline-block;

    display: none;

    @include media($medium-screen-up) {
      display: block;
    }
  }

}

.secondary-nav {
  & {
    // display: flex;
    // flex-flow: column;

    background: tint(black, 0);
    border-top: 1px solid tint(black, 10);
  }

  >.list {
    list-style: none;
    margin: 0 ;
    padding: 0;

    width: 100%;

    height: (60px);
    line-height: (60px);

    // scrollbar stuff
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar { display: none;}

    display: flex;
    flex-flow: row nowrap;

    align-items: center;

    @include media($medium-screen-up) {
      justify-content: center;
    }

    // position: relative;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   right: 0;
    //   left: auto;
    //   height: 100%;
    //   width: 10%;
    //   background: salmon;
    // }
  }

  // ul {
    // flex: 1 0 auto;
    // display: block;

    // width: 100%;

    // background: salmon;

    // overflow-y: hidden;
    // overflow-x: scroll;
  // }

  ul >li {
    flex: 0 0 auto;
    text-align: center;
    font-weight: 500;
  }

  ul >li >a{
    display: block;
    padding: 0 em(16px);

    color: $midlight-green;

    &:hover {
      color: white;
    }

    &.selected {
      color: white;
    }
  }

  // .-testme {
    // flex: 1 0 100%;
    // width: 1000px;
    // background: red;
  // }
}
