.newsletter-modal {
  & {
    display: block;
    /* overflow: hidden; */
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;

    font-size: em(12);

    width: 100%;
    max-width: 90%;
    margin: em($gutter * 0.6) auto;
    border-radius: $base-border-radius;

    background: tint(black, 100);
    color: tint(black, 10);

    -webkit-box-shadow: 0px 20px 40px 8px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 20px 40px 8px rgba(0,0,0,0.2);
    box-shadow: 0px 20px 40px 8px rgba(0,0,0,0.2);

    @include transition(all 600ms $ease-in-out-back);
  }

  &.-is-hidden {
    /* left: auto; */
    /* right: em(-500); */
    /* right: -100%; */
    /* @include transition(right 400ms ease); */
    @include transform(translateX(150%));
  }

  @include media($medium-screen-up) {
    right: 0;
    left: auto;

    margin: em($gutter * 0.6);
    max-width: em(420);
  }

  >.body {
    padding: em($gutter * 1.6) em($gutter * 1.0);
    text-align: center;
    @extend .type-system-sans;
  }

  >.dismissbutton {
    position: absolute;
    top: 0;
    right: 0;

    cursor: pointer;

    /* background: salmon; */

    @include size(em(50px));
    // margin-top: em(-60);
    // margin-right: em(-10);

    background: url('/images/svg/x-icon-01.svg') no-repeat center;
    background-size: em(20);

    opacity: 0.5;

    &:hover {
      opacity: 1.0;
    }
  }
  >.disclaimer {

    padding: em($gutter * 0.6) em($gutter * 0.4);
    /* margin-top: em($gutter * 0.2); */

    border-top: 1px solid tint(black, 90);

    background: tint(black, 94);
    color: tint(black, 25);

    font-size: em(14);
    text-align: center;
  }

}
