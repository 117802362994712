.team-list {
  & {
    position: relative;
    /* overflow: hidden; */
    padding-top: em($gutter * 4)
  }

  >.item {
    position: relative;
    margin: em(60px) auto;
    /* border: 1px solid tint(black, 90); */
    background: white;
    /* border-radius: $base-border-radius; */

    /* @extend %card-style; */

    /* overflow-x:hidden; */
    /* overflow: visible; */
  }

  >.item >.head {
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: em(10);
    /* padding: 0 em($gutter); */
    /* margin: em($gutter) auto; */
    padding: em($gutter) em($gutter*1.2);
  }

  >.item >.body {
    /* padding: 0 em($gutter*1.2); */
    /* margin: em($gutter) auto; */
    padding: em($gutter) em($gutter*1.2);
  }

  >.item >.figure {
    //extend circle bg figure/avatar
    // FIXME:

    overflow: hidden;
    border-radius: 50%;
    background: gray 100% center no-repeat;
    background-size: cover;
    @include size(80px);

    margin: 0 auto;
    margin-top: -30px;
  }

}


.person-block {
  & {
    @extend %card-style;
    /* overflow: visible; */
    /* background: white; */
    margin: em($gutter * 1) auto;
  }

  >.head {
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: em(12);
    padding: 0 em($gutter);
    margin-top: em($gutter);
    /* padding: em($gutter) em($gutter*1.2); */
  }

  >.body {
    /* padding: 0 em($gutter*1.2); */
    /* margin: em($gutter) auto; */
    padding: em($gutter) em($gutter*1.2);
  }

  >.figure {
    //extend circle bg figure/avatar
    // FIXME:

    overflow: hidden;
    border-radius: 50%;
    background: gray 100% center no-repeat;
    background-size: cover;
    @include size(80px);

    margin: 0 auto;
    margin-top: em($gutter);
  }
}
