.popup-block {
  & {
    background: white;
    color: #000;

    padding: 2rem;

    margin: 0 auto;
    max-width: 600px;
  }

  .text {
    @extend .type-system-sans;
  }

  .actions {
    & {
      width: 100%;
      margin: 1rem 0 0 ;
      display: flex;
      flex-flow: row nowrap;
    }

    button,
    a {
      @extend %round-button;
      // display: block;
      color: white;
      background: gray;
      // max-width: 280px;
      min-width: inherit;
      flex: 1;
      // width: calc(100% / 3);

      &.-is-positive {
        background: lighten($green, 10);
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}
